@import "variables";
@import "../mixins/text-bold";

[data-sp-search-result-pagination] {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: inline-flex;
	}
}

[data-sp-search-result-pagination-link] {
	margin: 5px 3px 0 2px;
	border: 1px solid $colorBorderPrimary;

	&,
	a {
		display: inline-block;
		-webkit-appearance: none;
		box-sizing: border-box;
		width: 42px;
		height: 38px;

		background: none white;
		color: $colorTextPrimary;

		@include bs-text-bold;
		text-align: center;
		text-decoration: none;
		line-height: 36px;

		white-space: nowrap;
		cursor: pointer;
	}

	.bs-icon {
		display: block;
		fill: $colorTextPrimary;
	}

	a,
	.bs-icon {
		width: 40px;
		height: 36px;
	}

	transition: background-color $transitionDurationDefault $transitionEasingDefault,
	color $transitionDurationDefault $transitionEasingDefault;

	&:not(.SP-isActive):hover,
	&:not(.SP-isActive):focus,
	&:not(.SP-isActive) a:hover,
	&:not(.SP-isActive) a:focus {
		background-color: $colorOffsetSegment;
	}

	&.SP-isActive,
	&.SP-isActive a {
		color: white;
		background-color: $colorTextOffsetSegment;
		cursor: default;
	}
}
