@import "../breakpoint";
@import "../variables";

.bs-mapsight-source-search {
	color: black;
	font-size: 16px;

	@include breakpoint($breakpointMobile) {
		padding: 5px;
		margin: 0 -5px 20px -5px;
		border: 1px solid $colorBorderPrimary;
		border-radius: 4px;

	}

	&__form {
		@include breakpoint($breakpointMobile) {
			&:not(&--expanded) {
				display: none;
			}
		}
	}

	&__toggle-button {
		display: flex;
		float: right;

		padding: 4px 10px;
		border: 0;
		border-radius: 3px;

		background: $colorPageBackground;
		color: black;

		@include breakpoint($breakpointTabletAndUp) {
			display: none;
		}

		&__icon {
			margin-left: 6px;
		}
	}

	.SP-Form {
		clear: both;
	}
}
