@import "../variables";
@import "../breakpoint";
@import "./text-bold";
@import "./text-regular";
@import "./text-medium";
@import "./text-light";
@import "./site-theme-property";

@mixin bs-headline-space {
	margin: 0.67em 0;
}

@mixin bs-headline-wrap {
	text-wrap: balance;

	@include breakpoint($breakpointMobile) {
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
}

@mixin bs-headline-all($space: true, $wrap: true) {
	@if $space {
		@include bs-headline-space;
	}
	@if $wrap {
		@include bs-headline-wrap;
	}
}

@mixin bs-headline-color {
	@include bs-site-theme-property('headlineColor', color);
}

@mixin bs-headline-page($color: true, $space: true, $wrap: true) {
	@include bs-headline-all($space, $wrap);
	@if $color {
		@include bs-headline-color;
	}
	@include bs-text-medium;
	line-height: $lineHeightHeading;
	font-size: 3.2rem;

	@include breakpoint($breakpointTabletAndUp) {
		font-size: 4rem;
	}
}

@mixin bs-headline-section($color: true, $space: true, $wrap: true) {
	@include bs-headline-all($space, $wrap);
	@if $color {
		@include bs-headline-color;
	}
	@include bs-text-light;

	clear: both;
	@if $space {
		padding-top: $sectionHeadlineTopPadding;
	}
	line-height: $lineHeightHeading;
	font-size: 2.6rem;
	text-transform: uppercase;
	border-bottom: 1px solid;
}

@mixin bs-headline-mini-section($color: true, $space: true, $wrap: true) {
	@include bs-headline-all($space, $wrap);
	@if $color {
		@include bs-headline-color;
	}
	@include bs-text-regular;

	@if $space {
		padding-top: 20px;
	}
	line-height: $lineHeightHeading;
	font-size: 1.5rem;
	text-transform: uppercase;
	border-bottom: 1px solid $colorBorderPrimary;
}

@mixin bs-headline-paragraph($color: true, $space: true, $wrap: true) {
	@include bs-headline-all($space, $wrap);
	@if $color {
		@include bs-headline-color;
	}
	@include bs-text-medium;
	line-height: $lineHeightHeading;
	font-size: 2.6rem;
}

@mixin bs-headline-contact($color: false, $space: true, $wrap: true) {
	@include bs-headline-paragraph($color, $space, $wrap);
	@include bs-text-regular;
}

@mixin bs-headline-offset($color: true, $space: true, $wrap: true) {
	@include bs-headline-all($space, $wrap);
	@if $color {
		@include bs-headline-color;
	}
	@include bs-text-regular;
	line-height: $lineHeightHeading;
	font-size: 1.5rem;
}
