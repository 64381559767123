@import "../variables";
@import "../mixins/text-medium";
@import "../mixins/text-bold";
@import "../mixins/link-special-content";

$iconSpace: 30px;
$iconSpaceSmaller: 25px;
//$iconSpaceSmallest: 20px;

.bs-link-list {
	$b: &;

	&__items {
		margin: 1em 0;
		padding: 0;
		list-style: none;

		& & {
			border-left: 1px solid $colorBorderPrimary;
			padding-left: 0.5rem;
			margin-bottom: 3rem;
			margin-left: -1px;
		}
	}

	&__item {
		position: relative;
		padding-left: $iconSpace;

		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	&__link {
		@include bs-text-medium;
		@include bs-link-special-content;
		color: inherit;
		text-decoration: none;

		&__icon {
			position: absolute;
			top: 0;
			left: 0;
			margin-top: -2px;
		}

		&__addon {
			@include bs-text-medium;
			color: $colorTextSecondary;
		}

		.bs-icon {
			width: 20px;
			height: 20px;

			&, path {
				@each $themeName, $theme in $themes {
					.bs-site--theme-#{$themeName} & {
						fill: map-get($theme, iconColor) !important;
					}
				}
			}
		}

		&:not(span):hover,
		&:not(span):focus,
		&:not(span):hover #{$b}__link__addon,
		&:not(span):focus #{$b}__link__addon {
			color: $colorAction;
			text-decoration: underline;
		}
	}

	#{$b}__description {
		p {
			margin: 0;
		}
	}

	&--blog {
		#{$b}__item:not(:first-child) {
			font-size: $fontSizeSmaller;
			margin-top: 5px;

			#{$b}__description {
				display: none;
			}

			#{$b}__link__text {
				font-weight: $fontWeightRegular;
			}
		}

		@include breakpoint($breakpointTabletAndUp) {
			#{$b}__items {
				margin-left: #{200px + $gridGap};
			}
		}
	}

	&--chronological {
		#{$b}__item {
			margin: 15px 0 0 0;
			padding: 5px 0;

			&:not(:first-child) {
				border-top: 1px solid $colorBorderSecondary;
			}
		}

		// date on the left, link and description on the right
		#{$b}__date {
			@include bs-text-medium;
			color: $colorTextSecondary;
			float: left;
			margin-right: 20px;
			font-variant-numeric: tabular-nums;
		}

		#{$b}__link,
		#{$b}__description {
			display: block;
			margin-left: 120px;
		}

		#{$b}__link:not(#{$b}__link--no-icon) {
			// move icon into link
			position: relative;
			padding-left: $iconSpace;
		}
	}
}

.bs-site:not(.bs-site--citygov) {
	.bs-link-list__items .bs-link-list__items .bs-link-list__items {
		font-size: $fontSizeSmaller;

		.bs-link-list__item {
			padding-left: $iconSpaceSmaller;
		}

		.bs-link-list__link .bs-icon {
			width: 17px;
			height: 17px;
		}
	}

	//& & & & & {
	//	font-size: $fontSizeSmallest;
	//
	//	#{$b}__item {
	//		padding-left: $iconSpaceSmallest;
	//	}
	//
	//	#{$b}__link .bs-icon {
	//		width: 14px;
	//		height: 14px;
	//	}
	//}
}

span.bs-link-list__link {
	cursor: default;
}

// NOTE: Bad hotfix for citygov where list content elements are used within a .bs-paragraph which has an "a"-selector
//           that trumps this definition... CityGov should not render the containing bs-paragraph instead
.bs-paragraph a.bs-link-list__link { // increase specificity
	text-decoration: none;
}
