@use "sass:math";

@import "../variables";
@import "../breakpoint";

.bs-hotelform {
	$lightColor: lighten(map-get(map-get($themes, ToKo), primaryColor), 50%);

	position: relative;
	margin: 0 #{-$siteLogoSmallBorderWidth} $siteLogoSmallBorderWidth #{-$siteLogoSmallBorderWidth};
	padding: 20px 0 30px 0;
	background-color: darken(map-get(map-get($themes, ToKo), primaryColor), 5%);
	color: #333333;

	&__headline {
		@include bs-text-medium;
		line-height: $lineHeightHeading;
		font-size: 2.6rem;
		margin: 0;
		padding: math.div($gridGap, 2) $gridGap;
	}

	&__form {
		display: flex;
		padding: 0 100px math.div($gridGap, 2) $gridGap;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: stretch;

		@include breakpoint($breakpointTabletAndUp) {
			padding-right: 130px;
		}
	}

	&__section {
		padding: 0;
		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 50%;

		&--anreise {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 100%;
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		&__section {
			flex-basis: 25%;

			&--anreise {
				flex-basis: 50%;
			}
		}
	}

	&__label {
		font-size: $fontSizeSmaller;
		display: block;
	}

	&__select {
		width: calc(100% - 1rem);
		height: 1.6em;
		padding: 0.5rem 1rem;

		border: none;
		border-radius: 5px;

		background-color: $lightColor;
		cursor: default;

		@include breakpoint($breakpointTabletAndUp) {
			width: calc(100% - 2rem);
		}
	}

	&__submit {
		position: absolute;
		bottom: 40px;
		right: $gridGap;

		height: 80px;
		width: 80px;

		border: none;
		border-radius: 50px;

		font-weight: bold;
		background-color: $lightColor;
		color: #000 !important;

		&:hover,
		&:focus {
			background-color: #fff;
		}

		@include breakpoint($breakpointTabletAndUp) {
			height: 100px;
			width: 100px;
			bottom: 30px;
		}
	}
}
