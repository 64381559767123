@import "../variables";

.SP-Upload {
	&__delete,
	&__upload,
	&__uploaded,
	&__button {
		display: none;
	}
}
