@import "../variables";
@import "../../mixins/headlines";

.SP-Legend {
	@include bs-headline-paragraph;
	margin: 1em 0 0.5em 0;

	&--radio,
	&--checkbox {
		@include bs-text-medium;
		font-size: $fontSizeDefault;
		color: $sp-field-label-color;

		.SP-isValid > & {
			color: $sp-field-signal-border-color-valid;
		}

		.SP-isInvalid > & {
			color: $sp-field-signal-border-color-invalid;
		}
	}
}
