@mixin bs-scroll-wrapper {
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	// Scrollbar Chrome/Webkit
	&::-webkit-scrollbar {
		width: 7px;
		background: none transparent;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-track-piece {
		background: none transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		background-clip: content-box;
		border: 1px solid transparent;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(0, 0, 0, 0.6);
	}

	// Scrollbar FF/Standard
	scrollbar-gutter: auto;
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.5) $colorOffsetSegment;
}
