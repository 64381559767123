@import "../variables";

$pswp-bg: #fff;
$pswp-placeholder-bg: #ccc;
$pswp-root-z-index: 100000;
$pswp-preloader-color: rgba(79, 79, 79, 0.4);
$pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
$pswp-transition-duration: 400ms; // keep in sync with js!
$pswp-icon-color: #333;
$pswp-icon-color-secondary: #fff;
$pswp-icon-stroke-color: #fff;
$pswp-icon-stroke-width: 0;
$pswp-error-text-color: $pswp-icon-color;


@keyframes pswp-clockwise {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.pswp {
	position: fixed;
	z-index: $pswp-root-z-index;
	display: none;
	touch-action: none;
	outline: 0;
	opacity: 0.003;
	contain: layout style size;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	/* Prevents focus outline on the root element,
	  (it may be focused initially) */
	&:focus {
		outline: 0;
	}

	* {
		box-sizing: border-box;
	}

	img {
		max-width: none;
	}

	&--open {
		display: block;
	}

	&,
	&__bg {
		transform: translateZ(0);
		will-change: opacity;
	}

	&__bg {
		opacity: 0.005;
		background: $pswp-bg;
	}

	&,
	&__scroll-wrap {
		overflow: hidden;
	}

	&,
	&__scroll-wrap,
	&__bg,
	&__container,
	&__item,
	&__content,
	&__img,
	&__zoom-wrap {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__scroll-wrap,
	&__bg,
	&__container,
	&__item,
	&__content,
	&__img,
	&__zoom-wrap {
		position: absolute;
	}

	&__img,
	&__zoom-wrap {
		width: auto;
		height: auto;
	}

	&--click-to-zoom#{&}--zoom-allowed &__img {
		cursor: zoom-in;
	}

	//noinspection CssOverwrittenProperties
	&--click-to-zoom#{&}--zoomed-in &__img {
		cursor: move;
		cursor: grab;
	}

	&--click-to-zoom#{&}--zoomed-in &__img:active {
		cursor: grabbing;
	}

	/* :active to override grabbing cursor */
	&--no-mouse-drag#{&}--zoomed-in &__img,
	&--no-mouse-drag#{&}--zoomed-in &__img:active,
	&__img {
		cursor: zoom-out;
	}


	/* Prevent selection and tap highlights */
	&__container,
	&__img,
	&__button,
	&__counter {
		user-select: none;
	}

	&__item {
		/* z-index for fade transition */
		z-index: 1;
		overflow: hidden;
	}

	&__hidden {
		display: none !important;
	}

	/* Allow to click through pswp__content element, but not its children */
	&__content {
		pointer-events: none;

		> * {
			pointer-events: auto;
		}
	}

	/*
		Error message appears when image is not loaded
		(JS option errorMsg controls markup)
	*/
	&__error-msg-container {
		display: grid;
	}

	&__error-msg {
		margin: auto;
		font-size: 1em;
		line-height: 1;
		color: $pswp-error-text-color;
	}

	/*
	class pswp__hide-on-close is applied to elements that
	should hide (for example fade out) when PhotoSwipe is closed
	and show (for example fade in) when PhotoSwipe is opened
	 */
	&__hide-on-close {
		opacity: 0.005;
		will-change: opacity;
		transition: opacity $pswp-transition-duration cubic-bezier(0.4, 0, 0.22, 1);
		z-index: 10; /* always overlap slide content */
		pointer-events: none; /* hidden elements should not be clickable */
	}

	/* class pswp--ui-visible is added when opening or closing transition starts */
	&--ui-visible &__hide-on-close {
		opacity: 1;
		pointer-events: auto;
	}

	/* <button> styles, including css reset */
	&__button {
		position: relative;
		display: block;
		width: 50px;
		height: 60px;
		padding: 0;
		margin: 0;
		overflow: hidden;
		cursor: pointer;
		background: none;
		border: 0;
		box-shadow: none;
		opacity: 0.85;
		-webkit-appearance: none;
		-webkit-touch-callout: none;

		&:hover,
		&:active,
		&:focus {
			opacity: 1;
		}

		&:disabled {
			opacity: 0.3;
			cursor: auto;
		}

		&--close {
			margin-right: 6px;
		}

		&--arrow {
			position: absolute;
			top: 50%;
			width: 75px;
			height: 100px;
			margin-top: -50px;

			&:disabled {
				display: none;
				cursor: default;
			}

			.pswp__icn {
				top: 50%;
				margin-top: -30px;
				width: 60px;
				height: 60px;
				background: none;
				border-radius: 0;
			}

			.pswp--one-slide & {
				display: none;
			}

			/* hide arrows on touch screens */
			.pswp--touch & {
				visibility: hidden;
			}

			/* show arrows only after mouse was used */
			.pswp--has_mouse & {
				visibility: visible;
			}

			&--prev {
				right: auto;
				left: 0;
			}

			&--next {
				right: 0;

				.pswp__icn {
					left: auto;
					right: 14px;
					/* flip horizontally */
					transform: scale(-1, 1);
				}
			}
		}

		&--zoom {
			display: none;

			.pswp--zoom-allowed & {
				display: block;
			}
		}

		&--close,
		&--zoom {
			margin: 10px;
			height: 50px;

			//noinspection CssOverwrittenProperties
			background-color: rgba(255, 255, 255, 0.8);
			//noinspection CssOverwrittenProperties
			background-color: hsla(0 0% 100% / 80%);
			backdrop-filter: saturate(180%) blur(20px);
			background-clip: padding-box;
			box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.50);
			border-radius: 4px;

			.pswp__icn {
				top: 9px;
			}
		}
	}

	&__icn {
		position: absolute;
		top: 14px;
		left: 9px;
		width: 32px;
		height: 32px;
		overflow: hidden;
		pointer-events: none;

		fill: $pswp-icon-color;
		color: $pswp-icon-color-secondary;

		&-shadow {
			stroke: $pswp-icon-stroke-color;
			stroke-width: $pswp-icon-stroke-width;
			fill: none;
		}

		&:focus {
			outline: 0;
		}
	}

	/*
		div element that matches size of large image,
		large image loads on top of it,
		used when msrc is not provided
	*/
	div#{&}__img--placeholder,
	&__img--with-bg {
		background: $pswp-placeholder-bg;
	}

	&__top-bar {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		z-index: 10;

		/* allow events to pass through top bar itself */
		pointer-events: none !important;

		> * {
			pointer-events: auto;
			/* this makes transition significantly more smooth,
			   even though inner elements are not animated */
			will-change: opacity;
		}
	}

	/* "+" => "-" */
	.pswp--zoomed-in .pswp__zoom-icn-bar-v {
		display: none;
	}

	&__preloader {
		position: relative;
		overflow: hidden;
		width: 50px;
		height: 60px;
		margin-right: auto;

		.pswp__icn {
			opacity: 0;
			transition: opacity 0.2s linear;
			animation: pswp-clockwise 600ms linear infinite;
		}

		&--active .pswp__icn {
			opacity: 0.85;
		}
	}

	&__counter {
		height: 30px;
		margin: 15px 0 0 20px;
		font-size: 14px;
		line-height: 30px;
		color: $pswp-icon-color;
		text-shadow: 1px 1px 3px $pswp-icon-color-secondary;
		opacity: 0.85;

		.pswp--one-slide & {
			display: none;
		}
	}

	&__custom-caption {
		position: absolute;
		left: 50%;
		bottom: 16px;

		width: calc(100% - 32px);
		max-width: 600px;
		padding: 2px 8px;

		//noinspection CssOverwrittenProperties
		background-color: rgba(255, 255, 255, 0.8);
		//noinspection CssOverwrittenProperties
		background-color: hsla(0 0% 100% / 80%);
		backdrop-filter: saturate(180%) blur(20px);
		background-clip: padding-box;
		box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.50);

		font-size: $fontSizeDefault;
		border-radius: 4px;
		transform: translateX(-50%);
	}

	&__hidden-caption-content {
		display: none;
	}
}
