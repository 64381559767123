@import "../variables";
@import "../breakpoint";

.bs-content {
	padding: {
		top: $siteLogoSmallTotalSize;
		left: $gridGap;
		right: $gridGap;
	}

	@media print {
		padding-top: 0; // top banner is position: static; when printing
	}

	@include breakpoint($breakpointTabletAndUp) {
		min-height: calc(100vh - #{$estimatedFooterHeight});
	}

	.bs-site--app & {
		padding-top: 0;
	}

	// these page types have an intro and do not need the extra space
	.bs-site--homepage &,
	.bs-site--minisitehome &,
	.bs-site--rubric & {
		padding-top: 0;
	}

	.bs-asset--oversized,
	&__item--oversized {
		margin-left: -$gridGap;
		margin-right: -$gridGap;
	}

	.bs-site--full-width & {
		@include breakpoint($breakpointSidebarFixed) {
			padding: {
				left: $gridGap * 2;
				right: $gridGap * 2;
			}

			.bs-asset--oversized,
			&__item--oversized {
				margin-left: -$gridGap * 2;
				margin-right: -$gridGap * 2;
			}
		}
	}
}
