@use "sass:math";

.flickity-viewport {
	overflow: hidden;
	position: relative;
}

.flickity-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.flickity-button {
	position: absolute;
	//noinspection CssOverwrittenProperties
	background-color: rgba(255, 255, 255, 0.75);
	//noinspection CssOverwrittenProperties
	background-color: hsla(0 0% 100% / 70%);
	backdrop-filter: saturate(180%) blur(20px);
	background-clip: padding-box;
	box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.50);
	border: 0;
	color: #333;
	padding: 0;
	transition: opacity $transitionDurationFast $transitionEasingDefault;

	&:hover {
		background-color: white;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}

	&:focus-visible,
	&.focus-visible {
		background-color: rgba(255, 255, 255, 0.9);
		color: #000;
	}

	&:active {
		opacity: 0.6;
	}

	&:disabled {
		opacity: 0.1;
		cursor: auto;
		/* prevent disabled button from capturing pointer up event. #716 */
		pointer-events: none;
	}
}

.flickity-button-icon {
	fill: #333;
}

.flickity-prev-next-button {
	top: calc(50% - #{math.div(42px + $minimumCaptionHeight, 2)});
	width: 42px;
	height: 42px;
	border-radius: 50%;

	&.previous {
		left: 10px;
	}

	&.next {
		right: 10px;
	}

	.flickity-button-icon {
		position: absolute;
		left: 20%;
		top: 20%;
		width: 60%;
		height: 60%;
	}
}

/* ---- page dots ---- */
.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;

	.dot {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin: 0 8px;
		background: #333;
		opacity: 0.25;
		cursor: pointer;

		&.is-selected {
			opacity: 1;
		}
	}
}

.flickity-enabled {
	position: relative;
	opacity: 1;

	&:focus {
		outline: none;
	}

	&.is-draggable {
		-webkit-tap-highlight-color: transparent;
		user-select: none;

		.flickity-viewport {
			cursor: move;
			cursor: -webkit-grab;
			cursor: grab;

			&.is-pointer-down {
				cursor: -webkit-grabbing;
				cursor: grabbing;
			}
		}
	}
}
