@import "variables";
@import "../mixins/button";
@import "../mixins/text-link";

// A-Z buttons
#fieldset_buttons_citygovObjectSearch {
	margin-left: -5px;

	.SP-Button {
		display: inline-block;
		-webkit-appearance: none;
		box-sizing: border-box;
		width: 42px;
		height: 38px;
		padding: 0;
		margin: 5px 0 0 5px;
		border: 1px solid $colorBorderPrimary;

		background: none white;
		color: $colorTextPrimary;

		text-transform: uppercase;
		transition: background-color $transitionDurationDefault $transitionEasingDefault,
		color $transitionDurationDefault $transitionEasingDefault;

		&:not(.SP-Button--active):hover,
		&:not(.SP-Button--active):focus {
			background-color: $colorOffsetSegment;
		}

		&--active {
			color: white;
			background-color: $colorTextOffsetSegment;
			cursor: default;
		}
	}
}
