@import "../../breakpoint";
@import "../../variables";


.bs-site-logo {
	$b: &;

	&__breadcrumb-wrapper {
		display: inline-block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -11px;
		overflow: hidden;
		white-space: nowrap;

		&::before {
			content: "\2026";
			position: absolute;
			left: 0;
			bottom: $siteLogoSmallBorderWidth;
			z-index: 2;
			padding: 0 5px;
			transition-timing-function: $transitionEasingSiteLogo;
			transition-property: transform, opacity;
			opacity: 0;
			transform: translate(-100%, 0);
			background-color: #fff;
			font-size: $fontSizeSmaller;

			#{$b}--with-transition & {
				transition-duration: $transitionDurationSiteLogo;
			}
		}

		.no-js &::before,
		&--has-overflow::before {
			transform: none;
			opacity: 1;
		}
	}

	&__breadcrumb {
		display: flex;
		line-height: 1;
		font-size: $fontSizeSmaller;

		&-wrapper--has-overflow & {
			justify-content: flex-end;
		}

		.js #{$b}--big & {
			position: absolute;
			margin-top: 0;
			transition-timing-function: $transitionEasingSiteLogo;
			transition-property: transform, opacity;
			opacity: 0;
			transform: translate(-100%);
		}

		#{$b}--with-transition & {
			transition-duration: $transitionDurationSiteLogo;
		}

		&-list {
			display: inline-flex;
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li > a {
			text-transform: uppercase;
			font-style: italic;
			position: relative;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: {
				top: 10px;
				bottom: $siteLogoSmallBorderWidth - 10px;
				left: 15px;
				right: 10px;
			}
			vertical-align: text-bottom;
			text-decoration: none;
			display: inline-block;

			@each $themeName, $theme in $themes {
				$color: bs-theme-color($themeName, "headlineColor", $colorTextPrimary);

				.bs-site--theme-#{$themeName} & {
					color: $color;

					svg {
						fill: $color;
					}
				}
			}

			@include breakpoint($breakpointMobile) {
				max-width: 150px;
			}

			@include bs-arrow-box(
				$boxSize: (5px, $fontSizeSmaller),
				$arrowSize: 4px,
				$pos: "before",
				$box: false,
				$vAlign: "middle"
			);

			@include bs-arrow-box-color-fg(inherit);
		}
	}
}
