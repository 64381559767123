@import "variables";
@import "breakpoint";
@import "./mixins/text-medium";
@import "./mixins/headlines";

$ms3-iconPath: "../img/";
$ms3-fullscreen-top: 0px; // px is required even for zero, because this var will be used in calc()
$ms3-listIconWidth: 34px;
$ms3-searchWidth: 300px;

$ms3-primaryColor: $colorTextPrimary;
$ms3-themeColorA: $colorTextPrimary;
$ms3-themeColorB: $colorPrimary;
$ms3-attributionsBackgroundColor: rgba(255, 255, 255, 0.8);
$ms3-borderColor: $colorBorderPrimary;

$ms3-mapBackgroundColor: #e3e3e3;

$ms3-zIndexListToggleButton: 1000;
$ms3-zIndexAppOverlay: 90000;
$ms3-zIndexViewportButton: 300;
$ms3-zIndexListHeader: 70;

$ms3-zIndexWrapper: 0;
$ms3-fullscreen-zIndexWrapper: 10002;

$ms3-fontSizeHeadline: $fontSizeDefault;

$ms3-option-mapOverlayButtonsShowLabel: false;
$ms3-option-stripedList: false;
$ms3-option-listInfoShowInFullscreen: false;
$ms3-option-appTitleShow: false;

@import "@mapsight/ui/src/scss/default";

// fullscreen support
//.ms3--map-no-scroll {
//  	overflow: hidden !important;
//}

@media print {
	// Override global background removal in braunschweig project
	.ms3-attribution,
	.ms3-panel--docked-left .ms3-list-wrapper {
		background: white !important;
	}

	.ms3-logo {
		background: transparent url($ms3-iconPath + 'mapsight-ui/watermark.svg') no-repeat 0 0 !important;
		background-size: 120px 40px !important;
	}

	// Hide some overlays
	.ms3-search-overlay,
	.ms3-layer-switcher-overlay,
	.ms3-map-overlay__button--desktop-view-toggle,
	.ms3-map-overlay__button--mobile-view-toggle,
	.ms3-map-overlay__button--share-link,
	.ms3-list-toggle-button {
		display: none;
	}
}

.ms3-search-result {
	font-size: $fontSizeSmaller;

	&__entry__subline {
		margin-top: 0;
		font-size: $fontSizeSmallest;
	}
}

// disable ui tooltips
[class*="ms3-hint--"]::before,
[class*="ms3-hint--"]::after {
	display: none !important;
}

.ms3-switcher-header {
	&__count {
		display: none;
	}
}

.ms3-wrapper--mobile > .ms3-additional-container {
	display: flex;
	flex-direction: column;
}

.ms3-marginal {
	line-height: inherit; // TODO: Remove default in @mapsight/ui
	margin-top: 0; // TODO: Remove default in @mapsight/ui

	@include breakpoint($ms3-breakpoint-desktop) {
		margin-top: 30px;
	}

	&--left {
		.ms3-additional-container & {
			.ms3-wrapper--desktop & {
				flex-shrink: 0;
				width: 30%;
				max-width: 320px;

				& + .ms3-standalone-main-panel-container {
					width: 67%
				}
			}
		}
	}
}

.ms3-scroll-target {
	.ms3-wrapper--mobile &,
	.ms3-wrapper--desktop & {
		scroll-snap-margin-top: $siteLogoSmallTotalSize;
		scroll-margin-block-start: $siteLogoSmallTotalSize;
	}
}

.ms3-map-wrapper {
	max-height: 50vh;
	font-size: 13px;
	transition: max-height 20ms ease-out; // basically disable transition to make transition from/to fullscreen less flickery, but keeping transitionend events

	// trick mapsight ui to scroll one pixel higher, showing the border around the map-row
	border-top: 1px solid transparent;
	margin-top: -1px;
}

.ms3-attribution {
	min-height: 22px;
	padding: 4px 10px;

	button {
		@include bs-text-medium;
	}
}

.ms3-input-container {
	&__element {
		padding-bottom: 6px;
	}
}


.ms3-query-input-with-label {
	&__label {
		@include visually-hidden;
	}

	&__input-container {
		width: 100%;
	}

	&__input {
		padding: 6px 40px 6px 8px;
		box-sizing: border-box !important;
		appearance: none !important;
		border: 1px solid #7e7e7e;
		border-radius: 4px;
	}
}

// disabled for now, as we do not have the properties on the features required for sorting
.ms3-features-sorting {
	display: none;
}

.ms3-search-overlay {
	.ms3-wrapper--desktop &,
	.ms3-wrapper--fullscreen & {
		margin: 10px 0 5px 15px;
	}
}

.ms3-layer-switcher {
	&-overlay &-container {
		// since mapsight/ui 5 the map overlay areas are flex containers which change relative positions
		// so we need to compensate to move the layer switch down to the top of the button
		top: 5px;
	}

	.ms3-additional-container &-container {
		@include breakpoint($ms3-breakpoint-mobile) {
			padding: 10px;
			margin-bottom: 10px;
		}
	}

	&__header {
		@include bs-text-medium;
		padding: 4px 6px;
	}

	&__entries {
		.ms3-map-wrapper & {
			font-size: 16px;
		}
	}

	&__entries + &__header {
		margin-top: 20px;
	}

	&__entry {
		&__count {
			display: none;
		}
	}

	@include breakpoint($ms3-breakpoint-desktop) {
		&__entries + &__header {
		}
		margin-top: 20px;
	}
}

.ms3-viewport-button {
	&--bottom-right {
		z-index: 300;
		margin-bottom: 6vh;
		margin-right: 3vw;
	}
}

.ms3-wrapper {
	&--mobile {
		.bs-article & {
			margin: 0 #{-$articlePaddingTabletAndUp};
		}
	}

	&--withoutmap {
		.ms3-main-container {
			display: none;
		}
	}
}

.ms3-map-overlay {
	overflow: hidden;

	&__area {
		* {
			touch-action: none !important;
		}

		&--bottom-right {
			// since mapsight/ui 5 info-overlay was split into left/right areas an right is not full width which breaks
			// the attribution width, so we stretch it back to span the whole container:
			left: 0;

			.ms3-attribution {
				max-width: none;
			}
		}
	}
}

.ms3-tool-overlay {
	&__text {
		max-width: 240px;
	}
}

.ms3-measure-distance {
	border-top: 1px solid #ccc;
	padding: 10px 6px 10px 6px;
}

.ms3-map-row {
	.ms3-wrapper--desktop &,
	.ms3-wrapper--mobile & {
		border: 1px solid $colorBorderPrimary;
	}
}

.ms3-wrapper--fullscreen .ms3-search-overlay,
.ms3-wrapper--desktop .ms3-search-overlay,
.ms3-map-overlay-combined-buttons,
.ms3-map-overlay__button {
	box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
}

.ms3-list {
	$b: &;

	&-header {
		border-top: 1px solid white;
		padding: 0 10px;

		// FIXME: should be js-is-stuck but the class never gets applied, have to check sticky helper in mapsight ui
		&.js-is-sticky,
		&.js-is-stuck {
			padding: 0 10px;
			margin: 0 !important; // FIXME: The negative margin (left/right) should be removed from mapsight ui!

			.ms3-wrapper--desktop &,
			.ms3-wrapper--mobile & {
				border-top: 1px solid $colorBorderPrimary;
			}
		}
	}

	&-wrapper {
		border-bottom: 1px solid $colorBorderPrimary;

		.js .ms3-panel--docked-left & {
			width: 100%;
			height: 100%;
			margin: 0;
			background: white;
		}

		h3 {
			@include bs-text-medium;
			@include bs-headline-color;
			margin: 16px 5px 5px 10px;
			font-size: 2rem;
		}

		@include breakpoint($ms3-breakpoint-mobile) {
			padding: 0;
		}
	}

	&-cycling-box {
		&__dots {
			gap: 4px;
		}

		&__dot {
			transition: transform 200ms ease-out;
			transform: scale(0.8);
			transform-origin: center center;

			&:hover,
			&:focus {
				transform: scale(1.1);
			}

			&--selected {
				transform: scale(1);

				&::before {
					@include bs-site-theme-property("primaryColor", background-color);
				}
			}
		}

		@include breakpoint($ms3-breakpoint-desktop) {
			margin-bottom: 5px;
		}
	}

	&__filter-box {
		max-width: 360px;
		padding: 10px 0;

		.ms3-wrapper--desktop & {
			width: 70%;
		}
	}

	&__empty {
		padding: 10px 10px 20px 10px;
		font-style: italic;
	}

	&__item {
		display: block;
		border-top: 1px solid $colorBorderPrimary;

		&__head {
			display: flex;
		}

		&--selected {
			color: $colorTextPrimary;
		}
	}

	&__icon {
		flex-shrink: 0;
		padding: 3px 6px;
	}

	&__info {
		flex-shrink: 1;
		padding: 5px;

		&:empty {
			display: none;
		}

		overflow: hidden;

		.ms3-wrapper:not(.ms3-wrapper--desktop) & {
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.ms3-wrapper--desktop & {
			display: block;

			padding: 0 10px 10px 11px;
			margin-left: 35px;
			color: $colorTextPrimary;
			font-size: $fontSizeSmaller;
		}
	}

	&__main {
		flex-shrink: 1;
		padding: 9px 0 7px 0;

		.ms3-wrapper--mobile #{$b}__item--selected & {
			@include bs-text-medium;
			color: $colorTextPrimary;
		}

		.ms3-wrapper--desktop & {
			@include bs-text-medium;
		}
	}

	&__link {
		text-decoration: none;
	}

	&__details {
		@include breakpoint($ms3-breakpoint-mobile) {
			padding: 0 10px;
		}
	}
}

.ms3-feature-details-content {
	&--description {
		font-size: $fontSizeSmaller;
	}
}

.ms3-feature-selection-info {
	&__header {
		padding: 15px 50px 12px 15px;
	}

	&__headline {
		margin: 0;
	}

	&__wrapper {
		.ms3-wrapper--mapOnly & {
			height: 40vh;
		}
	}

	&__content {
		.ms3-wrapper:not(.ms3-wrapper--mobile) & {
			padding: 0 15px 20px;
		}
	}

	.ms3-sticky-header__header &__headline {
		@include bs-text-medium;
		font-size: $ms3-fontSizeHeadline;
		line-height: 1.3;
		color: $ms3-themeColorA;
	}

	.ms3-wrapper--desktop &,
	.ms3-wrapper--fullscreen & {
		height: 100%;
		margin: 0;
		border-radius: 0;
		border-right: 1px solid #ccc;
	}

	&__close-button {
		.ms3-wrapper--desktop &,
		.ms3-wrapper--fullscreen & {
			margin-right: 20px;
			transition: margin-right $transitionEasingDefault $transitionDurationSuperFast;
		}

		.ms3-sticky-header__container--stuck & {
			.ms3-wrapper--desktop &,
			.ms3-wrapper--fullscreen & {
				margin-right: 10px;
			}
		}
	}

	.bs-asset--oversized,
	.bs-content__item--oversized {
		max-width: 330px;
		margin-left: -15px;
		margin-right: -15px;
	}

	* + .bs-content-element {
		margin-top: 10px;
	}

	.bs-asset {
		margin-bottom: 10px;
	}

	.bs-carousel {
		height: 234px;

		&.flickity-enabled {
			height: 100%;
		}
	}
}


@import "mapsight-ui-integration/besser-smart";

.ms3-tag-switcher {
	padding: 10px;
	margin-bottom: 10px;

	@include breakpoint($ms3-breakpoint-mobile) {
		border-bottom: 1px solid $ms3-borderColor;
	}
}

.m3-additional-container {
	.ms3-wrapper--mobile & {
		padding: 10px 20px;
	}
}

.ms3-panel {
	&--docked-above,
	&--docked-below {
		max-height: 40vh;
	}
}


// FIXME kein Scroll when modaler dialog geöffnet wird. stattdessen position: absolute; relativ zum body
// TODO mit breakpoints, 2 Spalten: 450px -stadtplan
.ReactModalPortal .ms3-modal {
	max-width: 95vw;

	.ms3-attribution {
		padding: {
			top: 4px;
			right: 40px;
			bottom: 4px;
			left: 10px;
		}
	}

	.ms3-dialog-close-button {
		border-radius: 0;
	}
}

// Do not link to mapsight from within mapsight.
.bs-mapsight-embed a[href*="mapsight-link"] {
	text-decoration: none;
	font-weight: inherit;
	pointer-events: none;
}

.ms3-modal__inner--with-legend {
	padding-top: 10px;
	padding-bottom: 10px;
	max-width: 920px;
	min-width: 920px;
	overflow-x: hidden;
}

//.ms3-modal__inner--with-legend .ms3-attribution__entries,
.bs-ms3-legende--stadtplan {
	max-width: 900px;
	min-width: 900px; // needed for some browsers when switching beetween smaller and greater
}

@media (max-width: 1024px) {
	.ms3-modal__inner--with-legend {
		max-width: 470px;
		min-width: 470px;
	}

	//.ms3-modal__inner--with-legend .ms3-attribution__entries,
	.bs-ms3-legende--stadtplan {
		max-width: 450px;
		min-width: 450px; // needed for some browsers when switching beetween smaller and greater
	}
}

@media (max-width: 550px) {
	.ms3-modal__inner--with-legend {
		max-width: 260px;
		min-width: 260px;
	}

	//.ms3-modal__inner--with-legend .ms3-attribution__entries,
	.bs-ms3-legende--stadtplan {
		max-width: 225px;
		min-width: 225px;
	}
}

