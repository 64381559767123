@import "../mixins/site-theme-property";

.bs-collapsible {
	&.bs-section {
		margin-top: 30px;

		& + & {
			margin-top: 0;
		}

		.bs-headline--section {
			transition: padding-top $transitionDurationDefault $transitionEasingDefault;

			&[aria-expanded="false"] {
				transition: padding-top 1s $transitionEasingDefault;
				padding-top: 5px;
			}
		}
	}

	&__trigger {
		margin-bottom: 0 !important;
		cursor: pointer;

		.no-focus-outline & {
			outline: 0;
		}

		&__icon {
			@include bs-site-theme-property('headlineColor', fill);

			float: left;
			margin: -4px 0 0 -8px;
			transform: rotate(90deg);
			transition: transform $transitionDurationSlower $transitionEasingDefault;

			.bs-icon {
				width: $buttonSize;
				height: $buttonSize;
			}
		}

		&[aria-expanded="true"] &__icon {
			transform: rotate(-90deg);
		}
	}

	&__content {
		transition: max-height 1s $transitionEasingDefault;
		clear: both;

		&--transitioning,
		&--collapsed {
			height: auto;
			overflow: hidden;
		}

		&--collapsed {
			max-height: 0 !important;
		}

		&--expanded:not(&--transitioning) {
			max-height: none;
		}

		&--collapsed:not(&--transitioning) {
			display: none;
		}
	}

	&__content-wrapper {
		padding-top: $gridGap; // contain margin to prevent flickering
	}
}
