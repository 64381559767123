@import "../variables";
@import "../mixins/text-medium";

.bs-translation-disclaimer {
	display: flex;
	padding: 12px;
	margin-bottom: 20px;
	flex-direction: row;
	align-items: center;
	gap: 1rem;

	background: $colorOffsetSegment;
	font-size: $fontSizeSmaller;

	&[aria-hidden="true"] {
		display: none;
	}

	&__icon {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
	}

	&__text {
		min-width: 0;
		flex-shrink: 1;
		line-height: $lineHeightHeading;

		h3,
		p {
			margin: 0;
			padding: 0;
			line-height: inherit;
			font-size: inherit;
		}

		h3 {
			@include bs-text-medium;
		}
	}
}
