@import "../variables";
@import "../mixins/text-medium";


.bs-external-video {
	background: #f5f5f5;
	padding: 20px;
	border: 1px solid #ccc
}

.bs-video {
	width: 100%;
	height: 100%;

	&--placeholder {
		border: 1px solid #ccc;
		overflow: hidden;
	}

	&-overlay-info {
		display: flex;
		height: 100%;
		width: 100%;

		flex-direction: column;
		align-items: center;

		.js-bs-youtube-activate-button {
			background: $colorTextPrimary;
			color: white;
			border: 0;
			border-radius: 4px;
			padding: 3px 6px;
		}
	}

	&__wrapper {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%;
		background: black;
	}

	&__wrapper-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	// Mediaelement.js video player overrides
	.mejs__container {
		@include bs-text-medium;
	}

	.mejs__controls:not([style*='display: none']) {
		background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.55));
	}

	.mejs__poster {
		opacity: 0.6;
	}

	.mejs__overlay-button {
		border: 0;
	}

	.mejs__captions-position {
		text-align: center;
		transition: bottom $transitionDurationDefault $transitionEasingDefault;
	}

	// move it a little up, because we made the caption text and background bigger
	.mejs__captions-position-hover {
		bottom: 50px;
	}

	// We only want readable width of text
	.mejs__captions-text {
		display: inline-block;
		text-align: center;
	}

	.mejs__captions-text {
		@include bs-text-medium;
		font-size: 18px;
		line-height: 26px;
		max-width: 780px;

		// give an text marker background border
		padding: 3px 0;
		background: rgba(20, 20, 20, 0.5);
		box-shadow: 8px 0 0 rgba(20, 20, 20, 0.5), // right
		-8px 0 0 rgba(20, 20, 20, 0.5); // left
	}

	// fine tuning for Ubuntu Font
	.mejs__time {
		font-size: 13px;
		padding-top: 14px;
	}

	// the calculations of Medialement seem to not work well
	.mejs__container-fullscreen {
		.mejs__captions-text {
			max-width: 90vw;
		}

		// move it a little up, because we made the caption text and background bigger
		.mejs__captions-position-hover {
			bottom: 70px !important;
		}

		.mejs__captions-text {
			font-size: 330% !important;
		}
	}
}
