@use "sass:math";

@import "../variables";
@import "../mixins/base-carousel";
@import "../functions/rem-to-px";

$captionPadX: 10px;
$captionPadY: 3px;
$minimumCaptionHeight: (
	2 * $captionPadY +
	2 * $lineHeightDefault * remToPx($fontSizeSmaller)
);
$minimumCaptionHeightSingleLine: (
	2 * $captionPadY +
	1 * $lineHeightDefault * remToPx($fontSizeSmaller)
);
$carouselRatio: math.div(3, 2);

.bs-carousel {
	$b: &;

	@include bs-base-carousel;

	&__item {
		height: 100%;
		margin-right: 10px;
		box-sizing: content-box;

		.bs-asset,
		.bs-asset__main,
		.bs-fixed-size__content,
		picture {
			display: block;
			height: 100%;
		}

		&[aria-selected="false"] {
			pointer-events: none;
		}

		.bs-asset {
			margin: 0;

			&__icons {
				display: none;
			}

			&__main {
				height: calc(100% - #{$minimumCaptionHeight});
			}
		}

		.bs-image-placeholder {
			height: 100%;
		}

		img {
			display: inline-block;
			width: auto;
			height: 100%;
			border: 0;
		}
	}

	// relative height using pseudo element
	.flickity-viewport {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(#{$minimumCaptionHeight} + #{100% * math.div(1, $carouselRatio)});
		}
	}

	&.flickity-enabled .bs-asset__caption {
		position: absolute;
		bottom: 0;
		left: 0;
		box-sizing: border-box;

		width: 100%;
		height: auto;
		min-height: $minimumCaptionHeight;
		padding: {
			left: $captionPadX;
			right: $captionPadX;
			top: $captionPadY;
			bottom: $captionPadY;
		}

		z-index: 2;
		background: $colorPageBackground;
	}

	&--style-smallSquares {
		$sizeSmall: 302px; // incl. 2x1px border
		$sizeBig: 440px; // incl. 2x1px border

		// Crop image in client to square (cannot use server side cropping or transition to lightbox breaks)
		.bs-asset__main img {
			object-fit: cover;
			object-position: 50% 50%;
			border: 1px solid $colorBorderPrimary;
		}

		#{$b}__item,
		.bs-asset__main img {
			width: $sizeSmall;
			height: $sizeSmall;
			overflow: hidden;
			box-sizing: border-box;

			@include breakpoint($breakpointDesktopAndUp) {
				width: $sizeBig;
				height: $sizeBig;
			}
		}

		// Slightly bigger gap
		#{$b}__item {
			height: #{$sizeSmall + $minimumCaptionHeightSingleLine};
			margin-right: 20px;

			@include breakpoint($breakpointDesktopAndUp) {
				height: #{$sizeBig + $minimumCaptionHeightSingleLine};
			}
		}

		// Single line caption without overflow
		.bs-asset__main {
			height: calc(100% - #{$minimumCaptionHeightSingleLine});
		}

		// fixed height viewport
		.flickity-viewport::before {
			padding-bottom: #{$minimumCaptionHeightSingleLine + $sizeSmall};

			@include breakpoint($breakpointDesktopAndUp) {
				padding-bottom: #{$minimumCaptionHeightSingleLine + $sizeBig};
			}
		}

		&.flickity-enabled .bs-asset__caption {
			border-top: 1px solid $colorBorderPrimary;
			min-height: $minimumCaptionHeightSingleLine;
		}

		.bs-asset__caption__text {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
