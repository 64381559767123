@import "../variables";
@import "../breakpoint";
@import "../mixins/text-medium";
@import "../mixins/headlines";
@import "../mixins/object-fit";

.bs-article-header {
	position: relative;
	padding-top: $gridGap;

	&__meta-corner {
		min-height: $gridGap;
	}

	&__image-banner {
		@include object-fit(cover);
		width: 100%;
		height: 200px;
		z-index: 1;
	}

	h1 {
		@include bs-headline-page;
		margin: 0;
		padding-top: $gridGap;
		padding-bottom: $gridGap;

		.bs-site--app & {
			padding-top: 10px;
		}
	}

	& &__image-banner + h1 {
		padding-top: $gridGap * 2;
	}

	p {
		@include bs-text-medium;
		padding-bottom: 2rem;
	}

	@include breakpoint($breakpointTabletAndUp) {
		.bs-asset--full {
			margin-left: -$articlePaddingTabletAndUp;
			margin-right: -$articlePaddingTabletAndUp;
		}
	}
}
