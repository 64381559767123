@import "../variables";
@import "../breakpoint";

.bs-quote {
	font-style: italic;

	blockquote {
		margin: 0;
		padding: 0 0 0 20px; // TODO: magic number
	}
}
