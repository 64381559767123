@import "../variables";

.SP-Errors {
	color: #960000;
	border: 1px solid #d00;
	padding: 10px;
	margin: 10px -10px;
	background: #ffe8e8;

	a {
		color: inherit;
	}
}
