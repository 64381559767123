@import "../variables";

.bs-banner {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	overflow: hidden;

	&__main {
		flex-grow: 1;
	}

	&__image {
		width: 100%;
		height: auto;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-grow: 1;
		padding: 10px;
		order: 0;
		background: $colorOffsetSegment;

		img {
			width: 100%;
			max-width: 200px;
			height: auto;
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		flex-direction: row;

		&__logo {
			width: 300px;
			flex-grow: 0;
			order: 1;
			padding: 0;
		}

		&__main {
			order: 2;
		}

		&__figure {
			display: flex;
			min-height: 100%; // if the banner is less high that the logo
		}

		// if the banner is less high that the logo
		&__image {
			min-height: 100%;
			object-fit: cover;
		}
	}
}
