@import "../variables";
@import "../breakpoint";
@import "../mixins/text-link";
@import "../mixins/text-medium";

.bs-paragraph {
	ul, ol, dl, p {
		max-width: $contentMaxWidth;
		margin: 0 0 1em 0;

		.bs-site--full-width & {
			@include breakpoint($breakpointSidebarFixed) {
				max-width: $contentMaxWidthFullWidth;
			}
		}
	}

	address {
		font-style: normal;
	}

	h1, h2, h3, h4, h5, h6 {
		a & {
			text-decoration: none;
		}
	}

	a {
		@include bs-text-link;
	}

	b,
	strong {
		@include bs-text-medium;
	}

	table {
		width: auto;
		border-spacing: 0;

		thead,
		th {
			@include bs-text-medium;
		}

		td,
		th {
			margin: 0;
			padding: 5px 10px;
			text-align: left;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		tr:not(:last-child),
		thead {
			td, th {
				border-bottom: 1px solid $colorBorderPrimary;
			}
		}
	}

	@media
		(pointer: none),
		(pointer: coarse),
		(hover: none)
	{
		abbr[title] {
			position: relative;

			&:hover {
				&::after {
					content: attr(title);

					/* position tooltip like the native one */
					position: absolute;
					left: 0;
					bottom: -30px;
					width: auto;
					white-space: nowrap;

					/* style tooltip */
					background-color: #1e1e1e;
					color: #fff;
					border-radius: 3px;
					box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
					font-size: 14px;
					padding: 3px 5px;
				}
			}
		}
	}

	&--condensed {
		h1, h2, h3, h4, h5, h6,
		table,
		ul, ol, dl, p {
			margin: 4px 0;
		}

		h1, h2, h3, h4, h5, h6 {
			&:not(:first-child) {
				margin-top: 12px;
			}
		}
	}
}
