@import "../../variables";
@import "../../functions/theme-color";

.bs-sidebar-header {
	position: relative;
	flex-shrink: 0;

	text-align: right;
	color: $colorTextPrimary;
	background-color: white;
	padding-top: $siteLogoSmallBorderWidth - 7px; // TODO: rm whitespace from buttons
	padding-right: $siteLogoSmallBorderWidth - 7px; // TODO: rm whitespace from buttons
	min-height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;

	.bs-sidebar-home-button {
		fill: $colorTextPrimary;
	}

	@each $themeName, $theme in $themes {
		$color: darken(bs-theme-color($themeName, "headlineColor", $colorTextPrimary), 5%);

		.bs-theme--#{$themeName} & {
			color: $color;

			.bs-sidebar-home-button {
				fill: $color;
			}
		}
	}

	@media print {
		.bs-sidebar-home-button {
			fill: black !important;
		}
	}

}
