@import "../variables";
@import "../mixins/text-light";

.bs-article {
	@include bs-text-light;

	&__intro-image.bs-asset {
		margin-bottom: 30px;
	}
}
