@mixin bs-email-separator-replacement {
	// E-Mail @ and .
	i {
		font-style: normal;

		&::before {
			content: "@";
		}

		&::after {
			content: ".";
		}

		//@include breakpoint(max-width $config-atom-link-wordbreak-breakpoint) {
		//	word-break: break-all;
		//}
	}
}
