@import "../../variables";

.bs-sidebar-panel {
	box-sizing: border-box;

	display: flex;
	flex-direction: column;

	overflow: hidden;

	transform: translate(100%, 0);
	transition: transform $transitionDurationDefault $transitionEasingDefault;
	transform-origin: top left;

	// fix blurry linear-gradient in chrome by adding a white shadow on top
	box-shadow: 0 0 0 0 white inset;

	background-color: map-get(map-get($themes, default), 'navBgColor');
	@each $themeName, $theme in $themes {
		&.bs-theme--#{$themeName} {
			background-color: map-get($theme, 'navBgColor');
		}
	}

	.bs-sidebar-panels > & {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
	}

	// Hide uninitialized, lazy loaded sub menus
	.bs-sidebar-list .bs-sidebar-list {
		display: none;
	}

	&--search {
		background: $colorOffsetSegment;
	}

	&--opened {
		z-index: 1;
		transform: translate(0, 0);
	}

	&--opened-parent {
		transform: translate(#{-$sidebarTransitionTranslationTargetForHiding}, 0); // TODO: Was ist das? Warum 30%?
	}

	&--highest {
		z-index: 2;
	}

	&--no-animation {
		transition: none !important;

		&.bs-sidebar-panel--opened-parent {
			transform: translate(0, 0);
		}
	}

	&--loading {
		display: none;
	}
}
