@use "sass:math";

@import "../variables";
@import "../breakpoint";
@import "../mixins/site-theme-property";

.bs-section {
	clear: both;
	margin-top: $gridGap;

	&__anchor {
		display: block;
		position: relative;
		// trick browser to scroll higher so the headline is not stuck behind the fixed site banner without
		// needing to use padding-top: x; margin-top: -x; on the headline itself,
		// see https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header/11842865#11842865
		top: -60px;
	}

	// use neagtive margin to make oversized content not cut off while collapsing
	.bs-content > & > .bs-collapsible__content {
		margin: 0 #{-$gridGap};
		padding: 0 $gridGap;
	}

	// add horizontal lines between header-less (=not <section>) section by the editor (= --editorial)
	&--editorial:not(section) {
		border-top: 1px solid;
		padding-top: $gridGap;
		@include bs-site-theme-property('headlineColor', border-color);

		// except the first after intro/article header
		header + &,
		.bs-anchor-list + &,
		.bs-article-header + & {
			border-top: 0;
			padding-top: 0;
		}
	}

	.bs-article-header + & {
		> .bs-headline--section {
			margin-top: -#{math.div($sectionHeadlineTopPadding, 2)};
		}
	}
}
