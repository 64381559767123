@use "sass:math";

// Debug
$debug: false;

// Site colors
$colorPrimary: #555 !default; // alternative: #ff3714   (neues Braunschweig-rot ist #ff3714)
$colorAction: $colorPrimary !default;
$colorSecondary: #b3b3b3 !default;

$colorBorderPrimary: #d6d6d6 !default;
$colorBorderSecondary: #d6d6d6 !default;

$colorOffsetSegment: #f5f5f5 !default;
$colorOffsetSegmentSecondary: #d6d6d6 !default;

$colorSiteBackground: #e3e3e3 !default; // back most background/letter-box
$colorPageBackground: white !default; // the actual "page"

// Misc
$sitePageDimmingColor: black !default;
$sitePageDimmingOpacity: 0.7 !default;

// Text colors
$colorTextPrimary: #4a4a4a !default;
$colorTextOffsetSegment: #3d3d3c !default;

$colorTextSecondary: #767676 !default; // TODO: make darker to pass WCAG contrast ratio; #767676
$colorTextSidebarHeadline: #8d8b89 !default;

// Theme colors
// TODO: adjust colors to pass WCAG contrast ratio
$themes: (
	default: (
		label: "Default Theme",
		primaryColor: $colorTextPrimary,
		headlineColor: $colorTextPrimary,
		iconColor: $colorTextPrimary,
		actionColor: black,
		navSelectedBgColor: $colorTextPrimary, // Lc >= 90 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: $colorPageBackground,
		navBgColor: $colorOffsetSegment,
	),
	Lebn: (
		label: 'Leben in Braunschweig',
		primaryColor: #78BE20, // Pantone 368 C
		//headlineColor: #60991A, // primaryColor darkened, Lc 61.9 against white bg => 18px/600w okay
		headlineColor: #78BE20, // =
		iconColor: #78BE20, // =
		secondaryColor: #009351, // Pantone 347 C
		navSelectedBgColor: #528316, // 4.5:1 / Lc 74 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: #d3e6bc,
		navBgColor: #e4f0d5,
	),
	WiWi: (
		label: 'Wirtschaft & Wissenschaft',
		primaryColor: #0095c8, // Pantone 639 C
		headlineColor: #0095c8, // = 3.1:1 / Lc 61.09 against white bg
		iconColor: #0095c8,
		secondaryColor: #006ba6, // Pantone 307 C
		navSelectedBgColor: #007EA8, // 4.5:1 / Lc 74 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: #bfe7f5,
		navBgColor: #d5edf5,
	),
	PoVe: (
		label: 'Politik & Verwaltung',
		primaryColor: #ef6e00, // Pantone 021 C
		headlineColor: #e06900, // darkened 3.1:1 / Lc 60.66 against white bg
		iconColor: #e06900,
		secondaryColor: #d04a14, // Pantone 7580 C
		navSelectedBgColor: #BD5800, // 4.5:1 / Lc -74 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: #ffdec2,
		navBgColor: #ffedde,
	),
	KuVe: (
		label: 'Kultur & Veranstaltungen',
		primaryColor: #991e66, // Pantone 7648 C
		headlineColor: #991e66, // = 6.7:1 / Lc 85.3 against white bg
		iconColor: #991e66, // =
		secondaryColor: #6d2077, // Pantone 259 C
		navSelectedBgColor: #80265a, // 8.6:1 / Lc -91.51 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: #e6b8d2,
		navBgColor: #ffe6f4,
	),
	ToKo: (
		label: 'Tourismus & Kongresse',
		primaryColor: #ffd100, // Pantone 109 C
		headlineColor: #A88900, // darkened 3.1:1 / Lc 60.87 against white bg
		iconColor: #A88900, // =
		secondaryColor: #cc8a00, // Pantone 131 C
		navSelectedBgColor: #e6cc5c, // 5.6:1 / Lc 76.95 behind black text
		navSelectedTextColor: black,
		navHoverBgColor: #faf1c8,
		navBgColor: #fffae6,
	),
	DiOn: (
		label: 'Digitalisierung & Online-Services',
		primaryColor: #58b7b8, // Pantone 7466 C
		//headlineColor: #4c9596, // primaryColor darkened, Lc 62.1 against white bg => 18px/600w okay
		headlineColor: #3b9a9b, // darkened 3.1:1 / Lc 60.60 against white bg
		iconColor: #3b9a9b, // =
		secondaryColor: #007680, // Pantone 7714 C
		navSelectedBgColor: #377F81, // 4.6:1 / Lc -74.87 behind white text
		navSelectedTextColor: white,
		navHoverBgColor: #c1e7e8,
		navBgColor: #cef4f5,
	),
	mini1: (
		label: 'Minisite 1 (weiß)',
		primaryColor: black,
		headlineColor: #222,
		iconColor: #222,
		secondaryColor: #666,
		navSelectedBgColor: #444,
		navSelectedTextColor: white,
		navHoverBgColor: white,
		navBgColor: white,
	),
	BesserSmart: (
		label: 'Minisite Besser Smart',
		primaryColor: #991e66, // See KuVe
		iconColor: #991e66, // See KuVe
		headlineColor: #991e66, // See KuVe
		secondaryColor: #6d2077, // See KuVe
		navSelectedBgColor: #991e66, // See KuVe
		navSelectedTextColor: white,
		navHoverBgColor: #e6b8d2,
		navBgColor: #ffe6f4,
	),
	mini2: (
		label: 'Minisite 2 (grau)',
		primaryColor: #999,
		headlineColor: #8a8a8a,
		iconColor: #8a8a8a,
		secondaryColor: #999,
		navSelectedBgColor: #999,
		navSelectedTextColor: white,
		navHoverBgColor: #dadada,
		navBgColor: #ebebeb,
	),
	Krise: (
		label: 'Krisenrubrik (Corona)',
		primaryColor: #777,
		headlineColor: #777,
		iconColor: #777,
		secondaryColor: #777,
		navSelectedBgColor: #555,
		navSelectedTextColor: white,
		navHoverBgColor: #d9d9d9,
		navBgColor: #e6e6e6,
	),
	Test: (
		label: 'Test',
		primaryColor: #2b9b8b,
		headlineColor: #2b9b8b,
		iconColor: #2b9b8b, // =
		secondaryColor: #2b9b8b,
		navSelectedBgColor: #248476,
		navSelectedTextColor: white,
		navHoverBgColor: #c0e8e0,
		navBgColor: #caf6f1,
	),
	Mice: (
		label: 'MICE',
		primaryColor: #991e66, // See KuVe
		headlineColor: #991e66, // See KuVe
		iconColor: #991e66, // See KuVe
		secondaryColor: #75b824,
		navSelectedBgColor: #991e66, // See KuVe
		navSelectedTextColor: white,
		navHoverBgColor: #e6b8d2,
		navBgColor: #ffe6f4,
	),
	Himbeer: (
		label: 'Tourismus & Kongresse',
		primaryColor: #ba0c2f,
		headlineColor: #ba0c2f,
		iconColor: #ba0c2f,
		secondaryColor: #ba0c2f,
		navSelectedBgColor: #a11a35,
		navSelectedTextColor: white,
		navHoverBgColor: #e6b8c1,
		navBgColor: #ffe6eb,
	),
	Irland: (
		label: 'Tourismus & Kongresse',
		primaryColor: #00965e, // Pantone 340 C
		headlineColor: #00965e,
		iconColor: #00965e,
		secondaryColor: #006747, // Pantone 342 C
		navSelectedBgColor: #0c7d54,
		navSelectedTextColor: white,
		navHoverBgColor: #e1faf1,
		navBgColor: #f2fffa,
	),
);

// Sizes
$sitePadding: 0px !default;
$siteMaxWidth: 1440px !default;
$sidebarWidth: 310px;
$sidebarLineWidth: 6px;
$sidebarLineWidthHover: 10px;
$sidebarOverscrollHeight: 50px;

$siteTopBarHeight: 52px;
$siteTopBarHeightDesktop: 68px;

$contentMaxWidth: 800px;
$contentMaxWidthFullWidth: 950px;
$estimatedFooterHeight: 440px;

$gridGap: 20px;
$articlePaddingTabletAndUp: $gridGap;

$buttonSize: 40px !default;

$sectionHeadlineTopPadding: 50px;

$sidebarListItemIndent: 18px;
$sidebarListItemMenuExtraIndent: 18px;
$sidebarPanelBarHeight: $buttonSize !default;

// Sizes::Logo
$siteLogoSmallSize: 60px;
$siteLogoSmallBorderWidth: 20px;
$siteLogoSmallTotalSize: $siteLogoSmallSize + 2 * $siteLogoSmallBorderWidth;

$siteLogoBigSize: 120px;
$siteLogoBigBorderWidth: 40px;

$siteLogoContentPadding: 10px;

// This creates a layered effect when the old panel of the sidebar gets hidden slower/less far than the new one gets shown
$sidebarTransitionTranslationTargetForHiding: 30% !default;

// Breakpoints
// NOTE: Keep in sync with src/js/_variables.js !
$breakpointDesktopAndUp: 1024px;
$breakpointTabletAndUp: 768px;

// -> Derived Breakpoints
$breakpointTabletAndDown: 0 ($breakpointDesktopAndUp - 1px);
$breakpointTablet: $breakpointTabletAndUp ($breakpointDesktopAndUp - 1px);
$breakpointMobile: 0 ($breakpointTabletAndUp - 1px);

$breakpointBannerMobile: 0 480px;

// Special Breakpoints
$breakpointSidebarFixed: 920px;
$breakpointSidebarOffCanvas: 0 ($breakpointSidebarFixed - 1px);

$breakpointGridSmall: 630px;
$breakpointGridMid: 900px;
$breakpointGridMidWithFixedSidebar: $breakpointSidebarFixed;
$breakpointGridLarge: 1200px;

// Focus
$focusRingColor: #0091da;

// Animations/Transitions
$transitionDurationDefault: 400ms;
$transitionDurationSlower: 600ms;
$transitionDurationFast: 200ms;
$transitionDurationSuperFast: 120ms;
$transitionEasingDefault: ease-in-out;
$transitionEasingSidebar: ease !default;

$transitionDurationSiteLogo: 250ms;
$transitionEasingSiteLogo: ease;

// Typography
$fontWeightBold: bold; // 700
$fontWeightMedium: 600;
$fontWeightRegular: normal; // 400
$fontWeightLight: 200;

$fontNameUbuntu: "Ubuntu";
$fontFamilyDefault: $fontNameUbuntu, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$fontFamilyMonospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$fontSizeDefault: 1.8rem;
$fontSizeSmaller: 1.5rem;
$fontSizeSmallest: 1.2rem;
$lineHeightDefault: math.div(26, 18); // 1.444
$lineHeightHeading: math.div(34, 27); // 1.3
$paragraphGapDefault: math.div(10, 18) * 1rem;

$rootFontSizeBase: 62.5%;
$rootFontSizeDefault: 1.0 * $rootFontSizeBase;

// Z-Indexes, ordered ascending
$zIndexSiteBackground: -1000;
$zIndexSidebarSearchToggle: 10;
$zIndexSiteLogo: 99;
$zIndexIntroMinisiteLogo: $zIndexSiteLogo;
$zIndexIntroMinisiteTitle: $zIndexSiteLogo;
$zIndexIntroMinisiteFixed: 100;
$zIndexSiteMainDimming: 100;
$zIndexSidebarHeader: 200;
$zIndexSidebarHeaderMobile: 10000;
$zIndexSidebarPageBlockerOpened: 10001;
$zIndexSidebarPageBlocker: 10002;
$zIndexSidebar: 10004;
$zIndexVoiceReader: 10005;
$zIndexScrollToTopButton: 12000;
$zIndexLightbox: 15000;
$zIndexFocus: 20000;
$zIndexTosc5: 30000;
$zIndexSkipLinks: $zIndexFocus;
