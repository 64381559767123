@import "../variables";

.bs-annotations {
	&__headline {
		display: none;
	}

	&__section__headline {
		margin-bottom: 0;
		color: $colorTextSecondary;
		font-size: $fontSizeSmallest;
	}

	&__list {
		color: $colorTextSecondary;
		font-size: $fontSizeSmallest;
		list-style: none;
		padding-left: 0;
		margin-top: 0;
	}

	&__item {
		&, div {
			display: inline;
		}
	}

	&__item + &__item:before {
		content: "; ";
	}
}
