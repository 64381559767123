@import "./text-light";

@mixin bs-file-info {

	@include bs-text-light;
	text-decoration: none;

	abbr {
		text-decoration: dotted;
	}

	&::before {
		content: " (";
	}

	&::after {
		content: ")";
	}

	&__type {
	}

	&__size::before {
		content: " ";
	}
}
