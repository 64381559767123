@import "../mixins/email-separator-replacement";
@import "../mixins/file-info";

@mixin bs-link-special-content {
	&[data-sp-email] {
		@include bs-email-separator-replacement;
	}

	&__file-info {
		@include bs-file-info;
	}
}
