@use "sass:math";

@import "../functions/unit";

/// `&`/"self" needs to be positioned.
/// $boxSize:
/// $pos: "before" or "after"
/// $dir: "left" or "right", direction of the arrow
/// $box: include `bs-arrow-box-box`
/// $vAlign: "middle", "top" or "bottom"
@mixin bs-arrow-box(
	$boxSize,
	$arrowSize,
	$weight: 2px,
	$pos: "before",
	$dir: "right",
	$box: true,
	$vAlign: "middle"
) {
	@if $pos != "before" and $pos != "after" {
		@error "Invalid $pos `#{$pos}`";
	}

	@if $dir != "left" and $dir != "right" {
		@error "Invalid $dir `#{$dir}`";
	}

	@if $vAlign != "middle" and $vAlign != "top" and $vAlign != "bottom" {
		@error "Invalid $vAlign `#{$vAlign}`";
	}

	$arrowSizeUnitless: strip-unit($arrowSize);
	$arrowSizeDiag: add-unit(math.sqrt($arrowSizeUnitless * $arrowSizeUnitless * 2), unit($arrowSize));

	$boxSizeW: $boxSize;
	$boxSizeH: $boxSize;
	@if type-of($boxSize) == list {
		$boxSizeW: nth($boxSize, 1);
		$boxSizeH: nth($boxSize, 2);
	}

	&::after {
		content: "";
		position: absolute;
		display: block;
		box-sizing: content-box;
		border-top: $weight solid;
		border-left: $weight solid;

		@if $dir == "right" {
			transform: rotate(135deg);
		} @else if $dir == "left" {
			transform: rotate(-45deg);
		}

		@if $vAlign == "middle" {
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
		} @else if $vAlign == "top" {
			top: calc(#{math.div($boxSizeH, 2)} + #{math.div(-$arrowSize, 2) - math.div($arrowSizeDiag - $arrowSize, 2)});
		} @else if $vAlign == "bottom" {
			bottom: calc(#{math.div($boxSizeH, 2)} + #{math.div(-$arrowSize, 2) - math.div($arrowSizeDiag - $arrowSize, 2)});
		}

		width: $arrowSize;
		height: $arrowSize;

		@if $pos == "before" {
			left: calc(#{math.div($boxSizeW, 2)} + #{math.div(-$arrowSize, 2) - math.div($arrowSizeDiag - $arrowSize, 2) - math.div($arrowSizeDiag, 4)});
		} @else if $pos == "after" {
			right: calc(#{math.div($boxSizeW, 2)} + #{math.div(-$arrowSize, 2) - math.div($arrowSizeDiag - $arrowSize, 2) + math.div($arrowSizeDiag, 4)});
		}
	}

	@if $box {
		@include bs-arrow-box-box(
			$boxSize: $boxSize,
			$pos: $pos,
			$vAlign: $vAlign
		);
	}
}


/// adds the actuall box
@mixin bs-arrow-box-box(
	$boxSize,
	$pos: "before",
	$vAlign: "middle"
) {
	@if $pos != "before" and $pos != "after" {
		@error "Invalid $pos `#{$pos}`";
	}

	@if $vAlign != "middle" and $vAlign != "top" and $vAlign != "bottom" {
		@error "Invalid $vAlign `#{$vAlign}`";
	}

	$boxSizeW: $boxSize;
	$boxSizeH: $boxSize;
	@if type-of($boxSize) == list {
		$boxSizeW: nth($boxSize, 1);
		$boxSizeH: nth($boxSize, 2);
	}

	&::before {
		content: "";
		position: absolute;
		display: block;
		box-sizing: content-box;

		@if $vAlign == "middle" {
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
		} @else if $vAlign == "top" {
			top: 0;
		} @else if $vAlign == "bottom" {
			bottom: 0;
		}

		@if $pos == "before" {
			left: 0;
		} @else if $pos == "after" {
			right: 0;
		}

		width: $boxSizeW;
		height: $boxSizeH;
	}
}

@mixin bs-arrow-box-color-bg($color) {
	&::before {
		background-color: $color;
	}
}

@mixin bs-arrow-box-color-fg($color) {
	&::after {
		border-color: $color;
	}
}
