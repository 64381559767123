@import "../variables";
@import "../functions/theme-color";
@import "../mixins/text-medium";

.bs-intro-minisite-fixed {
	position: fixed;
	top: 0;
	margin-left: -20px;
	z-index: $zIndexIntroMinisiteFixed;
	transition-timing-function: $transitionEasingSiteLogo;
	transition-property: transform;
	transform: translateY(-100%);
	height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
	background-color: $colorPageBackground;

	// mobile && sidebar not fixed
	// vw < $siteMaxWidth && vw < $breakpointSidebarFixed
	@media (max-width: $siteMaxWidth - 1) and (max-width: $breakpointSidebarFixed - 1) {
		width: 100vw;
	}

	// desktop && sidebar not fixed
	// $siteMaxWidth <= vw && vw < $breakpointSidebarFixed
	@media (min-width: $siteMaxWidth) and (max-width: $breakpointSidebarFixed - 1) {
		width: $siteMaxWidth;
	}

	// mobile && sidebar fixed
	// vw < $siteMaxWidth && $breakpointSidebarFixed <= vw
	@media (max-width: $siteMaxWidth - 1) and (min-width: $breakpointSidebarFixed) {
		width: calc(100vw - #{$sidebarWidth});
	}

	// max width (desktop)
	// $siteMaxWidth <= vw
	@media (min-width: $siteMaxWidth) {
		// sidebar fixed
		width: $siteMaxWidth - $sidebarWidth;

		.bs-site--full-width & {
			width: $siteMaxWidth;
		}
	}

	&--with-transition {
		transition-duration: $transitionDurationSiteLogo;
	}

	&--visible {
		transform: none;
	}

	&__logo {
		position: relative;
		display: block;
		width: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
		height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
	}

	&__logo-signet {
		position: absolute;
		display: block;
		width: $siteLogoSmallSize;
		height: $siteLogoSmallSize;
		left: $siteLogoSmallBorderWidth;
		top: $siteLogoSmallBorderWidth;
	}

	&__title {
		position: absolute;
		display: block;
		left: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
		right: $siteLogoSmallBorderWidth;
		top: $siteLogoSmallBorderWidth;
		font-size: $fontSizeDefault;
		@include bs-text-medium;

		padding: 0;
		margin: 0;

		p {
			margin: 0;
		}

		@each $themeName, $theme in $themes {
			.bs-site--theme-#{$themeName} & {
				color: bs-theme-color($themeName, headlineColor, #000);
			}
		}
	}
}
