@import "../variables";

.bs-button {
	display: inline-block;
	margin: 0;
	padding: 0;

	border: 0;
	background: transparent;
	color: $colorPrimary;
	cursor: pointer;
	vertical-align: top;

	font-size: $fontSizeSmaller;

	&--icon-only {
		position: relative;

		// Make it clickable despite using svg https://teamtreehouse.com/community/how-do-you-make-a-svg-clickable
		// by overlaying a pseudo element
		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	&__code {
		vertical-align: middle;
		padding: 5px 7px 7px 5px;
		font-size: 20px;

		.bs-icon + & {
			padding-left: 0;
		}
	}
}
