@import "../variables";

.bs-scroll-to-top-button {
	position: fixed;
	right: 3vw;
	bottom: 3vw;

	display: flex;

	width: 5vw;
	min-width: 40px;
	max-width: 70px;
	height: 5vw;
	min-height: 40px;
	max-height: 70px;

	align-items: center;
	justify-content: center;

	background-color: $colorPageBackground;
	border-radius: 50%;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);

	pointer-events: none;
	opacity: 0;

	z-index: $zIndexScrollToTopButton;

	transition-property: opacity;
	transition-duration: $transitionDurationDefault;

	@media print {
		display: none;
	}

	&__icon {
		height: 60%;
		width: 60%;
	}

	&--visible {
		pointer-events: all;
		opacity: 1;
	}

	&:hover &__icon path,
	&:focus &__icon path {
		fill: $colorAction;
	}
}
