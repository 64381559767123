@import "../variables";
@import "../mixins/text-regular";

.bs-language-switch {
	width: 40px;
	height: 40px;
	padding: 5px 7px 7px 7px;

	@include bs-text-regular;
	font-size: 20px;
	text-decoration: none;
}
