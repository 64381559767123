@import "../variables";
@import "../breakpoint";
@import "../functions/theme-color";
@import "../mixins/object-fit";

.bs-intro-minisite {
	$b: &;

	position: relative;
	margin: 0 #{-$siteLogoSmallBorderWidth} $siteLogoSmallBorderWidth #{-$siteLogoSmallBorderWidth};

	&__logo-area {
		position: relative;
		min-height: $siteLogoBigSize + $siteLogoBigBorderWidth * 4;
		max-height: ($siteLogoBigSize + $siteLogoBigBorderWidth * 2) * 3;

		@include breakpoint($breakpointMobile) {
			min-height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 4;
		}
	}

	&__base,
	&__bg-img {
		width: 100%;
		max-height: ($siteLogoBigSize + $siteLogoBigBorderWidth * 2) * 3;
		border: 0;
		@include object-fit(cover, 80% 20%);
		overflow: hidden;
	}

	&__img {
		width: 100%;
	}

	&__logo {
		position: absolute;
		display: block;
		z-index: $zIndexIntroMinisiteLogo;
		width: $siteLogoBigSize + $siteLogoBigBorderWidth * 2;
		height: $siteLogoBigSize + $siteLogoBigBorderWidth * 2;

		@include breakpoint($breakpointMobile) {
			width: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
			height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;
		}
	}

	&__logo-signet,
	&__logo-signet-text {
		position: absolute;
		display: block;
		width: $siteLogoBigSize;
		height: $siteLogoBigSize;
		left: $siteLogoBigBorderWidth;
		top: $siteLogoBigBorderWidth;

		@include breakpoint($breakpointMobile) {
			width: $siteLogoSmallSize;
			height: $siteLogoSmallSize;
			left: $siteLogoSmallBorderWidth;
			top: $siteLogoSmallBorderWidth;
		}
	}

	&__logo-signet-text {
		@include breakpoint($breakpointMobile) {
			display: none;
		}
	}

	&__logo-signet-border1,
	&__logo-signet-border2 {
		position: absolute;
		background-color: $colorPageBackground;
		width: $siteLogoBigSize + $siteLogoBigBorderWidth;
		height: $siteLogoBigSize + $siteLogoBigBorderWidth;

		@include breakpoint($breakpointMobile) {
			width: $siteLogoSmallSize + $siteLogoSmallBorderWidth;
			height: $siteLogoSmallSize + $siteLogoSmallBorderWidth;
		}
	}

	&__logo-signet-border1 {
		left: 0;
		top: 0;
	}

	&__logo-signet-border2 {
		left: $siteLogoBigBorderWidth;
		top: $siteLogoBigBorderWidth;

		@include breakpoint($breakpointMobile) {
			left: $siteLogoSmallBorderWidth;
			top: $siteLogoSmallBorderWidth;
		}
	}

	&__title,
	&__description {
		color: #fff;
	}

	&__title {
		margin: 0;
	}

	&--simple-top {
		#{$b}__logo {
			left: $siteLogoBigBorderWidth;
			top: $siteLogoBigBorderWidth;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallBorderWidth;
				top: $siteLogoSmallBorderWidth;
			}
		}

		#{$b}__content {
			display: none;
		}
	}

	&--simple-bottom {
		#{$b}__logo {
			left: $siteLogoBigBorderWidth;
			bottom: $siteLogoBigBorderWidth;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallBorderWidth;
				bottom: $siteLogoSmallBorderWidth;
			}
		}

		#{$b}__content {
			position: absolute;
			left: $siteLogoBigSize + $siteLogoBigBorderWidth * 4;
			right: $siteLogoBigBorderWidth;
			bottom: $siteLogoBigBorderWidth;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 4;
				right: $siteLogoSmallBorderWidth;
				bottom: $siteLogoSmallBorderWidth;
			}
		}

		#{$b}__title {
			font-size: 2.8rem;
			line-height: 3rem;
			margin-bottom: -0.4rem;

			@include breakpoint($breakpointTabletAndUp) {
				font-size: 4rem;
				line-height: 4.2rem;
				margin-bottom: -0.4rem;
			}

			@include breakpoint($breakpointDesktopAndUp) {
				font-size: 5.8rem;
				line-height: 6rem;
				margin-bottom: -0.6rem;
			}
		}

		#{$b}__description {
			display: none;
			p {
				margin-bottom: 0;
			}
			@include breakpoint($breakpointTabletAndUp) {
				display: block;
			}
		}
	}

	&__gradient {
		bottom: 0;
		position: absolute;
		width: 100%;
		height: 50%;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	}

	// TODO: find better mod names
	&--a,
	&--b {
		#{$b}__content {
			@each $themeName, $theme in $themes {
				.bs-site--theme-#{$themeName} & {
					background-color: bs-theme-color($themeName, primaryColor, #000);
				}
			}
		}

		#{$b}__links {
			position: absolute;
			height: 20px;
			bottom: 5px;
			right: 5px;
		}
	}

	&--a {
		#{$b}__logo-area {
			position: relative;
			min-height: $siteLogoBigSize + $siteLogoBigBorderWidth * 4;
			height: 60vh;
			max-height: ($siteLogoBigSize + $siteLogoBigBorderWidth * 2) * 3;

			@include breakpoint($breakpointMobile) {
				height: 50vh;
				max-height: ($siteLogoBigSize + $siteLogoBigBorderWidth * 2) * 2;
			}
		}

		#{$b}__base,
		#{$b}__bg-img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			border: 0;
			@include object-fit(cover);
		}

		#{$b}__img {
			width: 100%;
		}

		#{$b}__logo {
			left: $siteLogoBigBorderWidth;
			bottom: -$siteLogoBigBorderWidth;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallBorderWidth;
				bottom: -$siteLogoSmallBorderWidth;
			}
		}

		#{$b}__content {
			padding: {
				left: $siteLogoBigBorderWidth * 2;
				right: $siteLogoBigBorderWidth * 2;
				top: $siteLogoBigBorderWidth * 2;
				bottom: $siteLogoBigBorderWidth;
			}

			@include breakpoint($breakpointMobile) {
				padding: {
					left: $siteLogoSmallBorderWidth * 2;
					right: $siteLogoSmallBorderWidth * 2;
					top: $siteLogoSmallBorderWidth * 2;
					bottom: $siteLogoSmallBorderWidth;
				}
			}
		}
	}

	&--b {
		#{$b}__logo {
			left: $siteLogoBigBorderWidth;
			bottom: -$siteLogoBigBorderWidth - $siteLogoBigSize;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallBorderWidth;
				bottom: -$siteLogoSmallBorderWidth - $siteLogoSmallSize;
			}
		}

		#{$b}__content {
			position: relative;

			padding: {
				top: $siteLogoBigSize + $siteLogoBigBorderWidth;
				left: $siteLogoBigSize + $siteLogoBigBorderWidth * 4;
				right: $siteLogoBigBorderWidth;
				bottom: $siteLogoBigBorderWidth * 2;
			}

			@include breakpoint($breakpointMobile) {
				padding: {
					top: $siteLogoSmallSize + $siteLogoSmallBorderWidth;
					left: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 4;
					right: $siteLogoSmallBorderWidth;
					bottom: $siteLogoSmallBorderWidth * 2;
				}
			}
		}

		#{$b}__title {
			position: absolute;
			z-index: $zIndexIntroMinisiteTitle;
			left: $siteLogoBigSize + $siteLogoBigBorderWidth * 4;
			right: $siteLogoBigBorderWidth;
			bottom: -$siteLogoBigSize;

			@include breakpoint($breakpointMobile) {
				left: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 4;
				right: $siteLogoSmallBorderWidth;
				bottom: -$siteLogoSmallSize;
			}
		}
	}
}
