@import "../../variables";
@import "../../functions/theme-color";

@mixin sidebarHeaderMobileVisible {
	display: block;

	> .bs-language-switch,
	> .js-bs-auto-translate {
		display: none;
	}
}

.bs-sidebar-header-mobile {
	position: fixed;
	display: none;
	z-index: $zIndexSidebarHeaderMobile;
	background-color: white;
	top: 0;
	right: 0;
	padding-top: $siteLogoSmallBorderWidth - 7px; // TODO: rm whitespace from buttons
	padding-right: $siteLogoSmallBorderWidth - 7px; // TODO: rm whitespace from buttons

	@each $themeName, $theme in $themes {
		.bs-site--theme-#{$themeName} & {
			.bs-sidebar-home-button {
				fill: bs-theme-color($themeName, "headlineColor", $colorAction);
			}
		}
	}

	@include breakpoint($breakpointSidebarOffCanvas) {
		@include sidebarHeaderMobileVisible;
	}

	@include breakpoint($siteMaxWidth) {
		right: 0.5 * $sitePadding;
		margin-right: calc(50% - #{0.5 * $siteMaxWidth});
	}

	.bs-site--full-width & {
		@include sidebarHeaderMobileVisible;
	}

	@media print {
		.bs-sidebar-home-button {
			fill: black !important;
		}
	}
}
