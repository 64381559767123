@import "../variables";
@import "../mixins/site-theme-property";
@import "../mixins/text-bold";

.bs-minisite-logo-banner {
	display: flex;
	flex-direction: column;

	&__header {
		padding: 2*$gridGap $gridGap;
		flex-grow: 1;
		order: 1;
		text-align: center;
		@include bs-site-theme-property('headlineColor', color);

		h1,
		h2,
		h3,
		h4,
		p {
			margin: 0;
			padding: 0;
		}

		h1, h2, h3, h4 {
			@include bs-text-bold;
		}
	}

	&__logo {
		padding: 10px;
		order: 0;
		background: $colorOffsetSegment;
		text-align: center;

		img {
			width: 200px;
			height: auto;
		}
	}

	@media (min-width: 600px) {
		flex-direction: row;

		&__header {
			background: $colorOffsetSegment;
			padding-left: clamp(20px, 7vw, 120px);
			text-align: left;
		}

		&__logo {
			display: flex;
			align-items: center;
			order: 2;
			text-align: right;
			padding-right: clamp(20px, 7vw, 120px);
		}
	}
}
