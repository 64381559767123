@use "sass:math";

@import "../variables";
@import "../mixins/base-carousel";

$minimumCaptionHeight: 45px;
$carouselRatio: math.div(3, 2);

.bs-image-teaser-carousel {
	@include bs-base-carousel;

	&__item {
		width: calc(25% - 17px);
		min-width: 250px;
		margin-right: 17px;
	}

	// min height
	.flickity-viewport {
		min-height: 225px; // TODO: magic number
	}

	//&:not(:hover) .flickity-prev-next-button:not(:hover):not(:focus) {
	//	opacity: 0;
	//}
}
