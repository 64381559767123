@import "../variables";
@import "../breakpoint";

.bs-content-element {
	$b: &;

	clear: both;
	margin-top: 0;

	* + & {
		margin-top: $gridGap;
	}

	&.bs-text + &.bs-link-list:not(section) {
		&,
		& > .bs-link-list__items:first-child {
			margin-top: 0;
		}
	}

	// this would allow for tables and other fixed width elements to scroll in small devices
	&--overflow-scroll {
		overflow-y: hidden; // to prevent vertical scrollbar
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
	}

	&--intro-image {
		margin-bottom: $gridGap;
	}

	&--highlight {
		padding: $gridGap * 2 $gridGap * 2;
		background: $colorOffsetSegment;

		.bs-site--full-width & {
			padding: 60px $gridGap * 2;
		}

		// image following a grey area should not have a gap
		& + .bs-asset {
			margin-top: 0;
		}

		// multiple gray bg should not have a gap between
		& + & {
			margin-top: 0;
			padding-top: 0;
		}

		@include breakpoint($breakpointSidebarFixed) {
			.bs-site--full-width #{$b}:not(&) + &,
			.bs-site--full-width & + #{$b}:not(.bs-asset):not(&) {
				margin-top: $gridGap * 2;
			}
		}
	}
}
