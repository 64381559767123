.bs-site--theme-BesserSmart {
	.ms3-wrapper--desktop,
	.ms3-wrapper--mobile {
		.ms3-map-row {
			border: 0;
			padding: 1px;
			background: #ea5506;
			background: -moz-linear-gradient(left, #ea5506 0%, #991e66 100%);
			background: -webkit-linear-gradient(left, #ea5506 0%, #991e66 100%);
			background: linear-gradient(to right, #ea5506 0%, #991e66 100%);

			@include breakpoint($ms3-breakpoint-mobile) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.ms3-wrapper--desktop .ms3-feature-selection-info,
	.ms3-wrapper--fullscreen .ms3-feature-selection-info {
		border: 0;
		border-right: 1px solid #ccc;
	}

	.ms3-list__info {
		display: none !important;
	}

	.ms3-list__icon {
		img {
			display: inline-block;
			width: 26px;
			height: 26px;
			box-sizing: border-box;
			padding-left: 26px;
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/marker.png?1);
			margin: 4px 5px 4px 0;
			background-size: 26px 26px;
			vertical-align: middle;

			.ms3-wrapper--mobile & {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		img[src*="e-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/e-ladesaeule.png?1);
		}

		img[src*="w-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/wlan.png?1);
		}


		img[src*="f-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/forschung.png);
		}

		img[src*="h-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/uni.png);
		}

		img[src*="k-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/excellence_center.png);
		}

		img[src*="c-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/coworking.png);
		}

		img[src*="g-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/startup.png);
		}

		img[src*="v-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/networking.png);
		}

		img[src*="o-plain"],
		img[src*="p-plain"],
		img[src*="q-plain"] {
			background-image: url(/WEB-IES/braunschweig-module/current/img/mapsight-icons/besser-smart/glasfaser.png);
		}
	}

	.ms3-wrapper--mobile .ms3-list__icon img {
		margin-top: 0;
		margin-bottom: 0;
	}
}
