@use "sass:math";

@import "../functions/map-get-default";
@import "../mixins/object-fit";

$fixed-sizes: (
	(name: "cover", fit: cover),
	(name: "square", width: 1, height: 1, fit: cover),
	(name: "2x1", width: 2, height: 1, fit: cover),
	(name: "3x2", width: 3, height: 2, fit: cover),
	(name: "3x2-contain", width: 3, height: 2, fit: contain),
	(name: "logo2x1", width: 2, height: 1, fit: contain),
	(name: "logo3x2", width: 3, height: 2, fit: contain),
	(name: "4x3", width: 4, height: 3, fit: cover),
	(name: "16x9", width: 16, height: 9, fit: cover),
	(name: "16x10", width: 16, height: 10, fit: cover),
	(name: "21x9", width: 21, height: 9, fit: cover),
	(name: "180x180", fixed: true, width: 180px, height: 180px, fit: cover),
	(name: "illustration", fixedHeight: 600px, fit: cover),
	(name: "advert", fixed: true, width: 300px, height: 250px, fit: cover),
	(name: "uploadPreview", fixed: true, width: 100px, height: 100px, fit: cover),
	(name: "imageLink", fixedHeight: 150px, fit: cover),
);

.bs-fixed-size {
	$b: &;

	&__raiser {
		display: block;
		height: 0;
		overflow: hidden;
		position: relative;
	}

	// the nested selector allows for __content to be present even if there isn't a __raiser without breaking the layout completely
	&__raiser &__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@each $fixed-size in $fixed-sizes {
		.bs-fixed-size__raiser--#{map-get($fixed-size, name)} {
			@if map-get($fixed-size, fixed) == true {
				width: map-get($fixed-size, width);
				padding-top: map-get($fixed-size, height);
			} @else {
				@if map-has-key($fixed-size, fixedHeight) {
					padding-top: map-get($fixed-size, fixedHeight);
				} @else if map-has-key($fixed-size, height) {
					padding-top: percentage(math.div(map-get($fixed-size, height), map-get($fixed-size, width)));
				}
			}

			#{$b}__content {
				@include object-fit(map-get($fixed-size, fit));
			}
		}
	}
}
