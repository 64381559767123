@import "../variables";
@import "../breakpoint";
@import "../mixins/site-theme-property";

.bs-separator {
	clear: both;
	padding-top: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid;
	border-top: none;
	@include bs-site-theme-property('headlineColor', border-color);
}
