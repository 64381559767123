@import "../variables";
@import "../../mixins/text-medium";
@import "../../mixins/text-link";
@import "../../mixins/text-ellipsis";

$sp-field-size: $fontSizeDefault !default;
$sp-field-color-focus: #DDDD00 !default;
$sp-field-animation-duration: 200ms !default;
$sp-field-signal-border-size: 1px;
$sp-field-checkbox-signal-border-size: 1px;
$sp-field-signal-border-color-default: #d0d0d0 !default;
$sp-field-signal-border-color-disabled: #fff !default;
$sp-field-signal-border-color-focus: #DDDD00 !default;
$sp-field-signal-border-color-invalid: #DD0000 !default;
$sp-field-signal-border-color-valid: #00DD00 !default;
$sp-field-signal-border-animtation-duration: 300ms !default;
$sp-field-background: $colorOffsetSegment !default;
$sp-field-border: none !default;
$sp-field-checkbox-size: 1.3em !default;
$sp-field-checkbox-background: $colorOffsetSegment !default;
$sp-field-checkbox-border: #d0d0d0 !default;
$sp-field-checkbox-border-size: 1px !default;
$sp-field-checkbox-color: #000000 !default;
$sp-field-label-color: #666666 !default;
$sp-field-helper-size: 1em !default;

.SP-Field {
	$b: &;
	position: relative;

	&__control {
		background: $sp-field-background;
		border: $sp-field-border;
		border-radius: 0;
		box-shadow: none;
		box-sizing: content-box;
		font: inherit;
		//outline: none;

		&[disabled] {
			cursor: not-allowed;
		}
	}

	&__label {
		color: $sp-field-label-color;

		a {
			@include bs-text-link;
		}

		.SP-isValid > & {
			color: $sp-field-signal-border-color-valid;
		}

		.SP-isInvalid > & {
			color: $sp-field-signal-border-color-invalid;
		}
	}

	&__error {
		color: $sp-field-signal-border-color-invalid;
	}

	&__search {
		position: relative;
	}

	.bs-mapsight-embed {
		@include breakpoint($breakpointMobile) {
			margin: 0 $gridGap;
		}

		.m3-additional-container {
			display: none;
		}

		.ms3-map-wrapper {
			max-height: 33vh;
		}

		.ms3-wrapper--desktop .ms3-map-wrapper {
			min-height: 300px;
		}

		.ms3-map-overlay__button--desktop-view-toggle,
		.ms3-map-overlay__button--mobile-view-toggle {
			display: none;
		}
	}

	/**
	 * Checkboxen
	 */
	&--checkbox {
		#{$b}__control {
			position: absolute;
		}

		#{$b}__control:checked + #{$b}__label::after {
			transform: scale(1);
		}

		#{$b}__control + #{$b}__label::after {
			transition: all #{$sp-field-animation-duration} ease;
		}

		#{$b}__label {
			display: block;
			min-height: $sp-field-checkbox-size;
			padding-left: 3.25rem;
			position: relative;
			cursor: pointer;

			&::before {
				content: "";

				position: absolute;
				top: 0;
				left: 0;

				display: block;
				height: $sp-field-checkbox-size;
				width: $sp-field-checkbox-size;

				background-color: $sp-field-checkbox-background;
				border: {
					color: $sp-field-signal-border-color-default;
					style: solid;
					width: $sp-field-checkbox-signal-border-size;
				}
				transition: border-color #{$sp-field-signal-border-animtation-duration} ease;
			}

			&::after {
				content: "✓";

				position: absolute;
				top: 0;
				left: 0;

				width: 20px;
				height: 20px;
				margin: 2px;

				font-size: 18px;
				speak: none;
				text-align: center;

				color: $sp-field-checkbox-color;

				transform: scale(0);
			}

			&::before {
			}
		}

		// NOTE(PG): Class .SP-hasFocus does not work, JS is missing!
		&.SP-hasFocus #{$b}__label::before {
			border-color: $sp-field-signal-border-color-focus;
		}

		&.SP-isValid #{$b}__label::before {
			border-color: $sp-field-signal-border-color-valid;
		}

		&.SP-isInvalid #{$b}__label::before {
			border-color: $sp-field-signal-border-color-invalid;
		}

		#{$b}__control[disabled] + #{$b}__label::before,
		#{$b}__control[readonly] + #{$b}__label::before {
			border-color: $sp-field-signal-border-color-disabled;
			border-style: dashed;
		}
	}

	/**
	 * Radio button
	 */
	&--radio {
		list-style: none;

		#{$b}__label {
			padding-left: 1rem;
		}
	}

	/**
	 * "Normal" fields
	 */
	&:not(#{$b}--checkbox):not(#{$b}--radio) {
		display: flex;
		flex-direction: column;

		font-size: $sp-field-size;
		margin: 0.75em 0 0.75em 0;

		#{$b}__label {
			@include bs-text-medium;
			order: -1;
			cursor: default;

			// NOTE(PG): Class .SP-hasFocus does not work, JS is missing!
			#{$b}.SP-hasFocus & {
				color: $sp-field-color-focus;
			}
		}

		#{$b}__control {
			height: 1.6em;
			background-color: $colorOffsetSegment;
			padding: 0.5rem 1rem;
			width: calc(100% - 2rem);
			border-bottom-color: $sp-field-signal-border-color-default;
			border-bottom-style: solid;
			border-bottom-width: $sp-field-signal-border-size;
			transition: border-color #{$sp-field-signal-border-animtation-duration} ease, box-shadow #{$sp-field-signal-border-animtation-duration} ease;

			// NOTE(PG): Class .SP-hasFocus does not work, JS is missing!
			.SP-hasFocus & {
				border-bottom-color: $sp-field-signal-border-color-focus;
				box-shadow: 0 #{$sp-field-signal-border-size} 0 0 #{$sp-field-signal-border-color-focus};
			}

			.SP-isValid & {
				border-bottom-color: $sp-field-signal-border-color-valid;
				box-shadow: 0 #{$sp-field-signal-border-size} 0 0 #{$sp-field-signal-border-color-valid};
			}

			.SP-isInvalid & {
				border-bottom-color: $sp-field-signal-border-color-invalid;
				box-shadow: 0 #{$sp-field-signal-border-size} 0 0 #{$sp-field-signal-border-color-invalid};
			}

			&[disabled],
			&[readonly] {
				border-bottom-color: $sp-field-signal-border-color-disabled;
				border-bottom-style: dashed;
				border-bottom-width: $sp-field-signal-border-size;
			}
		}

		textarea#{$b}__control {
			height: 7em;
		}
	}

	/* Override checkbox/radio controls, because the :not():not() rule above fails for nested fields */
	&--checkbox,
	&--radio {
		#{$b}__control {
			height: auto !important;
			background-color: initial !important;
			padding: 0 !important;
			width: auto !important;
			border: 0 !important;
			transition: none !important;
		}
	}

	/**
	 * Datum
	 */
	&--date &__control {
		margin-bottom: 0 !important; /* stylelint-disable-line */

		&::-webkit-clear-button,
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			height: 2.8125em;
			opacity: 0 !important;
		}

		&::-webkit-clear-button,
		&::-webkit-inner-spin-button {
			display: none;
		}

		.SP-Date {
			margin-bottom: 0.75rem;
		}
	}

	&--inline {
		display: inline-block;
		margin-top: $gridGap;
		margin-right: $gridGap;
	}

	&--radio--inline {
		height: 38px;
		padding: 4px 8px 0 10px;
		border: 1px solid $colorBorderPrimary;

		background: none white;
		color: $colorTextPrimary;

		cursor: pointer;
		transition: background-color $transitionDurationDefault $transitionEasingDefault,
		color $transitionDurationDefault $transitionEasingDefault;

		label {
			cursor: pointer;
		}

		&:hover,
		&:focus {
			background-color: $colorOffsetSegment;
		}
	}

	&--file__preview {
		margin: 10px 0;
	}
}
