@use "sass:math";

@import "../breakpoint";
@import "../variables";
@import "../mixins/text-medium";
@import "../mixins/text-bold";
@import "../functions/theme-color";

@mixin bs-site-logo-adjust-size($b, $is_small, $is_scrolled) {
	$logoSize: null;
	$borderWidth: null;
	@if $is_small {
		$logoSize: $siteLogoSmallSize;
		$borderWidth: $siteLogoSmallBorderWidth;
	} @else {
		$logoSize: $siteLogoBigSize;
		$borderWidth: $siteLogoBigBorderWidth;
	}

	$totalWidth: $logoSize + (2 * $borderWidth);

	$signetScale: math.div($logoSize, $siteLogoBigSize);
	$signetBorderScale: math.div($logoSize + $borderWidth, $siteLogoBigSize + $siteLogoBigBorderWidth);

	#{$b}__signet,
	#{$b}__signet-text {
		transform: translate(#{$borderWidth}, #{$borderWidth}) scale(#{$signetScale});
	}

	#{$b}__signet-text {
		@if $is_small {
			opacity: 0;
		} @else {
			opacity: 1;
		}
	}

	#{$b}__signet-border1 {
		transform: scale(#{$signetBorderScale});
	}

	#{$b}__signet-border2 {
		transform: translate(#{$borderWidth}, #{$borderWidth}) scale(#{$signetBorderScale});
	}

	#{$b}__content-container {
		@if $is_scrolled {
			width: calc(100% - #{$totalWidth});
			transform: translate(#{$totalWidth}, #{$borderWidth});
		} @else {
			width: #{$signetScale * 100px};
			transform: translate(#{$borderWidth + $siteLogoContentPadding}, #{$totalWidth});
		}
	}
}

.bs-site-logo {
	$b: &;

	position: fixed;
	z-index: $zIndexSiteLogo;
	width: 100%;
	height: $siteLogoSmallTotalSize;
	max-width: $siteMaxWidth;

	.bs-site:not(.bs-site--full-width) & {
		max-width: $siteMaxWidth - $sidebarWidth;

		@include breakpoint($breakpointSidebarFixed) {
			width: calc(100% - #{$sidebarWidth});
		}
	}

	&__link {
		display: block;
		width: $siteLogoSmallTotalSize;
		height: $siteLogoSmallTotalSize;
		position: absolute;
	}

	&--with-transition {
		transition-duration: $transitionDurationSiteLogo;
		transition-timing-function: $transitionEasingSiteLogo;
		transition-property: transform;
	}

	transform: none;
	@include bs-site-logo-adjust-size($b, true, true);

	&--big {
		transform: translate(#{$siteLogoBigBorderWidth}, #{$siteLogoBigBorderWidth});
		@include bs-site-logo-adjust-size($b, false, false);
	}

	&--scrolled {
		transform: none;
		@include bs-site-logo-adjust-size($b, true, true);
	}

	// mobile
	@include breakpoint($breakpointMobile) {
		&--big {
			transform: none;
			@include bs-site-logo-adjust-size($b, true, false);
		}

		// mobile + scrolled
		&--scrolled {
			@include bs-site-logo-adjust-size($b, true, true);
		}
	}

	// logo parts (svgs)
	&__signet,
	&__signet-text {
		position: absolute;
		display: block;
		transform-origin: left top;
		width: $siteLogoBigSize;
		height: $siteLogoBigSize;

		#{$b}--with-transition & {
			transition-timing-function: $transitionEasingSiteLogo;
			transition-property: transform, opacity;
			transition-duration: $transitionDurationSiteLogo;
		}
	}

	// white rectangles, part of logo
	&__signet-border1,
	&__signet-border2 {
		position: absolute;
		background-color: $colorPageBackground;
		transform-origin: left top;
		width: $siteLogoBigSize + $siteLogoBigBorderWidth;
		height: $siteLogoBigSize + $siteLogoBigBorderWidth;

		#{$b}--with-transition & {
			transition-timing-function: $transitionEasingSiteLogo;
			transition-property: transform;
			transition-duration: $transitionDurationSiteLogo;
		}
	}

	// bigger white rectangle that contains the content, laying behind it
	&__content-bg {
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: calc(100% - #{$siteLogoSmallBorderWidth});
		height: $siteLogoSmallTotalSize;

		@include breakpoint($breakpointSidebarFixed) {
			width: calc(100% - #{$siteLogoSmallBorderWidth});
		}

		z-index: -1;
		background-color: #fff;

		transform-origin: left top;
		transform: translateX(#{$siteLogoSmallBorderWidth});

		#{$b}--big & {
			width: 100px;
			transform: translateY(-999px);
		}

		#{$b}--with-transition & {
			transition-timing-function: $transitionEasingSiteLogo;
			transition-property: transform, opacity;
			transition-duration: $transitionDurationSiteLogo;
		}
	}

	.js &__content-container {
		opacity: 0.001;
	}

	.js &--inited &__content-container {
		opacity: 1;
	}

	// content container element
	&__content-container {
		display: block;
		min-height: $siteLogoSmallSize;
		padding-bottom: 20px; // breadcrumb space

		position: absolute;

		transition-timing-function: $transitionEasingSiteLogo;
		transition-property: transform;

		white-space: nowrap;
		line-height: 1.15;
		@include bs-text-medium;

		@each $themeName, $theme in $themes {
			.bs-site--theme-#{$themeName} & {
				color: bs-theme-color($themeName, "headlineColor", $colorTextPrimary);
			}
		}

		#{$b}--with-transition & {
			transition-duration: $transitionDurationSiteLogo;
		}

		#{$b}--big:not(#{$b}--scrolled) & {
			@include bs-text-bold;
		}
	}

	// real content. Used by JS to get size of content
	&__content {
		display: inline-block; // must be inline-block to calculate real size in JS (cannot be display:block!)
		color: inherit;
		text-decoration: none;

		p {
			margin: 0;
		}
	}

	.ms3--map-no-scroll &,
	body.fullscreen & {
		display: none;
	}

	@media print {
		position: static;
	}
}

@import "site-logo/breadcrumb";
