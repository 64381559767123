@import "../variables";
@import "../mixins/text-light";
@import "../mixins/text-medium";

.bs-service-navigation {
	list-style: none;
	padding: 0;
	margin: 0;
	color: $colorTextOffsetSegment;

	&__list-item {
		position: relative;
		z-index: 1;
		display: block;
		border-top: 1px solid $colorPageBackground;
		@include bs-text-light;
		font-size: 1.3rem;
		line-height: 1.15;

		strong {
			display: block;
			@include bs-text-medium;
			margin-bottom: 3px;
		}
	}

	&__item {
		position: relative;

		display: block;
		min-height: 40px; // TODO: magic number
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		height: 40px;
		width: 40px;
		margin: 6px 12px;
		fill: $colorPrimary;
	}

	&__link {
		color: inherit;
		display: block;
		padding: 12px 0 12px 58px;
	}
}
