@mixin bs-arrow-pseudo($color) {
	// Arrow buttons
	content: '';
	border-top: 2px solid $color;
	border-left: 2px solid $color;
	box-sizing: content-box;
	display: block;
	width: 4px;
	height: 4px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
}
