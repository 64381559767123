@import "../variables";
@import "../breakpoint";

.bs-content-addon {
	clear: both;
	padding: {
		top: 50px;
		left: $gridGap;
		right: $gridGap;
	}

	& + & {
		padding-top: $gridGap;
	}
}
