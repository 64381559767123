@import "../variables";

.bs-site-footer-contact {
	font-size: $fontSizeSmaller;

	&__header {
		font-size: inherit;
		margin: 0;
		padding-bottom: 7px;
		padding-top: 7px;
	}

	p {
		margin: 0 0 20px 0;
	}
}
