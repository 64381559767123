@import "../variables";

@font-face {
	font-family: $fontNameUbuntu;
	font-display: swap;
	src:
		local("Ubuntu Bold"),
		local("Ubuntu-Bold"),
		url("../fonts/Ubuntu-Bold.woff") format("woff"),
		url("../fonts/Ubuntu-Bold.ttf") format("truetype");
	font-weight: $fontWeightBold;
	font-style: normal;
}

//@font-face {
//	font-family: $fontNameUbuntu;
//	src:
//		local("Ubuntu Bold Italic"),
//		local("Ubuntu-Bold-Italic"),
//		url("../fonts/Ubuntu-BoldItalic.woff") format("woff"),
//		url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype");
//	font-weight: $fontWeightBold;
//	font-style: italic;
//}

@font-face {
	font-family: $fontNameUbuntu;
	font-display: optional;
	src:
		local("Ubuntu Italic"),
		local("Ubuntu-Italic"),
		url("../fonts/Ubuntu-Italic.woff") format("woff"),
		url("../fonts/Ubuntu-Italic.ttf") format("truetype");
	font-weight: $fontWeightRegular;
	font-style: italic;
}

@font-face {
	font-family: $fontNameUbuntu;
	font-display: swap;
	src:
		local("Ubuntu Light"),
		local("Ubuntu-Light"),
		url("../fonts/Ubuntu-Light.woff") format("woff"),
		url("../fonts/Ubuntu-Light.ttf") format("truetype");
	font-weight: $fontWeightLight;
	font-style: normal;
}

//@font-face {
//	font-family: $fontNameUbuntu;
//	src:
//		local("Ubuntu Light Italic"),
//		local("Ubuntu-Light-Italic"),
//		url("../fonts/Ubuntu-LightItalic.woff") format("woff"),
//		url("../fonts/Ubuntu-LightItalic.ttf") format("truetype");
//	font-weight: $fontWeightLight;
//	font-style: italic;
//}

@font-face {
	font-family: $fontNameUbuntu;
	font-display: swap;
	src:
		local("Ubuntu Medium"),
		local("Ubuntu-Medium"),
		url("../fonts/Ubuntu-Medium.woff") format("woff"),
		url("../fonts/Ubuntu-Medium.ttf") format("truetype");
	font-weight: $fontWeightMedium;
	font-style: normal;
}

@font-face {
	font-family: $fontNameUbuntu;
	font-display: optional;
	src:
		local("Ubuntu Medium Italic"),
		local("Ubuntu-Medium-Italic"),
		url("../fonts/Ubuntu-MediumItalic.woff") format("woff"),
		url("../fonts/Ubuntu-MediumItalic.ttf") format("truetype");
	font-weight: $fontWeightMedium;
	font-style: italic;
}

@font-face {
	font-family: $fontNameUbuntu;
	font-display: swap;
	src:
		local("Ubuntu"),
		local("Ubuntu Regular"),
		local("Ubuntu-Regular"),
		url("../fonts/Ubuntu-Regular.woff") format("woff"),
		url("../fonts/Ubuntu-Regular.ttf") format("truetype");
	font-weight: $fontWeightRegular;
	font-style: normal;
}
