@import "../variables";
@import "../mixins/text-light";

html {
	height: 100%;
	@include bs-text-light;
	font-size: $rootFontSizeDefault;
	line-height: $lineHeightDefault;
	color: $colorTextPrimary;
}

body {
	overflow-y: scroll; // always show vertical scroll bar to prevent flickering
	font-size: $fontSizeDefault;
	background-color: $colorPageBackground;
}
