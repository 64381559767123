.bs-audio {
	width: 100%;

	&--placeholder {
		height: 40px;
	}

	&--with-playlist {
		height: auto;
		min-height: 300px !important;
	}
}
