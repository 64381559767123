@import "../variables";
@import "../mixins/base-carousel";

$minimumHeight: 410px;

.bs-intro-image {
	min-height: $minimumHeight;
	overflow: hidden;

	.bs-intro-minisite__img {
		width: 100%;
		min-height: $minimumHeight;
		object-fit: cover;
	}
}

.bs-content-element--intro-image .bs-site-logo {
	margin-left: -$gridGap;
}
