@import "../variables";

$sp-field-size: $fontSizeDefault;
$sp-field-color-focus: #DDDD00;
$sp-field-animation-duration: $transitionDurationFast;
$sp-field-signal-border-size: 1px;
$sp-field-checkbox-signal-border-size: 1px;
$sp-field-signal-border-color-default: #d0d0d0;
$sp-field-signal-border-color-disabled: #fff;
$sp-field-signal-border-color-focus: #DDDD00;
$sp-field-signal-border-color-invalid: #DD0000;
$sp-field-signal-border-color-valid: #00DD00;
$sp-field-signal-border-animtation-duration: 300ms;
$sp-field-background: $colorOffsetSegment;
$sp-field-border: none;
$sp-field-checkbox-size: 1.3em;
$sp-field-checkbox-background: $colorOffsetSegment;
$sp-field-checkbox-border: #d0d0d0;
$sp-field-checkbox-border-size: 1px;
$sp-field-checkbox-color: #000000;
$sp-field-label-color: $colorTextPrimary;
$sp-field-helper-size: 1em;
