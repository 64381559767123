@import "../variables";
@import "../breakpoint";

.bs-portal-links {
	display: flex;
	margin: 1px -19px 20px -19px;
	padding: 5px;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	background: $colorOffsetSegment;

	.bs-site-footer & {
		margin-bottom: 1px;
	}

	@media (min-width: 1200px) {
		gap: 5%;
	}
}
