@import "../variables";
@import "../breakpoint";
@import "../mixins/text-bold";
@import "../mixins/text-medium";
@import "../mixins/site-theme-property";
@import "../mixins/arrow-box";

.bs-image-link {
	$b: &;

	display: block;
	width: 100%;
	position: relative;
	min-height: 150px;

	font-size: $fontSizeSmaller;
	color: $colorTextPrimary;
	text-decoration: none;

	&__image {
		margin: 0;

		img {
			background: white; // background for transparent images (gif/png)
		}
	}

	&__headline {
		font-size: $fontSizeSmaller;
		padding-right: 15px;
		position: relative;
		@include bs-text-medium;

		#{$b}--linked & {
			@include bs-arrow-box(
				$boxSize: (10px, $fontSizeSmaller * $lineHeightDefault),
				$arrowSize: 7px,
				$weight: 3px,
				$pos: "after",
				$vAlign: "top",
				$box: false
			);

			@each $themeName, $theme in $themes {
				.bs-site--theme-#{$themeName} & {
					color: bs-theme-color($themeName, headlineColor, $colorTextPrimary);
				}
			}
		}
	}

	&__text {
		width: 100%;
		padding: 10px 15px;
		background-color: $colorPageBackground;
	}

	&--bordered {
		border: 1px solid $colorBorderPrimary;
	}
}
