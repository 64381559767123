@import "../../variables";

.bs-sidebar-panels {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
	background: inherit;
	border-color: inherit;
	box-sizing: border-box;
	margin: 0;
	overflow: hidden;
}
