@import "../variables";

.SP-Date {
	position: relative;
}

.SP-Date__clear,
.SP-Date__dropdown {
	cursor: pointer;
	fill: $sp-field-label-color;
	height: $sp-field-helper-size;
	line-height: $sp-field-helper-size;
	position: absolute;
	right: 2px;
	top: 50%;
	width: $sp-field-helper-size;
	z-index: 2;
}

.SP-Date__dropdown {
	pointer-events: none;
}

.SP-Date__clear {
	opacity: 0;
	right: calc(#{$sp-field-helper-size} + 0.25rem);
}

.SP-Date__clear:hover,
.SP-Field__control:hover + .SP-Date__clear,
.SP-Field__control:focus + .SP-Date__clear {
	opacity: 1;
}
