@import "../variables";
@import "../mixins/headlines";
@import "../mixins/text-link";

.bs-app__meinewahl {
	a {
		@include bs-text-link;
	}

	b,
	strong {
		@include bs-text-medium;
	}
}

.bs-meinewahl-searchform-input {
	width: calc(66% - #{(1px + 6px) * 2 + 7px});
	height: 25px;
	border: 1px solid $colorBorderPrimary;
	border-radius: 5px;
	padding: 2px 6px;
	margin-right: 7px;
	box-sizing: content-box;
}

.bs-meinewahl-searchform-reset {
	width: calc(34% - #{(1px + 6px) * 2});
	height: 25px;
	border: 1px solid $colorBorderPrimary;
	padding: 2px 6px;
	border-radius: 5px;
	box-sizing: content-box;
	background-color: #fff;
}

.bs-meinewahl-section-title {
	// @include bs-headline-paragraph;
	@include bs-headline-section;
}

.bs-meinewahl-result {
	&--all,
	&--search {
		max-height: 300px;
		overflow: auto;
		overflow-scrolling: touch;
	}

	&--search {
		min-height: 100px;
	}

	&__list {
		list-style: none;
		padding-left: 0;
	}

	&__item {
		font-size: $fontSizeDefault;
		padding: 5px 0;
		border-bottom: 1px solid $colorBorderPrimary;
	}

	&__link {
		text-decoration: none;
		color: inherit;

		&:hover,
		&:focus {
			color: $colorPrimary;
		}
	}

	&__nr {
		display: inline-block;
		width: 100px;
	}

	&__name {
		@include bs-text-medium;
	}

	&__strasse {
		color: #89969e;
		padding-left: 100px;
		font-size: 0.8em;
	}
}

.bs-meinewahl-title {
	font-size: 1.2em;
	margin: 0 0 16px 0;

	&__nr {
		font-weight: normal;
		display: inline-block;
		width: 100px;
	}

	&__name {
		@include bs-text-medium;
	}
}

.bs-meinewahl-subtitle {
	@include bs-headline-all;
	@include bs-headline-color;
	@include bs-text-light;
	padding: 2rem 1rem 1rem 1rem;
	margin: 0;
	line-height: $lineHeightHeading;
	font-size: 2.4rem;
}

.bs-meinewahl-wahllokal {
	padding: 13px;

	&__address {
	}

	&__accessibility {
		padding-left: calc(23px + 1rem);
		background: no-repeat top left;

		&--1 {
			background-image: url(../img/meinewahl/barrierefrei_1.png);
		}

		&--2 {
			background-image: url(../img/meinewahl/barrierefrei_2.png);
		}

		&--3 {
			background-image: url(../img/meinewahl/barrierefrei_3.png);
		}
	}
}

.bs-meinewahl-map {
	.ms3-map-wrapper {
		height: 240px;
		min-height: 0 !important;
	}

	.ms3-map-overlay__button--layers,
	.ms3-map-overlay__button--desktop-view-toggle,
	.ms3-map-overlay__button--mobile-view-toggle,
	.ms3-search-overlay,
	.m3-additional-container {
		display: none !important;
	}
}

.bs-meinewahl-details {
	padding: 13px;

	&__subheadline {
		@include bs-text-medium;
		font-size: $fontSizeDefault;
		padding-top: 1rem;
	}

	&__text {
		font-size: $fontSizeDefault;

		p, ul, div, span {
			font-size: $fontSizeDefault;
		}
	}

	&__table {
		width: 100%;
		max-width: 760px;
		margin-bottom: 1.5rem;
	}

	&__th {
		padding-left: 0;
		text-align: left;
		color: #b0bdc5;

		&:not(:first-child) {
			padding-left: 0.5rem;
		}
	}

	&__td {
		padding: 0.5rem 0;

		&:not(:first-child) {
			padding-left: 0.5rem;
		}

		tr:not(:last-child) & {
			border-bottom: 1px solid $colorBorderPrimary;
		}
	}

	&__von {
		display: inline-block;
		min-width: 2rem;
		text-align: right;
	}

	&__bis {
		display: inline-block;
		min-width: 2rem;
		text-align: left;

		&:before {
			content: "-";
			padding: 0 0.3rem
		}
	}
}
