@use "sass:math";

@import "../variables";

$bsLogoBaseSize: 64;
$bsLogoWidthRatio: math.div(212, $bsLogoBaseSize);
$bsLogoClaimWidthRatio: math.div(124, $bsLogoBaseSize);
$bsLogoClaimHeightRatio: math.div(15, $bsLogoBaseSize);
$bsLogoClaimOffsetTopRatio: math.div(12, $bsLogoBaseSize);
$bsLogoClaimOffsetLeftRatio: math.div(15, $bsLogoBaseSize);

@mixin bs-logo($size: 64px, $includeClaim: true) {
	height: $size;

	@if $includeClaim {
		width: round($size * $bsLogoWidthRatio) + round($size * $bsLogoClaimOffsetLeftRatio) + round($size * $bsLogoClaimWidthRatio);
	} @else {
		width: round($size * $bsLogoWidthRatio);
	}
}

@mixin bs-logo-signet($size: 64px) {
	width: round($size * $bsLogoWidthRatio);
	height: round($size);
}

@mixin bs-logo-claim($size: 64px) {
	width: round($size * $bsLogoClaimWidthRatio);
	height: round($size * $bsLogoClaimHeightRatio);

	transform: translate(
		#{round($size * $bsLogoWidthRatio + $size * $bsLogoClaimOffsetLeftRatio)},
		#{round($size * $bsLogoClaimOffsetTopRatio)}
	);
}
