@import "../variables";
@import "../mixins/text-bold";
@import "../mixins/site-theme-property";

.bs-link-button {
	display: inline-block;
	padding: 10px 20px;

	color: white;
	text-decoration: none;
	@include bs-text-bold;
	@include bs-site-theme-property('secondaryColor', background-color);
}
