@import "../variables";
@import "../breakpoint";

.bs-site-footer {
	$b: &;

	clear: both;
	margin-top: 85px;
	padding-left: $gridGap;
	padding-right: $gridGap;

	&__element {
		padding-bottom: $gridGap;
	}

	&__segment {
		position: relative;
		display: block;
		padding: 10px;

		&--portal-links {
			padding: 0 19px;
		}

		&--links {
			display: flex;
			padding: {
				left: $siteLogoSmallBorderWidth * 2;
				right: 0;
				top: 52px;
				bottom: 66px;
			}

			background: $colorOffsetSegment;
			flex-direction: row;

			#{$b}__grid-item {
				padding: 0;
				flex-shrink: 1;
				flex-grow: 1;
				min-width: 0;
				min-height: 0;
			}
		}

		&--copyright {
			text-align: right;
			background: $colorOffsetSegmentSecondary;
		}
	}

	&__logo {
		position: absolute;
		top: -21px;
		left: 30px;
	}

	@include breakpoint($breakpointTabletAndDown) {
		&__segment--links {
			flex-direction: column;
			padding: {
				left: 30px;
				right: 20px;
				top: 42px;
				bottom: 33px;
			}

			#{$b}__grid-item:not(:first-child) {
				margin-top: 35px;
			}
		}
	}

	@include breakpoint($breakpointDesktopAndUp) {
		&__segment--links {
			#{$b}__grid-item:not(:first-child) {
				padding-left: 5px;
			}
		}
	}

	@media print {
		display: none;
	}
}
