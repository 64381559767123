@import "../variables";
@import "../breakpoint";

.bs-portal-link {
	display: flex;
	flex-direction: row;
	margin: 4px;
	padding: 3px 6px 4px 4px;
	gap: 8px;
	color: inherit;
	text-decoration: none;
	border-radius: 7px;

	&:hover,
	&:focus {
		background: #d2d3d2;
		color: #000;
	}

	&__icon {
		width: 32px;
		height: 32px;
	}

	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		line-height: 1.3;
		font-size: 1.2rem;
		font-weight: 500;

		strong {
			font-weight: 600;
		}
	}

	@media (min-width: 1200px) {
		margin: 5px;
		padding: 5px 10px 7px 8px;
		gap: 10px;

		&__text {
			font-size: 1.4rem;

			strong {
				font-size: 1.6rem;
			}
		}

		&__icon {
			width: 42px;
			height: 42px;
		}
	}
}
