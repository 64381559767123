@import "../variables";
@import "../../mixins/text-link";

.SP-Suggest {
	position: absolute;
	z-index: 2;

	width: 100%;
	margin: 0;
	padding: 0;

	list-style: none;

	background: $colorOffsetSegment;
	border-bottom: 1px solid $colorTextPrimary;

	&__item {
		display: block;
	}

	&__link {
		@include bs-text-link;
		text-decoration: none;
		display: flex;
		align-items: center;
		padding: 5px 10px;
		color: inherit;
	}

	&__link:hover,
	&__link:focus,
	&__item.active .SP-Suggest__link {
		background: darken($colorOffsetSegment, 10%);
		color: black;
	}

	&__query {
		flex-grow: 1;
	}

	&__amount {
		width: 40px;
		margin: 5px;
		background: inherit;
		border: 1px solid $colorBorderPrimary;
		border-radius: 10px;
		text-align: center;
		font-size: $fontSizeSmallest;
	}

	&__wrapper {

	}
}
