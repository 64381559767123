@import "../variables";

.bs-image-links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: -$gridGap;
	margin-left: -$gridGap;

	&__item {
		display: flex;
		width: 100%;
		padding-top: $gridGap;
		padding-left: $gridGap;
		flex-grow: 0;

		justify-items: stretch;

		@include breakpoint($breakpointGridSmall) {
			width: 50%;
		}

		@include breakpoint($breakpointGridMid) {
			&--1\/4 {
				width: 25%;
			}

			&--1\/3 {
				width: 33.33%;
			}
		}
	}
}
