@import "../variables";
@import "../breakpoint";
@import "../mixins/text-bold";

.bs-asset {
	$b: &;

	display: block;
	margin: 0 0 $gridGap;
	$assetMargin: 18px;

	img {
		border: 1px solid $colorBorderPrimary;
	}

	&__caption {
		padding: 10px 15px;
		font-size: 1.5rem;
		color: $colorTextSecondary;

		&--primary {
			color: $colorTextPrimary;
		}

		display: flex;
		flex-direction: column;

		a {
			color: inherit;
			@include bs-text-bold;
		}

		.bs-carousel__item & {
			margin-bottom: 0;
		}

		&__copyright {
			&::before {
				content: "(";
			}

			&::after {
				content: ")"
			}
		}

		&__text {
			margin-right: 5px;
		}

		&--small {
			font-size: $fontSizeSmallest;
			padding-top: 2px;
			padding-left: 0;
			padding-right: 0;
		}

		&--small &__copyright {
			&::before,
			&::after {
				content: "";
			}
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		&--align-left,
		&--align-right {
			width: 33%;
			margin-top: 0;

			* + &.bs-content-element {
				margin-top: 0;
			}

			.bs-content-element + &.bs-content-element {
				margin-top: 38px;

				& + .bs-text {
					> .bs-paragraph:first-child {
						//margin-top: 1em;
					}

					.bs-text ~ & {
						> .bs-paragraph:first-child {
							margin-top: 0;
						}
					}
				}
			}

			& + .bs-text {
				clear: none;
				width: 67%;
				padding-top: 1px; // keep content margin inside box
				margin-top: 0;

				> .bs-headline--paragraph:first-child {
					margin-top: 0;
				}

				.bs-content-element ~ & {
					margin-top: 36px;
				}

				& + .bs-link-list,
				& + .bs-link-list + .bs-link-list,
				& + .bs-link-list + .bs-link-list + .bs-link-list,
				& + .bs-link-list + .bs-link-list + .bs-link-list + .bs-link-list {
					min-width: 67%;
					padding-top: 1px; // keep content margin inside box
					clear: none;
					// TODO: Understand what is going on, document!
					float: left; // hotfix weird floating bug (https://neonaut-intern.net/rt/Ticket/Display.html?id=2151)
				}
			}
		}

		&--align-left {
			float: left;
			margin-top: $gridGap;
			padding-right: $assetMargin;

			& + .bs-text {
				float: right;

				//& + .bs-link-list,
				//& + .bs-link-list + .bs-link-list,
				//& + .bs-link-list + .bs-link-list + .bs-link-list,
				//& + .bs-link-list + .bs-link-list + .bs-link-list + .bs-link-list {
				//	float: right;
				//}
			}
		}

		&--align-right {
			float: right;
			padding-left: $assetMargin;

			& + .bs-text {
				float: left;

				//& + .bs-link-list,
				//& + .bs-link-list + .bs-link-list,
				//& + .bs-link-list + .bs-link-list + .bs-link-list,
				//& + .bs-link-list + .bs-link-list + .bs-link-list + .bs-link-list {
				//	float: left;
				//}
			}
		}

		&--full &__caption,
		&--oversized &__caption {
			flex-direction: row;

			&__copyright {
				flex-grow: 1;
				flex-shrink: 0;
				max-width: 100%;
			}

			&__text + &__copyright {
				max-width: 70%;
			}

			&__text {
				flex-grow: 0;
				flex-shrink: 1;
			}
		}
	}

	// ugly undo of the above for full sized assets inside
	// mapsight feature details, using breakpoint 1px for specificity
	@include breakpoint(1px) {
		&--full &__caption,
		&--oversized &__caption {
			.ms3-feature-details-content & {
				flex-direction: column;

				&__copyright,
				&__text {
					width: 100%;
					max-width: 100%;

					flex-grow: 1;
					flex-shrink: 1;
				}

				&__copyright + #{$b}__caption__text {
					margin-right: 0;
				}
			}

			// limit to one line per element (overflow ellipsis), but show on hover/focus
			.ms3-feature-details-content .bs-carousel & {
				&__copyright,
				&__text {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					// NOTE: you can not focus them actually, would require tabindex on those elements
					//       keeping it here though as a reminder
					&:hover,
					&:focus {
						overflow: visible;
						background: $colorPageBackground;
						white-space: normal;
					}
				}
			}
		}
	}
}
