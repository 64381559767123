@import "../variables";
@import "../mixins/text-bold";
@import "../mixins/text-medium";

@mixin bs-text-link {
	@include bs-text-medium;
	color: inherit;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $colorAction;
	}
}
