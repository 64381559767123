@import "../mixins/text-bold";
@import "../mixins/text-medium";

@mixin bs-button {
	@include bs-text-bold;
	cursor: pointer;
	border-radius: 0;
	border: none;
	padding: {
		top: 7px;
		bottom: 7px;
		left: 15px;
		right: 15px;
	}
	color: $colorPageBackground;
	text-decoration: none;

	@each $themeName, $theme in $themes {
		.bs-site--theme-#{$themeName} & {
			$bg-color: bs-theme-color($themeName, headlineColor, $colorPrimary);
			background-color: $bg-color;
			border-radius: 2px;

			&:hover,
			&:focus {
				background-color: darken($bg-color, 5%);
			}
		}
	}
}
