@import "variables";

.bs-allris-scroll-container {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	margin: 0 -20px;

	.allris_container {
		min-width: 600px;
		padding: 0 20px;
	}
}

.allris_container {
	font-size: $fontSizeSmaller;

	h1,
	strong,
	b,
	th,
	.kb1,
	.kb2 {
		font-weight: $fontWeightMedium;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}
