@import "../variables";
@import "../breakpoint";
@import "../mixins/arrow-pseudo";
@import "../mixins/site-theme-property";

.bs-anchor-list {
	clear: both;
	max-width: $contentMaxWidth;
	margin: 40px 0 40px 0;
	padding: 0;
	list-style: none;

	ul, ol {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		border-bottom: 1px solid $colorBorderSecondary;

		a {
			@include bs-site-theme-property('headlineColor', color);

			display: block;
			padding-right: 45px;
			padding-top: 1px;
			padding-bottom: 1px;

			position: relative;

			font-family: inherit;
			font-weight: inherit;
			text-decoration: none;

			// arrow
			&::after {
				@include bs-arrow-pseudo($colorSecondary);
				@include bs-site-theme-property('headlineColor', border-top-color);
				@include bs-site-theme-property('headlineColor', border-left-color);

				position: absolute;
				top: 0;
				right: 0;

				margin: 10px 15px 0 0;
				transform: rotate(135deg);
			}

			@include breakpoint($breakpointTabletAndUp) {
				padding-right: 30px;

				// arrow
				&::after {
					margin-right: 0;
				}
			}
		}
	}

	.bs-site--app & {
		display: none;
	}
}
