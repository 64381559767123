@import "../variables";
@import "../breakpoint";
@import "../mixins/text-bold";
@import "../mixins/object-fit";

.bs-intro-item {
	overflow: hidden;

	&__title {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;

		padding: {
			left: 60px;
			right: 60px;
			top: 15px;
			bottom: 15px;
		}

		color: white;
		background: rgba(0, 0, 0, 0.2);
		text-shadow: 0 0 10px black;

		@include bs-text-bold;
		text-align: center;
		line-height: $lineHeightHeading;

		hyphens: auto;
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: grey;

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			border: 0;
			@include object-fit(cover);
		}
	}
}
