@import "../variables";
@import "../mixins/text-link";
@import "../mixins/headlines";

$classicTeaserImageWidthMobile: 160px;
$classicTeaserImageWidthTablet: 200px;
$classicTeaserImageWidthDesktop: 248px;
$classicTeaserImageWidthMobileStandalone: 300px;
$iconSize: 40px;
$smallGap: 10px;

.bs-classic-teaser {
	$b: &;

	display: block;
	padding: $gridGap 0;
	color: inherit;
	text-decoration: none;

	&:not(:first-child) {
		border-top: 1px solid $colorBorderSecondary;
	}

	&__inner {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__kicker {
		display: none;
	}

	[data-sp-site-search-controller] &__kicker {
		display: block;
		color: $colorTextSecondary;
		font-size: $fontSizeSmaller;
		margin-bottom: 0.75rem;
	}

	&__kicker-icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: $colorTextSecondary;
	}

	&__headline {
		@include bs-headline-paragraph(false);

		position: relative;

		margin-top: 0;
		margin-bottom: $smallGap;
		padding-right: $smallGap + $iconSize;

		word-break: break-word;
		hyphens: auto;

		&__icon {
			position: absolute;
			top: 0;
			right: 0;
			width: $iconSize;
			height: $iconSize;
		}
	}

	&__abstract {
		max-width: 900px;

		b, strong {
			@include bs-text-medium;
		}
	}

	&__image {
		width: $classicTeaserImageWidthMobile;
		transition: width $transitionDurationDefault $transitionEasingDefault,
		height $transitionDurationDefault $transitionEasingDefault;

		margin-bottom: 1em;
		@include breakpoint($breakpointTabletAndUp) {
			margin-bottom: 0;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&__text {
		width: 100%;
		flex-grow: 1;
	}

	&--standalone {
		border: {
			top: 1px solid $colorBorderSecondary;
			bottom: 1px solid $colorBorderSecondary;
		}

		#{$b}__headline {
			@include bs-headline-color;

			&__icon {
				@include bs-site-theme-property('headlineColor', fill);
			}
		}

		#{$b}__image {
			width: $classicTeaserImageWidthMobileStandalone;
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		&__inner {
			display: flex;
			flex-direction: row;
		}

		&__image {
			margin-right: $gridGap;
			flex-shrink: 0;
			width: $classicTeaserImageWidthTablet;
		}

		&--textual &__text {
			margin-left: #{$classicTeaserImageWidthTablet + $gridGap};
		}
	}

	@include breakpoint($breakpointDesktopAndUp) {
		&__image {
			width: $classicTeaserImageWidthDesktop;
		}

		&--textual &__text {
			margin-left: #{$classicTeaserImageWidthDesktop + $gridGap};
		}
	}
}
