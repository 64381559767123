@import "../variables";

.js-focus-visible :focus:not(.focus-visible),
.js-focus-visible :focus-visible:not(.focus-visible) {
	outline: none;

	@if ($debug) {
		outline: 2px solid darkmagenta;
		background: pink !important;
	}
}

@mixin ring {
	box-shadow: $colorPageBackground 0 0 0 3px,
	$focusRingColor 0 0 0 6px;
}

@mixin ringBlack {
	box-shadow: black 0 0 0 3px,
	$focusRingColor 0 0 0 6px;
}

@mixin ringTight {
	box-shadow: $focusRingColor 0 0 0 3px;
}

@mixin ringInset {
	box-shadow: inset $focusRingColor 0 0 0 3px;
}

@mixin ringInsetAfter {
	box-shadow: none;

	&::after {
		content: "";
		position: absolute;
		inset: 0;
		z-index: $zIndexFocus;
		box-shadow: inset $focusRingColor 0 0 0 3px;
		pointer-events: none;
		user-select: none;
	}
}

@mixin focusVisible($b: &) {
	html:not(.js-focus-visible) #{$b}:focus,
	.js-focus-visible #{$b}.focus-visible:focus,
	#{$b}:focus-visible {
		@content;
	}
}

@include focusVisible {
	outline: none;
	@include ring;
}

@include focusVisible('.bs-site-main') {
	@include ringInsetAfter;
}

@include focusVisible('.bs-sidebar-list-item__link') {
	@include ringInsetAfter;
}

@include focusVisible('.bs-intro-minisite__logo') {
	@include ringInsetAfter;
}

@include focusVisible('.bs-site-logo__link') {
	@include ringInsetAfter;
}

@include focusVisible('.ms3-feature-selection-info__wrapper') {
	@include ringInsetAfter;
}

@include focusVisible('.bs-service-navigation__link') {
	@include ringInset;
}

@include focusVisible('.bs-site-logo__breadcrumb-list a') {
	@include ringInset;
}

@include focusVisible('.bs-intro-stage') {
	@include ringInsetAfter;
}

@include focusVisible('.bs-intro-item__link') {
	.bs-intro-item__focus-anchor {
		@include ringBlack;
		background: black;
	}
}

@include focusVisible('.ms3-map-overlay__button--zoom') {
	position: relative;
	z-index: 2;
}

@include focusVisible('.bs-carousel__link') {
	@include ringInsetAfter;
}

@include focusVisible('input:not([type="checkbox"]):not([type="radio"])') {
	@include ringTight;
}

@include focusVisible('select') {
	@include ringTight;
}

@include focusVisible('button') {
	@include ringTight;
}

@include focusVisible('textarea') {
	@include ringTight;
}
