@import "../variables";

.bs-quote-of-the-day {
	max-width: 500px;

	&__text {

	}

	&__source {
		font-style: italic;
	}
}
