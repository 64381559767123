@use "sass:math";

@import "../variables";
@import "../breakpoint";

.bs-sidebar-wrapper {
	overflow-x: hidden;
	position: relative;

	&--is-active {
		position: relative;
	}
}

// this is a .bs-button--icon-only
.bs-sidebar-search-toggle {
	z-index: $zIndexSidebarSearchToggle;
	border: 1px solid $colorPageBackground;
	background: $colorPageBackground;

	span {
		display: none;
	}

	.bs-sidebar &:not(.js-bs-sidebar-search-toggle--active) {
		height: 35px;
		margin: {
			top: 3px;
			left: 10px;
		}

		border: 1px solid $colorBorderPrimary;
		border-radius: 34px;

		background: transparent;
		color: $colorTextPrimary;

		vertical-align: top;
		text-align: left;

		span {
			display: inline-block !important;
			padding: 0 10px;
			line-height: 28px;
		}

		.bs-icon {
			margin-top: -4px;
		}
	}
}

.bs-sidebar-home-button {
	width: 20px;
	height: 20px;
}

@import "sidebar/headline";
@import "sidebar/header-mobile";
@import "sidebar/header";
@import "sidebar/list-wrapper";
@import "sidebar/list";
@import "sidebar/list-item";
@import "sidebar/page-blocker";
@import "sidebar/panel";
@import "sidebar/panel-bar";
@import "sidebar/panels";
@import "sidebar/search";
@import "sidebar/text";

.bs-sidebar {
	width: $sidebarWidth;
	padding: 0;
	margin: 0;

	position: fixed;
	left: auto;
	right: $sitePadding;
	top: 0;
	bottom: 0;

	display: none;

	z-index: $zIndexSidebar;

	transition:
		opacity $transitionDurationDefault $transitionEasingDefault,
		transform $transitionDurationDefault $transitionEasingSidebar;

	box-sizing: border-box;
	background: $colorPageBackground;
	color: $colorTextPrimary;
	border-color: $colorPageBackground;

	@include bs-text-regular;
	font-size: $fontSizeSmaller;

	a,
	a:link,
	a:active,
	a:visited,
	a:hover {
		text-decoration: none;
	}

	&--opened {
		display: block;
	}

	@include breakpoint($siteMaxWidth) {
		right: 0.5 * $sitePadding;
		margin-right: calc(50% - #{0.5 * $siteMaxWidth});
	}

	@include breakpoint($breakpointSidebarOffCanvas) {
		transform: translate(calc(100% + #{$sitePadding}), 0);

		.bs-sidebar-wrapper--is-opened & {
			transform: translate(0, 0);
		}
	}

	.bs-site--full-width & {
		transform: translate(10%, 0);
		opacity: 0;

		.bs-sidebar-wrapper--is-opened & {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	@include breakpoint($breakpointSidebarFixed) {
		.bs-site:not(.bs-site--full-width) & {
			display: block;
			width: $sidebarWidth;
		}
	}

	.ms3--map-no-scroll & {
		display: none !important;
	}
}

.bs-sidebar-toggle {
	@include breakpoint($breakpointSidebarFixed) {
		.bs-site:not(.bs-site--full-width) & {
			display: none;
		}
	}
}

.bs-sidebar-search-toggle:not(.js-bs-sidebar-search-toggle--active) .bs-sidebar-search-toggle__content--active,
.js-bs-sidebar-search-toggle--active .bs-sidebar-search-toggle__content--inactive,
.bs-sidebar-toggle:not(.js-bs-sidebar-toggle--active) .bs-sidebar-toggle__content--active,
.js-bs-sidebar-toggle--active .bs-sidebar-toggle__content--inactive {
	display: none;
}
