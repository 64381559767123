@import "../variables";

.bs-site--braunschweig-directory-careFacility-manager {
	.SP-Field--checkboxTree span + .SP-Field--checkbox {
		display: none;
	}
}

.bs-site--braunschweig-directory-educationalGuidepost-manager {
	.SP-Upload .SP-Field.SP-Field--checkbox {
		margin-top: 10px;
	}

	.SP-Buttons {
		margin-top: 20px;
	}

	.SP-Legend {
		margin: 0.5em 0;
		font-size: $fontSizeDefault;
	}
}
