@import "../variables";
@import "../breakpoint";
@import "../mixins/headlines";

.bs-headline {
	.no-focus-outline & {
		outline: 0;
	}

	&--page {
		@include bs-headline-page;
	}

	&--section {
		@include bs-headline-section;
	}

	&--paragraph {
		@include bs-headline-paragraph;
	}

	&--mini-section {
		@include bs-headline-mini-section;
	}

	&--offset {
		@include bs-headline-offset;
		color: $colorSecondary;
	}
}
