@import "../../variables";

.bs-sidebar-list {
	display: block;
	width: $sidebarWidth;
	padding: 0;
	margin: 0;

	position: relative;

	list-style: none;
	font: inherit;

	background: $colorPageBackground;

	&:first-child,
	.bs-sidebar-bar + & {
		margin-top: 0;
	}
}

.no-js .bs-sidebar-list .bs-sidebar-list {
	border-left: 1em solid $colorPageBackground;
}
