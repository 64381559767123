// default object-position is center center
@mixin object-fit($object-fit, $object-position: false) {
	@if ($object-position) {
		object-fit: $object-fit;
		object-position: $object-position;

		// font-family used to configure polyfill for ie (see https://github.com/fregante/object-fit-images)
		font-family: 'object-fit: #{$object-fit}; object-position: #{$object-position};';
	} @else {
		object-fit: $object-fit;

		// font-family used to configure polyfill for ie (see https://github.com/fregante/object-fit-images)
		font-family: 'object-fit: #{$object-fit};';
	}
}
