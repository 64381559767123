@import "../../variables";
@import "../../mixins/scroll-wrapper";

$header-height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;

.bs-sidebar-list-wrapper {
	width: 100%;
	flex-grow: 1;
	padding-bottom: 40px; // overscroll

	transition-property: opacity;
	transition-delay: 0ms;
	transition-duration: 200ms;
	transition-timing-function: ease-in-out;

	@include bs-scroll-wrapper;

	.bs-sidebar--loading & {
		opacity: 0.7;
		cursor: wait;
		transition-delay: 500ms;
		transition-duration: 300ms;
		user-select: none;
	}

	@each $themeName, $theme in $themes {
		.bs-theme--#{$themeName} & {
			scrollbar-color: rgba(0, 0, 0, 0.5) map-get($theme, 'navBgColor');
		}
	}
}
