@import "../variables";
@import "../mixins/headlines";
@import "../mixins/object-fit";
@import "../mixins/text-regular";

$contactImageWidth: 180px;
$contactImageWidthMinimal: 120px;
$contactImageGap: $articlePaddingTabletAndUp;

.bs-contact {
	$b: &;

	&__image,
	&__picture__image {
		display: block;
		vertical-align: top;
		width: $contactImageWidth;
	}

	&__picture__image {
		background: #efefef;

		// feature detection, scale-down is not supported well by polyfill, required option watchMQ breaks IE11
		.objectfit & {
			@include object-fit(scale-down);
		}

		.no-objectfit & {
			@include object-fit(contain);
		}
	}

	&__header,
	&__section {
		padding: 8px 0;
	}

	&__header {
		border-bottom: 2px solid $colorPageBackground;
	}

	&__headline {
		@include bs-headline-contact;
		margin: 0;
	}

	&__section-headline,
	&__sub-section-headline {
		margin: 0;
	}

	&__sub-section-headline:not(:first-child) {
		padding-top: 16px;
	}

	.bs-paragraph a {
		color: inherit;
		text-decoration: none;

		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;

		&:hover,
		&:focus {
			color: $colorAction;
			text-decoration: underline;
		}
	}

	.bs-paragraph &__contact__line--phone {
		a {
			font-family: inherit;
			font-weight: inherit;
			color: inherit;
		}
	}

	&--embedded,
	&--minimal {
		#{$b}__header {
			display: none;
		}

		#{$b}__image {
			margin-bottom: 10px;

			.bs-asset__caption {
				padding: 0 0 5px 0;
				font-size: $fontSizeSmallest;
			}
		}
	}

	&--minimal {
		#{$b}__sub-section-headline,
		#{$b}__section-headline {
			display: none;
		}

		#{$b}__image {
			float: right;
			margin-left: $articlePaddingTabletAndUp;
		}

		#{$b}__image,
		#{$b}__picture__image {
			width: $contactImageWidthMinimal;
		}

		#{$b}__header,
		#{$b}__section {
			padding: 0 0 8px 0;
		}
	}

	&--full {
		#{$b}__content {
			background: $colorOffsetSegment;
		}

		#{$b}__header,
		#{$b}__section {
			padding: 8px $articlePaddingTabletAndUp;
		}

		@include breakpoint($breakpointTabletAndUp) {
			margin-left: -$articlePaddingTabletAndUp;
			margin-right: -$articlePaddingTabletAndUp;

			#{$b}__image {
				float: left;
			}

			.bs-content-element--contact--with-image ~ .bs-content-element &,
			#{$b}--with-image ~ &,
			&#{$b}--with-image {
				margin-left: 0;

				#{$b}__content {
					margin-left: $contactImageWidth + $contactImageGap;
				}
			}

			#{$b}__section--reach {
				.bs-contact__sub-section {
					display: inline-block;
					width: 50%;
					margin-right: -4px;
					vertical-align: top;
				}
			}
		}
	}
}
