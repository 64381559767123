@import "../../variables";
@import "../../mixins/text-light";

.bs-sidebar-headline {
	@include bs-text-light;

	position: relative;

	display: block;
	width: $sidebarWidth;
	padding: 5px 0 8px 17px;
	margin: 0;
	border-bottom: 1px solid $colorPageBackground;

	font-style: italic;
	font-size: 1.3rem;

	color: $colorTextSidebarHeadline;
}
