@use "sass:math";

@import "../variables";

.bs-definitions {
	display: block;
	width: 100%;
}

.bs-definition {
	$termWidth: 25%;

	&__term {
		display: block;
		margin: 5px 0 0 0;
		padding: 4px 0;
		font-weight: bold;
		color: $colorPrimary;

		&:not(:first-child) {
			border-top: 1px solid $colorBorderSecondary;
		}

		&--chronic {
			color: $colorTextSecondary;
		}
	}

	&__definition {
		display: block;
		margin: 0;

		a {
			@include bs-text-link;
		}

		strong {
			@include bs-text-medium;
		}

		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		&__term {
			clear: both;
			float: left;
			width: $termWidth;
			padding-right: 18px;
			text-align: right;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover,
			&:focus {
				overflow: visible;
			}

			&--chronic {
				width: math.div($termWidth, 2);
			}
		}

		&__definition {
			float: left;
			margin: 5px 0 0 0;
			padding: 4px 0;
			width: 100% - $termWidth;

			&:not(:first-of-type) {
				border-top: 1px solid $colorBorderSecondary;
			}

			&--chronic {
				width: 100% - (math.div($termWidth, 2));
			}

		}
	}
}
