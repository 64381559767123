@import "../breakpoint";
@import "../variables";

.bs-site {
	scroll-behavior: smooth;

	.bs-sidebar-wrapper--is-blocking &,
	&--no-scroll {
		overflow: hidden;
	}

	@include breakpoint(screen and (min-width: $siteMaxWidth)) {
		&:not(&--app) &__background {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $colorSiteBackground;
			z-index: $zIndexSiteBackground;

			.ms3--map-no-scroll & {
				display: none;
			}
		}
	}

	@media print {
		&__background {
			display: none !important;
		}
	}
}
