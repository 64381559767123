@import "../variables";
@import "../breakpoint";

.bs-meta-navigation {
	position: absolute;
	top: 0;
	right: 0;

	transition: padding-top $transitionDurationDefault $transitionEasingDefault,
	padding-right $transitionDurationDefault $transitionEasingDefault;
	padding-top: 9px;

	@include breakpoint($breakpointTabletAndUp) {
		padding-top: 19px;
		padding-right: 20px;
	}

	&__button-icon {
		width: $buttonSize;
		height: $buttonSize;
		fill: $colorPrimary;

		@media print {
			fill: black;
		}
	}
}
