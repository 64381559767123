@use "sass:math";

@import "../variables";

.bs-skip-links {
	position: fixed;
	left: calc(50% - #{math.div($siteMaxWidth, 2)});
	top: 0;
	width: $siteMaxWidth;
	z-index: $zIndexSkipLinks;

	@include breakpoint(0 $siteMaxWidth - 1) {
		left: 0;
		width: 100%;
	}

	.bs-skip-link {
		position: absolute !important;
		left: 10px;
		top: 10px;
		background-color: #000;
		text-decoration: none;
		color: #fff;
		padding: 10px;
		border-radius: 5px;
	}
}
