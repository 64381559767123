@import "../variables";
@import "../mixins/text-link";
@import "../mixins/link-special-content";

.bs-link {
	@include bs-text-link;
	@include bs-link-special-content;

	&--internal.SP-Link.SP-Iconized--left.SP-Link--table.bs-iconized.bs-iconized--left {
		// broken html; should actually use ".bs-link-list__link"
		@extend .bs-link-list__link;
	}

//	.bs-site--app .bs-content-element:not(.bs-content-element--contact) & {
//		font-weight: inherit;
//		text-decoration: none;
//		pointer-events: none;
//		touch-action: none;
//
//		&:hover,
//		&:focus {
//			color: inherit;
//		}
//	}
}
