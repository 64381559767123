@import "variables";
@import "mixins/button";
@import "mixins/text-link";
@import "mixins/link-special-content";

.bs-code form,
.bs-legacyform {
	clear: both;
	padding-top: 30px;

	table {
		width: 100%;
		max-width: calc(100vw - 7rem) !important; // prevent overflowing the viewport
		margin-bottom: 10px;
	}

	th, td {
		padding: 0 0 0.75em 0;
		border: 0 !important;
		vertical-align: top;
	}

	li {
		padding: 0 0 0.75em 0;
	}

	th {
		padding-top: 2px;
		padding-right: 1em;
		font-weight: bold;
		color: $colorPrimary;
		text-align: right;
		vertical-align: top;
	}

	td > p {
		margin: 0
	}

	a {
		@include bs-text-link;
		@include bs-link-special-content;
	}

	strong {
		@include bs-text-medium;
	}

	input:not([type]),
	input[type=""],
	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="date"] {
		border: none;
		height: 1.6em;
		background-color: $colorOffsetSegment;
		padding: 0.5rem 1rem;
		width: calc(100% - 2rem);
		max-width: calc(100vw - 7rem) !important; // prevent overflowing the viewport

		&:not(:last-child) {
			margin-bottom: 0.75em;
		}

		&:required {
			border-left: 2px solid $colorPrimary;
		}
	}

	textarea {
		border: none;
		height: 5em;
		background-color: $colorOffsetSegment;
		padding: 0.5rem 1rem;
		width: calc(100% - 2rem);
		max-width: calc(100vw - 7rem) !important; // prevent overflowing the viewport

		&:not(:last-child) {
			margin-bottom: 0.75em;
		}

		&:required {
			border-left: 2px solid $colorPrimary;
		}
	}

	input[type="submit"],
	input[type="button"],
	input[type="reset"],
	button[type="submit"],
	button[type="button"] {
		@include bs-button;
		margin: 0 10px 10px 10px;
	}

	.bs-site & input[type="reset"] {
		background-color: $colorTextOffsetSegment;
	}

	/*
	 * Clearfix: contain floats
	 *
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    `contenteditable` attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that receive the `clearfix` class.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */

	.form:before,
	.form:after {
		content: " "; /* 1 */
		display: table; /* 2 */
	}

	.form:after {
		clear: both;
	}

	.rightaligned {
		float: left;
		display: block;
		width: 240px;
		text-align: right;
		padding-right: 15px;
		clear: left;
	}

	h2.box-head {
		clear: both;
		@include bs-text-medium;

		strong {
			@include bs-text-bold;
			color: black;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 5px;
		& + label {
			margin-right: 2rem;
			vertical-align: middle;
		}
	}

	@include breakpoint($breakpointMobile) {
		table:not(.bs-none-responsive-table),
		table:not(.bs-none-responsive-table) tbody,
		table:not(.bs-none-responsive-table) thead,
		table:not(.bs-none-responsive-table) tr,
		table:not(.bs-none-responsive-table) td,
		table:not(.bs-none-responsive-table) th,
		.rightaligned {
			display: block;
			text-align: left;
			float: none;
		}

		input[type="checkbox"],
		input[type="radio"] {
			clear: left;
			float: left;
			margin-top: 5px;
			margin-right: 10px;

			& + label {
				display: block;
				vertical-align: middle;
			}
		}
		th {
			input[type="checkbox"],
			input[type="radio"] {
				margin-top: 20px;
			}
		}

		.bs-none-responsive-table {
			position: relative;
			border-right: 20px solid white;
			margin-bottom: 0 !important;
			border-left: 20px solid white;

			&__wrapper {
				width: 100%;
				overflow-x: auto;
				-webkit-overflow-scrolling: touch;
				position: relative;
				box-shadow: -15px 0 12px -15px rgba(0, 0, 0, 0.4) inset, 14px 0 11px -15px rgba(0, 0, 0, 0.4) inset;
			}

			input[type="checkbox"],
			input[type="radio"] {
				float: none;
			}
		}
	}

	@include breakpoint($breakpointTabletAndUp) {
		table {
			min-width: 600px !important;
		}

		input:not([type]),
		input[type=""],
		input[type="text"],
		input[type="email"],
		input[type="number"],
		input[type="date"],
		textarea {
			&:not([style*="width"]) {
				min-width: 66%;
			}

			&[size="18"] {
				width: 31%;
			}
		}
	}
}
