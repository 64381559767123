@import "../variables";
@import "../mixins/site-theme-property";
@import "../mixins/text-bold";

$miceHighlightColor: #991e66;
$miceContrastColor: #78be20;

.bs-mice-intro {
	width: 100%;
	position: relative;
	overflow: hidden;
	pointer-events: none;
	background: #444;

	&__base {
		width: 100%;
		height: auto;
	}

	&__overlay {
		position: absolute;
		inset: 0;
		z-index: 2;
		width: 100%;
		height: auto;
	}

	&::after {
		display: block;
		content: "";
		position: absolute;
		inset: 0;
		margin: 50px;
		border: 6px solid $miceHighlightColor;
		z-index: 1;
		transition: border-width 200ms ease-out;
	}

	@media (min-width: 600px) {
		&::after {
			border-width: 9px;
		}
	}
}

.bs-site--theme-Mice {
	.bs-headline--paragraph,
	.bs-image-link__headline {
		@include bs-text-bold;
		color: $miceHighlightColor;
	}

	.bs-intro-minisite-fixed {
		display: none !important;
	}

	.bs-intro-minisite-custom .bs-site-logo:not(.bs-site-logo--scrolled) .bs-site-logo__content-bg,
	.bs-intro-minisite-custom .bs-site-logo:not(.bs-site-logo--scrolled) .bs-site-logo__content {
		opacity: 0 !important;
		width: 0 !important;
		height: 0 !important;
	}

	.bs-intro-minisite-custom .bs-site-logo:not(.bs-site-logo--scrolled) .bs-site-logo__content-container {
		transform: translate(200px, 0px) !important;
	}

	.bs-section--editorial:not(section) {
		border-top: 0;
		margin-top: $gridGap;
	}

	.bs-teaser--linked,
	.bs-image-link--linked {
		.bs-fixed-size__content::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: $miceHighlightColor;
			z-index: 10;
			pointer-events: none;
			opacity: 0;
			transition: opacity $transitionDurationFast $transitionEasingDefault;
		}

		&:focus,
		&:hover {
			.bs-fixed-size__content::after {
				opacity: 0.3;
			}
		}
	}

	.bs-site-footer {
		&__segment {
			margin-left: -20px;
			margin-right: -20px;

			&--copyright {
				background: $miceHighlightColor;
				color: white;
				@include bs-text-bold;
			}
		}
	}
}

.bs-site--theme-Mice:not(.bs-site--inherited) {
	@include breakpoint($breakpointSidebarFixed) {
		.bs-section--editorial:not(section),
		.bs-content-element:not(.bs-content-element--highlight):not(.bs-asset--align-right):not(.bs-asset--align-left) + .bs-content-element,
		.bs-content-element--highlight + .bs-content-element:not(.bs-asset):not(.bs-content-element--highlight) {
			margin-top: 80px;
		}

		// except the first after intro/article header
		.bs-anchor-list + .bs-section--editorial:not(section),
		.bs-article-header + .bs-section--editorial:not(section) {
			margin-top: 0;
		}

		.bs-content-element + .bs-asset--align-left.bs-content-element,
		.bs-content-element + .bs-asset--align-right.bs-content-element {
			margin-top: 78px;
		}

		.bs-content-element ~ .bs-asset--align-left + .bs-text,
		.bs-content-element ~ .bs-asset--align-right + .bs-text {
			margin-top: 76px;
		}

		.SP-Fieldset {
			padding-right: 40px;

			&,
			~ .bs-paragraph,
			~ .SP-Buttons {
				float: left;
				width: 50%;
			}

			& + .SP-Fieldset {
				margin-top: 0;
			}
		}

		.bs-content-element--contact:not(:last-child) {
			float: right;
			background: $colorOffsetSegment;
			width: 38%;
			padding: 0 40px 20px 40px;
			margin-left: 40px;
			clear: right !important;
		}

		.bs-content-element--contact + .bs-content-element,
		.bs-content-element--contact + .bs-content-element + .bs-content-element,
		.bs-content-element--contact + .bs-content-element + .bs-content-element + .bs-content-element {
			clear: none;
		}

		.bs-contact--full .bs-contact__section--reach .bs-contact__sub-section {
			width: 100%;
		}
	}
}
