
.bs-logo-banner {
	.bs-grid__item {
		max-width: 268px;
	}

	&__link {
		display: block;
	}

	&__image {
		margin: 0;
		padding: 9%;
	}
}
