@import "../../variables";
@import "../../mixins/text-light";
@import "../../mixins/text-medium";
@import "../../mixins/scroll-wrapper";

$header-height: $siteLogoSmallSize + $siteLogoSmallBorderWidth * 2;

.bs-sidebar-search {
	&__form {
		position: absolute;
		inset: 0;

		display: flex;
		height: 100%;
		flex-direction: column;
	}

	// inside .bs-sidebar-header (position: relative)
	&__input-wrapper {
		position: absolute;
		bottom: 0;
		display: block;
		width: 100%;
		padding: 10px 20px;
		z-index: 2;
		background: $colorPageBackground;
	}

	&__wrapper {
		width: 100%;
		flex-grow: 1;
		padding-bottom: 40px; // overscroll

		@include bs-scroll-wrapper;
	}

	&__input {
		@include bs-text-light;

		display: block;
		width: calc(100% - 32px);
		padding: 4px 16px 6px;
		border: 1px solid $colorBorderPrimary;
		border-radius: 20px;
		font-size: 1.7rem;

		color: inherit;
		background: $colorPageBackground;

		&:focus {
			outline: 0;
			color: black;
			border-color: darken($colorBorderPrimary, 20%);
			@include bs-text-medium;
		}
	}

	&__button {
		position: absolute;
		top: 0;
		right: 0;

		display: block;
		width: 40px;
		height: 40px;
		padding: 0;
		margin: 6px 13px 0 0;

		background: none;
		border: 0;
		border-radius: 6px;
		z-index: 5;

		&__icon {
			width: 40px;
			height: 40px;
			fill: $colorBorderPrimary;
		}
	}

	&__input:focus + &__button &__button__icon,
	&__input:focus + &__button:focus &__button__icon {
		fill: darken($colorBorderPrimary, 20%);
	}
}
