.SP-isHidden {
	display: none;
}

@mixin hide ($accessible: false) {
	@if $accessible == true {
		clip: rect(1px, 1px, 1px, 1px);
		left: 0;
		position: absolute;
		width: 0;
		margin: 0;
		padding: 0;
	} @else {
		display: none !important;
	}
}

.SPu-hide,
.SPu-hidden {
	@include hide();
}
.SPu-access {
	@include hide($accessible: true);
	overflow: hidden;
}
