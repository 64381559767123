@import "../variables";
@import "../breakpoint";

.bs-grid {
	$b: &;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	margin-left: -$gridGap;
	margin-top: -$gridGap;

	&__item {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: stretch;
		align-content: stretch;
		padding-top: $gridGap;
		padding-left: $gridGap;

		& > &--a {
			width: auto;
		}
	}

	&--4 {
		> .bs-grid__item {
			width: 50%;

			&--2,
			&--3,
			&--4 {
				width: 100%;
			}
		}
	}

	@include breakpoint($breakpointGridSmall) {
		&--2 > &__item {
			width: 50%;

			&--2 {
				width: 100%;
			}
		}

		&--3 > &__item {
			width: 50%;

			&--2,
			&--3 {
				width: 100%;
			}
		}

		&--4 > &__item {
			width: 25%;

			&--2 {
				width: 50%;
			}

			&--3 {
				width: 75%;
			}

			&--4 {
				width: 100%;
			}
		}

		& > &__item--a {
			width: 100%;
		}
	}

	@include breakpoint($breakpointGridMid) {
		&--2 > &__item {
			width: 50%;

			&--2 {
				width: 100%;
			}
		}

		&--3 > &__item {
			width: 33.33%;

			&--2 {
				width: 66.67%;
			}

			&--3 {
				width: 100%;
			}
		}

		&--4 > &__item {
			width: 25%;

			&--2 {
				width: 50%;
			}

			&--3 {
				width: 75%;
			}

			&--4 {
				width: 100%;
			}
		}
	}
}

@include breakpoint($breakpointGridMidWithFixedSidebar) {
	.bs-site:not(.bs-site--full-width) {
		.bs-grid--2 > .bs-grid__item {
			width: 50%;

			&--2 {
				width: 100%;
			}
		}

		.bs-grid--3 > .bs-grid__item {
			width: 50%;

			&--2,
			&--3 {
				width: 100%;
			}
		}

		.bs-grid--4 > .bs-grid__item {
			width: 25%;

			&--2 {
				width: 50%;
			}

			&--3 {
				width: 75%;
			}

			&--4 {
				width: 100%;
			}
		}

		.bs-grid > .bs-grid__item--a {
			width: 100%;
		}
	}
}

@include breakpoint($breakpointGridLarge) {
	.bs-site:not(.bs-site--full-width) {
		.bs-grid--2 > .bs-grid__item {
			width: 50%;
		}

		.bs-grid--3 > .bs-grid__item {
			width: 33.33%;

			&--2 {
				width: 66.67%;
			}

			&--3 {
				width: 100%;
			}
		}

		.bs-grid--4 > .bs-grid__item {
			width: 25%;

			&--2 {
				width: 50%;
			}

			&--3 {
				width: 75%;
			}

			&--4 {
				width: 100%;
			}
		}
	}

	.bs-site--full-width {
		.bs-grid--2 > .bs-grid__item {
			width: 33.33%;

			.bs-grid--2 {
				width: 100%;
			}
		}

		.bs-grid--3 > .bs-grid__item {
			width: 25%;

			.bs-grid--2 {
				width: 50%;
			}

			.bs-grid--3 {
				width: 75%;
			}
		}

		.bs-grid--4 > .bs-grid__item {
			width: 20%;

			.bs-grid--2 {
				width: 40%;
			}

			.bs-grid--3 {
				width: 60%;
			}

			.bs-grid--4 {
				width: 80%;
			}
		}
	}
}
