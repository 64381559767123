@import "../../variables";
@import "../../breakpoint";

.bs-sidebar-page-blocker {
	background: transparent;
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zIndexSidebarPageBlocker;

	.bs-sidebar--opened ~ & {
		z-index: $zIndexSidebarPageBlockerOpened;
	}

	.bs-sidebar-wrapper--is-blocking & {
		display: block;
	}
}
