@import "../../variables";
@import "../../mixins/text-ellipsis";
@import "../../mixins/arrow-box";
@import "../../functions/theme-color";

.bs-sidebar-panel-bar {
	display: flex;
	width: $sidebarWidth;
	margin: 15px 0 0 0;
	text-align: left;

	.bs-site--intranet-homepage .bs-sidebar-panel--intranet-root &,
	.bs-site--homepage .bs-sidebar-panel--root & {
		display: none;
	}

	&__item {
		flex-shrink: 0;
		padding: 0 0 10px 0;

		&--title {
			flex-grow: 1;
			flex-shrink: 1;
		}

		&--arrow {
			position: relative;
			width: $buttonSize;

			@include bs-arrow-box(
				$boxSize: ($buttonSize, $fontSizeSmaller * $lineHeightDefault),
				$arrowSize: 4px,
				$pos: "before",
				$dir: "left",
				$box: false,
				$vAlign: "top"
			);
			@include bs-arrow-box-color-fg($colorTextPrimary);

			@each $themeName, $theme in $themes {
				.bs-theme--#{$themeName} & {
					$color: bs-theme-color($themeName, headlineColor, $colorPrimary);
					@include bs-arrow-box-color-fg($color);
				}
			}

			@media print {
				@include bs-arrow-box-color-fg(black !important);
			}
		}

		&--home {
			box-sizing: border-box;
			min-width: $buttonSize + $sidebarListItemIndent;
			position: relative;
			text-align: center;
			padding: {
				left: 15px;
				right: $sidebarListItemIndent;
			}
			outline: none;

			&::after {
				content: "";
				display: block;
				position: absolute;
				right: 0;
				top: -4px;
				bottom: 7px;
				border-right: 1px solid $colorBorderPrimary;

				@media print {
					border-right-color: black;
				}
			}
		}

		&__label {
			margin-left: 7px;
			color: $colorTextPrimary;
			vertical-align: middle;
		}
	}

	.bs-sidebar-panel--intranet-root &__item--home,
	.bs-sidebar-panel--root &__item--home {
		display: none;

		.bs-sidebar--opened & {
			display: inline-block;
		}

		&::after {
			display: none;
		}
	}

	a {
		color: inherit;

		&,
		&:hover {
			text-decoration: none;
		}
	}
}
