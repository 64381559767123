@import "../variables";

.bs-tagungsorte-combo-button {
	display: flex;
	max-width: 19em;
	padding: 10px;
	background: #ecb400;
	border-radius: 20px;

	&__checkbox {
		display: block;
		flex-shrink: 0;
		flex-grow: 0;
		margin: 0;
		padding: 0 10px 0 0;
		border: 0;
		-webkit-appearance: none;
		border-right: 1px solid white;
		background: #ecb400;

		&::before {
			content: " ";
			display: inline-block;
			width: 2rem;
			height: 2rem;
			vertical-align: middle;
			border: 1px solid rgba(68, 68, 68, 0.33);
			border-radius: 1rem;
			margin-right: 0.5rem;
			margin-top: -2px;
		}

		&[data-js-bs-toggle-local-storage-value="true"]::before {
			content: "✓";
		}
	}

	&__link {
		flex-shrink: 1;
		flex-grow: 1;
		display: block;
		padding: 0 5px;
		text-align: center;
		color: black;
	}
}
