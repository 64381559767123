@import "variables";

@import "svg-sprites/svg-sprite-main.scss";
@import "functions/theme-color";
@import "flatpicker";

// Globals
@import "normalize-css/normalize.css";
@import "@neonaut/lib-scss/global/box-sizing";
@import "global/misc";
@import "global/document";
@import "global/fonts";
@import "global/focus";
// Blocks
@import "blocks/anchor-list";
@import "blocks/annotations";
@import "blocks/app";
@import "blocks/article";
@import "blocks/article-header";
@import "blocks/asset";
@import "blocks/audio";
@import "blocks/auto-translate";
@import "blocks/banner";
@import "blocks/baugebiet";
@import "blocks/meinewahl";
@import "blocks/button";
@import "blocks/carousel";
@import "blocks/classic-teaser";
@import "blocks/collapsible";
@import "blocks/contact";
@import "blocks/content";
@import "blocks/content-addon";
@import "blocks/content-element";
@import "blocks/definitions";
@import "blocks/headline";
@import "blocks/fine-print";
@import "blocks/fixed-size";
@import "blocks/flare-tag";
@import "blocks/flickity";
@import "blocks/footer-navigation";
@import "blocks/grid";
@import "blocks/hotelform";
@import "blocks/icon";
@import "blocks/iframe";
@import "blocks/image-links";
@import "blocks/image-link";
@import "blocks/image-teaser";
@import "blocks/image-teaser-carousel";
@import "blocks/intro-item";
@import "blocks/intro-minisite-custom";
@import "blocks/intro-minisite-fixed";
@import "blocks/intro-minisite";
@import "blocks/intro-stage";
@import "blocks/intro-image";
@import "blocks/language-switch";
@import "blocks/legacy-headline-anchor";
@import "blocks/legacyform";
@import "blocks/link";
@import "blocks/link-button";
@import "blocks/link-list";
@import "blocks/logo";
@import "blocks/logo-banner";
@import "blocks/mapsight-source-search";
@import "blocks/mapsight-embed";
@import "blocks/media-activation-button";
@import "blocks/media-icon";
@import "blocks/meta-navigation";
@import "blocks/minisite-logo-banner";
@import "blocks/paragraph";
@import "blocks/portal-links";
@import "blocks/portal-link";
@import "blocks/pswp";
@import "blocks/quote";
@import "blocks/quote-of-the-day";
@import "blocks/sanfona";
@import "blocks/scroll-to-top-button";
@import "blocks/search";
@import "blocks/section";
@import "blocks/separator";
@import "blocks/service-navigation";
@import "blocks/sidebar";
@import "blocks/site";
@import "blocks/skip-links";
@import "blocks/site-footer";
@import "blocks/site-footer-contact";
@import "blocks/site-logo";
@import "blocks/site-main";
@import "blocks/site-sidebar";
@import "blocks/site-wrapper";
@import "blocks/special-article-teaser-image";
@import "blocks/stage";
@import "blocks/tagungsort-teaser-image";
@import "blocks/tagungsorte-combo-button";
@import "blocks/teaser";
@import "blocks/text";
@import "blocks/tosc5";
@import "blocks/translation-disclaimer";
@import "blocks/video";
@import "blocks/voice-reader-button";

// SitePark specials
@import "sitepark/index";

// mapsight integration  WICHTIG! @mapsight nirgendwo sonst und vor allem nicht hier importen!!!
@import "mapsight-ui-integration";

// Allris / Ratsinfo via ratsinfo.braunschweig.de
@import "allris-integration";

// Cookie consent
.cc-window.cc-floating {
	border: 1px solid #444;
}

// mediaelement player integration
@import "medialement";

// voice reader screen reader
@import "voice-reader";

// legacy markup
@import "legacy-mailform";

// Special themes
@import "themes/mice";

// Global trumps
@import "global/print";
@import "global/trumps/helpers";
@import "global/trumps/breakpoints";
@import "global/trumps/js";

// DEBUG:
pre {
	font-size: 1.2rem;
	line-height: 1;
	color: #eee;
	background: #444;
	padding: 10px;
	overflow: auto;
}
