@import "mediaelement/build/mediaelementplayer.css";
@import "mediaelement-plugins/src/playlist/playlist.css";

// Override path to image

.mejs__overlay-button,
.mejs__overlay-loading-bg-img,
.mejs__button > button {
	background-image: url("../img/mejs-controls.svg");
}

.mejs__playlist-button > button,
.mejs-playlist-button > button,
.mejs__next-button > button,
.mejs-next-button > button,
.mejs__prev-button > button,
.mejs-prev-button > button,
.mejs__loop-button > button,
.mejs-loop-button > button,
.mejs__shuffle-button > button,
.mejs-shuffle-button > button {
	background-image: url("../img/mejs-playlist-controls.svg");
}

.mejs__container.mejs__audio,
.mejs-container.mejs-audio {
	min-height: auto;
}
