@import "../breakpoint";
@import "../variables";
@import "../mixins/logo";

$logoSize: 37px;

.bs-logo {
	position: absolute;
	overflow: hidden;
	@include bs-logo($logoSize, false);

	&__signet {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include bs-logo-signet($logoSize);
	}

	&__claim {
		display: block;
		position: absolute;
		opacity: 0;
		transition: opacity $transitionDurationDefault $transitionEasingDefault,
		transform $transitionDurationDefault $transitionEasingDefault,
		top $transitionDurationDefault $transitionEasingDefault,
		left $transitionDurationDefault $transitionEasingDefault,
		width $transitionDurationDefault $transitionEasingDefault,
		height $transitionDurationDefault $transitionEasingDefault;
		@include bs-logo-claim($logoSize);
	}

	&--with-claim {
		@include bs-logo($logoSize, true);
	}

	&--with-claim &__claim {
		opacity: 1;
	}
}
