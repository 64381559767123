@use "sass:math";

@import "../variables";
@import "../breakpoint";
@import "../mixins/text-bold";
@import "../mixins/text-medium";
@import "../mixins/site-theme-property";
@import "../mixins/arrow-box";

.bs-teaser {
	$b: &;

	$paddingText: 15px;
	$paddingTextSmall: 10px 15px;
	$paddingMinimum: 4px;

	font-size: $fontSizeSmaller;
	color: $colorTextPrimary;
	text-decoration: none;

	&,
	&__inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	&__image {
		margin: 0;

		img {
			background: white; // background for transparent images (gif/png)
		}
	}

	&__kicker {
		display: none;
	}

	&__headline {
		margin: 0 0 6px 0;
		font-size: $fontSizeDefault;
		padding-right: 15px;
		position: relative;
		hyphens: auto;
		-webkit-hyphens: auto;
		@include bs-text-medium;

		#{$b}--linked & {
			@include bs-arrow-box(
				$boxSize: (10px, $fontSizeDefault * $lineHeightDefault),
				$arrowSize: 7px,
				$weight: 3px,
				$pos: "after",
				$vAlign: "top",
				$box: false
			);

			@each $themeName, $theme in $themes {
				.bs-site--theme-#{$themeName} & {
					color: bs-theme-color($themeName, headlineColor, $colorTextPrimary);
				}
			}
		}
	}

	&__text {
		width: 100%;
		padding: $paddingText;
		background-color: $colorPageBackground;
		flex-grow: 1;
	}

	&--small &__text {
		padding: $paddingTextSmall;
	}

	&--big {
		&#{$b}--textual {
			&::before {
				content: "";
				display: block;
				//aspect-ratio: 3/2;
				padding-top: 66.67%;
				flex-shrink: 0;
				@include bs-site-theme-property(primaryColor, background-color);
			}
		}

		#{$b}__text {
			border: 1px solid $colorBorderPrimary;
			padding-bottom: 20px;
			display: flex;
			flex-direction: column;

			> * {
				flex-shrink: 0;
			}
		}

		#{$b}__abstract {
			position: relative;
			flex-shrink: 1;
			flex-grow: 1;
			overflow: hidden;
		}
	}

	// FIXME: The renderer adds the .bs-teaser--bordered class to all kinds of teasers (even .bs-classical-teaser)
	&--bordered.bs-teaser {
		border: 1px solid $colorBorderPrimary;

		#{$b}__text {
			border: 0;
		}
	}

	&--medium,
	&--small {
		border-top: 1px solid $colorBorderPrimary;
	}

	&--medium &__image,
	&--small &__image,
	&--small &__addon,
	&--small &__abstract {
		display: none;
	}
}
