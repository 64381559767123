@import "../variables";
@import "../mixins/headlines";

.bs-auto-translate-menu {
	z-index: $zIndexLightbox;
	display: flex;

	&,
	&__overlay {
		position: fixed;
		inset: 0;
	}

	&[aria-hidden='true'] {
		display: none;
	}

	&__overlay {
		background-color: rgb(0 0 0 / 0.8);
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $colorBorderPrimary;
		padding: 1rem 2rem;
	}

	&__title {
		@include bs-headline-paragraph(false, false);
		margin: 0;
		padding: 0;
	}

	&__dialog {
		margin: auto;
		z-index: 2;
		position: relative;

		display: flex;
		flex-direction: column;
		min-width: 50vw;
		max-width: 60ch;
		max-height: 100vh;
		max-height: 100svh;

		background: $colorPageBackground;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);

		overflow: auto;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 1px;
		padding: 1rem 2rem;

		@include breakpoint($breakpointTabletAndUp) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	&__close-button {
		display: flex;
		border: 0;
		padding: 0;
		background: transparent;
		align-self: flex-end;

		.bs-icon {
			width: 40px;
			height: 40px;
			fill: $colorTextPrimary;
		}
	}

	&__button {
		display: flex;
		justify-items: center;
		padding: 0.5rem 1rem;
		border: 0;
		background: transparent;
		text-align: left;
		line-height: 1.4;
		gap: 1rem;

		&__code {
			@include bs-text-bold;
			min-width: 2.6rem;
			text-align: center;
		}

		&__label {
		}

		@include breakpoint($breakpointTabletAndUp) {
			width: calc(50% - 2rem);
		}

		&--active {
			background: $colorOffsetSegment;
			pointer-events: none;
		}

		&:hover,
		&:focus {
			background: $colorOffsetSegment;
		}
	}
}
