@import "../variables";
@import "../mixins/text-link";
@import "../mixins/link-special-content";

.bs-iframe {
	$b: &;

	&__main {
		width: 100%;
		border: 1px solid $colorBorderPrimary;
	}

	&__alt {
		display: none;
		border: 1px solid $colorBorderPrimary;
		padding: 10px;
		margin: 10px -10px;
	}

	&--disabled {
		iframe {
			display: none;
		}

		#{$b}__alt {
			display: block;
		}
	}
}
