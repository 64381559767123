@import "../../variables";

.no-js.bs--js-only,
.no-js .bs--js-only {
	display: none;
}

.js.bs--no-js-only,
.js .bs--no-js-only {
	display: none;
}

.js.bs--js-hidden,
.js .bs--js-hidden {
	display: none;
}
