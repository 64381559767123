@import "../../breakpoint";
@import "../../variables";

.bs--mobile-only {
	@include breakpoint($breakpointTabletAndUp) {
		display: none;
	}
}

.bs-breakpoint--navigation-sidebar {
	@include breakpoint($breakpointSidebarFixed) {
		display: none;
	}
}

.bs-breakpoint--navigation-not-sidebar {
	@include breakpoint($breakpointSidebarOffCanvas) {
		display: none;
	}
}
