@import "../variables";
@import "../breakpoint";

.bs-app__rss-feed {
	.bs-blog-teaser-image {
		display: block;
		width: 100%;
		border: 1px solid $colorBorderPrimary;
		margin: 0;
	}

	@include breakpoint($breakpointTabletAndUp) {
		.bs-blog-teaser-image {
			width: 200px;
			float: left;
			margin: 0 $gridGap $gridGap 0;
		}
	}
}
