@mixin bs-base-carousel {
	max-height: 600px;

	// with js: fade in
	.js & {
		opacity: 0.01;
		transition: opacity $transitionDurationDefault $transitionEasingDefault;

		&.flickity-enabled {
			opacity: 1;
			max-height: none;
		}
	}

	// without js fallback to scrollable div
	.no-js & {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;

		&__item {
			display: inline-block;
			position: relative;

			img {
				border: 1px solid $colorBorderPrimary;
			}
		}
	}
}
