@use "sass:math";

$stageMinHeight: 410px;
$stageSizeRatio: math.div(2, 1);

.bs-stage {
	position: relative;

	width: 100%;
	padding-bottom: math.div(100%, $stageSizeRatio);
	min-height: $stageMinHeight;
	box-sizing: content-box;

	background: grey;

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
