@import "../variables";

.bs-media-activation-button {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;

	min-width: 145px;
	min-height: 145px;
	padding: 10px 20px;

	appearance: none;
	border: 0;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;

	font-family: $fontFamilyDefault;
	font-size: $fontSizeSmallest;
	color: $colorTextPrimary;

	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(8px);
	box-shadow: 4px 4px 13px rgb(0 0 0 / 30%);
	border-radius: 10px;

	transform: translate(-50%, -50%);
	transition: background 300ms ease-in-out, transform 300ms ease-in-out;

	.bs-icon {
		display: none;
		width: 64px;
		height: 64px;
		place-self: self-start;
	}

	@include breakpoint($breakpointTabletAndUp) {
		padding: 15px 30px;
		gap: 10px;
		font-size: $fontSizeSmaller;

		.bs-icon {
			display: block;
		}
	}

	@include breakpoint($breakpointDesktopAndUp) {
		padding: 20px 40px;
		gap: 15px;
		font-size: $fontSizeDefault;
	}

	&:hover,
	&:focus {
		background: white;
		transform: translate(-50%, -50%) scale(1.02);
	}

	.js-bs-youtube & {
		min-width: 220px;
	}
}
