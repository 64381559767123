@import "../variables";
@import "../breakpoint";
@import "../mixins/text-ellipsis";
@import "../mixins/text-link";

.bs-voice-reader-button {
	$b: &;
	$iconSize: 20px;

	display: block;
	margin: 0 -5px;
	padding: 5px;
	border: 0;
	appearance: none;

	background: transparent;
	text-align: left;
	font-size: $fontSizeSmaller;
	color: $colorPrimary;

	&__label {
		margin-left: 1rem;
	}

	&__icon {
		display: inline-block;
		width: $iconSize;
		height: $iconSize;
		vertical-align: top;
		fill: $colorPrimary;

		@media print {
			fill: black;
		}
	}
}
