@import "variables";


.vrweb_player_main {
	position: relative;
}

.vrweb_player_main a {
	margin: 0;
	font-size: inherit;
}

span.VR20S_highlight {
	background-color: #FFFF00;
}

span.VR20S_highlight_word {
	background-color: #6fa3f7 !important;
}

div.VR20S_h_player_container {
	width: 248px;
	height: 45px;
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/bkg.png');
	border: 1px solid black;
	border-radius: 20px;
	padding-top: 4px;
	padding-left: 6px;
	box-sizing: content-box;
	line-height: normal;
	font: medium sans-serif;
}

div.VR20S_h_player_container, .vrweb_settings {
	box-shadow: 0 3px 5px #666;
	-moz-box-shadow: 0 3px 5px #666;
	-webkit-box-shadow: 0 3px 5px #666;
	-o-box-shadow: 0 3px 5px #666;
	-ms-box-shadow: 0 3px 5px #666;
}

div.VR20S_h_marked_container {
	width: 170px;
}

div.VR20S_h_player_button {
	width: 38px;
	height: 38px;
	text-decoration: none;
	float: left;
	border-radius: 8px;
	border: 1px solid transparent;
	box-sizing: content-box;
}

/*NEW*/
button.vrweb_icon {
	padding: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
}

button.VR20S_h_player_button {
	width: 38px;
	height: 38px;
	padding: 1px 0;
	text-decoration: none;
	float: left;
	border-radius: 8px;
	border: 1px solid transparent;
	box-sizing: content-box;
	background-color: transparent;
	cursor: pointer;
}

button.VR20S_h_player_button:hover {
	border: 1px solid white;
}

button.VR20S_h_button_play {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/play.png');
}

button.VR20S_h_button_pause {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/pause.png');
}

button.VR20S_h_button_waiting {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/wait.png');
}

button.VR20S_h_button_previous {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/prev.png');
}

button.VR20S_h_button_next {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/next.png');
}

button.VR20S_h_button_config {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/config.png');
}

button.VR20S_h_button_close {
	margin-left: 56px;
	width: 16px;
	height: 16px;
	padding-top: 2px;
	padding-left: 4px;
	padding-right: 4px;
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/blue/close.png');
	background-repeat: no-repeat;
	background-position: center;
}

/*END*/

div.VR20S_h_player_button:hover {
	border: 1px solid white;
}

div.VR20S_h_button_play {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/play.png');
}

div.VR20S_h_button_pause {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/pause.png');
}

div.VR20S_h_button_waiting {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/wait.png');
}

div.VR20S_h_button_previous {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/prev.png');
}

div.VR20S_h_button_next {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/next.png');
}

div.VR20S_h_button_config {
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/config.png');
}

div.VR20S_h_close_logo {
	width: 76px;
	height: 38px;
}

div.VR20S_h_close_logo:hover {
	border: 1px solid transparent;
}

div.VR20S_h_button_close {
	margin-left: 56px;
	width: 16px;
	height: 16px;
	padding-top: 2px;
	padding-left: 4px;
	padding-right: 4px;
	background-image: url('https://vrweb15.linguatec.org/VoiceReaderWeb15User/player20/buttons/black/close.png');
	background-repeat: no-repeat;
	background-position: center;
}

img.VR20S_h_linguatec_logo {
	width: 77px;
	height: 19px;
	margin: 0;
	float: left;
}

/* Special case for multiland languages */
div.VR20S_multiLandLayout {
	height: 68px;
}

input.VR20S_multiLandButton {
	margin: 3px 2px 3px 4px;
	padding: 0;
	height: 16px;
	width: 16px;
}

img.VR20S_multiLandButton {
	width: 18px;
}

/* Special case for the mini player */
div.VR20S_miniPlayer {
	width: 46px;
}

/* Colors both for highlighting and the subtitles box */
.VR20S_Color_Black {
	background-color: #000000;
}

.VR20S_Color_LightGrey {
	background-color: #CCCCCC;
}

.VR20S_Color_White {
	background-color: #FFFFFF;
}

.VR20S_Color_LightBlue {
	background-color: #99CCFF;
}

/* Colors exclusively for highlighting */
.VR20S_Color_Cyan {
	background-color: #00FFFF;
}

.VR20S_Color_Yellow {
	background-color: #FFFF00;
}

.VR20S_Color_LightGreen {
	background-color: #66FF00;
}

.VR20S_Color_Magenta {
	background-color: #FF33FF;
}

/* Colors exclusively for the subtitles box */
.VR20S_Color_Blue {
	background-color: #6699FF;
}

.VR20S_Color_LightYellow {
	background-color: #FFFF99;
}

.VR20S_Color_Green {
	background-color: #CCFF99;
}

.VR20S_Color_Red {
	background-color: #FF9999;
}

/* Color classes for highlighting (prefix ".VR20S", because more specific CSS classes have higher priority) */
.VR20S.VR20S_Color_Black {
	background-color: #000000;
}

.VR20S.VR20S_Color_Cyan {
	background-color: #00FFFF;
}

.VR20S.VR20S_Color_LightBlue {
	background-color: #99CCFF;
}

.VR20S.VR20S_Color_Yellow {
	background-color: #FFFF00;
}

.VR20S.VR20S_Color_LightGreen {
	background-color: #66FF00;
}

.VR20S.VR20S_Color_Magenta {
	background-color: #FF33FF;
}

.VR20S.VR20S_Color_LightGrey {
	background-color: #CCCCCC;
}

.VR20S.VR20S_Color_White {
	background-color: #FFFFFF;
}

/* Color classes for the subtitles box (prefix ".VR20S_subtitle", because more specific CSS classes have higher priority) */
.VR20S_subtitle.VR20S_Color_Black {
	background-color: #000000;
}

.VR20S_subtitle.VR20S_Color_LightBlue {
	background-color: #99CCFF;
}

.VR20S_subtitle.VR20S_Color_LightGrey {
	background-color: #CCCCCC;
}

.VR20S_subtitle.VR20S_Color_White {
	background-color: #FFFFFF;
}

.VR20S_subtitle.VR20S_Color_Blue {
	background-color: #6699FF;
}

.VR20S_subtitle.VR20S_Color_LightYellow {
	background-color: #FFFF99;
}

.VR20S_subtitle.VR20S_Color_Green {
	background-color: #CCFF99;
}

.VR20S_subtitle.VR20S_Color_Red {
	background-color: #FF9999;
}

/* SUBTITLES */
.VR20S_subtitle {
	display: block;
	position: fixed !important;
	top: auto !important;
	bottom: 50px !important;
	left: 50px !important;
	right: 50px !important;
	padding: 25px !important;
	background-color: #99CCFF;
	border: solid 4px black;
	border-radius: 15px;
	text-align: center;
	z-index: 1000;
	font: 3em Arial, Helvetica, sans-serif;
	line-height: 1.15em;
	color: black;
	max-height: 100% !important;
}

.VR20S_closeSubtitle {
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	font-size: 18px;
	line-height: 18px;
	font-weight: bold;
}

/* SETTINGS */
.vrweb_settings_wrapper {
	display: none;
}

.vrweb_settings {
	width: 300px;
	background-color: black;
	border: 1px solid black;
	border-radius: 3px;
	resize: both;
	font: 13px Arial, sans-serif;
	line-height: normal;
}

.vrweb_settings * {
	box-sizing: content-box !important;
}

.vrweb_settings .vrweb_s_content_header {
	margin: 5px;
	font-size: 17px;
	font-weight: bold;
	color: white;
	padding: 0;
}

.vrweb_settings .vrweb_s_content_header h2 {
	display: inline-block;
	margin: 2px;
}

a.vrweb_x_link {
	float: right;
	text-decoration: none;
}

div.vrweb_x {
	color: white;
}

/*NEW*/
button.vrweb_x {
	float: right;
	padding: 1px 0;
	color: white;
	font-weight: bold;
	font-size: 16px;
	background-color: transparent;
	border: 2px solid transparent;
	cursor: pointer;
}

button.VR20S_closeSubtitle {
	background-color: transparent;
	border: none;
}

/*END*/

.vrweb_settings .vrweb_settings_frame {
	background-color: #FFFFFF;
	margin: 5px;
	padding: 10px 5px;
	overflow: auto;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.vrweb_settings .vrweb_settings_content {
	color: black;
	background-color: #FFFFFF;
	padding-left: 5px;
}

.vrweb_settings .vrweb_settings_option:not(:last-of-type) {
	margin-bottom: 20px;
}

/*NEW*/
.vrweb_settings .vrweb_settings_option {
	position: relative;
}

/*END*/

.vrweb_settings .vrweb_s_option_label {
	font-size: 14px;
	font-weight: bold;
}

.vrweb_settings div.vrweb_s_option_label {
	margin-bottom: 5px;
}

.vrweb_settings label.vrweb_s_option_label {
	display: block;
	margin-bottom: 5px;
}

.vrweb_settings input {
	margin: 0;
	border: none;
	padding: 0;
}

.vrweb_settings .vrweb_s_slider {
	width: 150px;
	vertical-align: middle;
}

.vrweb_settings .vrweb_s_value {
	padding-top: 4px;
	padding-right: 0;
	padding-bottom: 4px;
	padding-left: 8px;
	margin-left: 25px;
	border: 1px solid #000000;
	border-radius: 8px;
	width: 40px;
	height: 15px;
	color: black;
}

.vrweb_settings label {
	display: inline;
	float: none;
	font-weight: normal;
}

.vrweb_settings .vrweb_s_button {
	position: absolute;
	top: 0; /*new*/
	right: 45px; /*new*/
	padding-top: 4px;
	padding-right: 8px;
	padding-bottom: 4px;
	padding-left: 8px;
	border: 1px solid #000000;
	border-radius: 8px;
	box-shadow: 2px 2px 2px grey;
	font-size: 13px;
	color: black;
}

.vrweb_settings .vrweb_s_radio_off {
	margin-left: 20px;
}

.vrweb_settings .vrweb_s_color_group {
	float: right;
}

.vrweb_settings .vrweb_s_color_border {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 2px solid transparent;
	padding: 1px;
}

.vrweb_settings .vrweb_s_color_border:not(:last-of-type) {
	margin-right: 1px;
}

.vrweb_settings .vrweb_s_color_border.active {
	border-color: black;
}

.vrweb_settings .vrweb_s_color_option {
	display: inline-block;
	width: 18px;
	height: 18px;
	border: 1px solid black;
	border-radius: 5px;
}

/*NEW*/
button.vrweb_s_color_option {
	display: inline-block;
	width: 18px;
	height: 18px;
	padding: 0;
	border: 1px solid black;
	border-radius: 5px;
	cursor: pointer;
}

.vrweb_s_color_border.active {
	border: 2px solid black;
}

/*END*/

/* PLAYBUTTON MESSAGE */
.vrweb_tapSubPlayerMessage {
	background-color: black;
	color: white;
	margin-left: 8px;
	margin-top: 46px;
	padding: 2px 0 25px;
	position: absolute;
	text-align: center;
	width: 224px !important;
	height: 5px !important;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.vrweb_tapSubPlayerMessage > .VR20S_h_button_play {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-size: 100% 100%;
	position: relative;
	top: 4px;
}

.VR15S_subtitle {
	z-index: $zIndexVoiceReader !important;
}

.vrweb_hover_player {
	z-index: $zIndexVoiceReader + 1 !important;
}

.vrweb_settings_wrapper {
	z-index: $zIndexVoiceReader + 2 !important;
}
