@use "sass:math";

@import "../variables";
@import "../mixins/base-carousel";

$minimumHeight: 410px;
$minimumCaptionHeight: 45px;
$carouselRatio: math.div(5, 2);

.bs-intro-stage {
	@include bs-base-carousel;

	&,
	.flickity-viewport {
		min-height: $minimumHeight;
	}

	.js & {
		overflow: hidden;
	}

	&__item {
		width: 100%;
		height: 100%;
		margin-right: 10px;

		.no-js & {
			height: 410px;
		}

		&[aria-selected="false"] {
			pointer-events: none;
		}
	}

	// relative height using pseudo element + min height
	.flickity-viewport::before {
		content: "";
		display: block;
		padding-bottom: calc(#{$minimumCaptionHeight} + #{100% * math.div(1, $carouselRatio)});
	}

	.flickity-button:not(:hover):not(:focus-visible):not(.focus-visible) {
		// make it slightly less opaque
		background-color: rgba(255, 255, 255, 0.3);
	}

	.flickity-prev-next-button {
		// make it slightly smaller
		width: 32px;
		height: 32px;
		bottom: 10px;
		top: auto;
	}
}
