@import "../variables";

.bs-flare-tag {
	display: inline-block;
	padding: 2px 5px;
	margin: 0 6px 0 0;

	border-radius: 2px;

	background: #333333;
	color: white;

	font-size: $fontSizeSmallest;

	&--primary {
		background: #005587;
	}

	&--success {
		background: #006747;
	}

	&--danger {
		background: #862633;
	}

	&--warning {
		background: #cc8a00;
	}
}
