@import "../variables";
@import "../breakpoint";

.bs-fine-print {
	color: $colorTextSecondary;

	&--small {
		font-size: $fontSizeSmaller;
	}
}
