@use "sass:math";

@import "../../variables";
@import "../../mixins/text-bold";
@import "../../mixins/text-ellipsis";
@import "../../mixins/text-ellipsis";
@import "../../mixins/word-wrap";

.bs-sidebar-list-item {
	$b: &;

	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
	position: relative;

	color: black;
	border-bottom: 1px solid $colorPageBackground;

	&::after {
		transition: width 300ms ease-out;
	}

	&:not(.bs-sidebar-list-item--offset):hover {
		&::after {
			transition: none;
		}
	}

	&__link {
		display: block;
		width: 100%;
		padding: {
			left: $sidebarListItemIndent + $sidebarListItemMenuExtraIndent;
			right: 0;
			top: math.div($buttonSize * 0.5, 2);
			bottom: math.div($buttonSize * 0.5, 2);
		}
		margin: 0;
		line-height: $buttonSize * 0.5;
		text-decoration: none;
		color: inherit;

		// @include bs-word-wrap();
		hyphens: auto;
		overflow-wrap: break-word;

		&--overview {
			//@include bs-text-medium;
			padding-left: $sidebarListItemIndent;

			.bs-icon {
				position: absolute;
				top: 0;
				right: 0;
				height: 20px;
				width: 20px;
				margin: 10px 10px 0 0;
			}
		}

		&--with-icon {
			padding-left: 52px !important;

			.bs-icon {
				position: absolute;
				top: 0;
				left: 0;
				height: 40px;
				width: 40px;
				margin: 1px 0 0 8px;
				fill: $colorTextPrimary;
				transition: margin-left $transitionDurationFast $transitionEasingDefault;
			}

			&:hover,
			&:focus {
				.bs-icon {
					margin-left: 10px;
				}
			}
		}

		// next arrow box
		&--next {
			$m: &;
			padding: {
				right: $buttonSize * 1.2;
			}

			// root level item
			@at-root .bs-sidebar-list--root {
				#{$m} i {
					@include bs-arrow-box(
						$boxSize: ($buttonSize 100%),
						$arrowSize: 4px,
						$pos: "after",
						$box: true,
						$vAlign: "middle"
					);

					// fg color for all themes, but default
					@include bs-arrow-box-color-fg(#fff);
				}

				.bs-theme--default #{$m} i {
					// fg color for default theme
					@include bs-arrow-box-color-fg($colorTextPrimary);
				}

				@each $themeName, $theme in $themes {
					.bs-theme--#{$themeName} #{$m} i {
						@include bs-arrow-box-color-bg(bs-theme-color($themeName, iconColor, transparent));
					}
				}
			}

			// non-root level item
			@at-root .bs-sidebar-list:not(.bs-sidebar-list--root) {
				#{$m} i {
					@include bs-arrow-box(
						$boxSize: ($buttonSize 100%),
						$arrowSize: 4px,
						$pos: "after",
						$box: true,
						$vAlign: "middle"
					);

					&::before {
						border-left: 1px solid #fff;
					}

					&:hover {
						// fg color for all themes, but default
						@include bs-arrow-box-color-fg(#fff);
						@include bs-arrow-box-color-bg(#444);
					}
				}

				.bs-sidebar-list-item--active > #{$m} i,
				.bs-sidebar-list-item--selected > #{$m} i {
					@include bs-arrow-box-color-fg(#fff);
				}
			}
		}

		@each $themeName, $theme in $themes {
			@at-root .bs-theme--#{$themeName} .bs-sidebar-list:not(.bs-sidebar-list--root) &--next i:hover {
				@include bs-arrow-box-color-bg(
						bs-theme-color($themeName, "navSelectedBgColor", transparent)
				);
			}
		}

		.bs-sidebar-panel--root &,
		.bs-sidebar-panel--intranet-root & {
			padding-left: $sidebarListItemIndent;
		}

		.bs-sidebar-panel--root & {
			background: $colorOffsetSegment;
		}
	}

	// theme by parent
	@each $themeName, $theme in $themes {
		.bs-theme--#{$themeName} & {
			background: map-get($theme, 'navBgColor');
		}

		.bs-theme--#{$themeName} &__link .bs-icon {
			fill: map-get($theme, 'headlineColor');
		}
	}

	// theme by item
	@each $themeName, $theme in $themes {
		&.bs-theme--#{$themeName} {
			background: map-get($theme, 'navBgColor');
		}

		&.bs-theme--#{$themeName} &__link .bs-icon {
			fill: map-get($theme, 'headlineColor');
		}
	}

	.bs-sidebar-panel--root & {
		background: $colorOffsetSegment;
		//@include bs-text-medium;
	}

	& > a:hover,
	.no-js & > a:focus,
	.focus-outline & > a:focus,
	&.bs-sidebar-list-item--selected > a {
		color: inherit;
		background: $colorSecondary;
		text-decoration: none;
	}

	.no-focus-outline & > a:focus {
		outline: none;
	}

	@each $themeName, $theme in $themes {
		&.bs-theme--#{$themeName},
		.bs-theme--#{$themeName} & {
			& > a:hover,
			.no-js & > a:focus,
			.focus-outline & > a:focus {
				color: inherit;
				background: map-get($theme, 'navHoverBgColor');
			}

			&.bs-sidebar-list-item--active > a,
			&.bs-sidebar-list-item--selected > a {
				background-color: map-get($theme, "navSelectedBgColor");
				color: map-get($theme, "navSelectedTextColor");

				&:hover,
				.no-js &:focus,
				.focus-outline &:focus {
					background-color: darken(map-get($theme, "navSelectedBgColor"), 5%);
					color: map-get($theme, "navSelectedTextColor");
				}
			}
		}
	}

	&--offset {
		padding-top: 52px; // TODO: magic number
	}

	.bs-sidebar-list--search &:first-child {
		border-top: 1px solid $colorPageBackground;
	}

	@media print {
		&__link .bs-icon {
			fill: black;
		}
	}
}
