@import "../variables";

.bs-special-article-teaser-image {
	width: 100%;
	max-width: 320px;

	img {
		width: 100%;
		height: auto;
	}

	.bs-asset__caption {
		padding: 5px;
		font-size: $fontSizeSmallest;
	}

	.ms3-list__info &,
	.ms3-wrapper--desktop .ms3-list__details & {
		max-width: 14vw;
		float: right;
		margin-left: $gridGap;
	}
}
