@import "../variables";
@import "../breakpoint";
@import "../mixins/text-regular";
@import "../mixins/text-medium";

.bs-image-teaser {
	$paddingText: 15px;
	$paddingTextSmall: 10px 15px;
	$paddingMinimum: 4px;

	$bigHeight: 125%;
	$mediumHeight: 56%;

	display: block;
	width: 100%;
	position: relative;
	font-size: $fontSizeDefault;
	color: $colorTextPrimary;
	text-decoration: none;
	@include bs-text-regular;

	&__image,
	&__image-placeholder {
		display: block;
		width: 100%;
		height: auto;
		padding: 0;
		margin: 0;
		background: #ccc;
		background-clip: padding-box;
		overflow: hidden;
	}

	&__image-placeholder {
		padding-top: 66.67%;
	}

	&__text {
		margin: 5px 15px;
		padding-right: 10px;
		position: relative;
		font-size: $fontSizeSmaller;
		font-weight: $fontWeightMedium;

		@include bs-arrow-box(
			$boxSize: (10px, $fontSizeSmaller * $lineHeightDefault),
			$arrowSize: 6px,
			$weight: 3px,
			$pos: "after",
			$vAlign: "top",
			$box: false
		);

		@each $themeName, $theme in $themes {
			.bs-site--theme-#{$themeName} & {
				color: bs-theme-color($themeName, "headlineColor", $colorTextPrimary);
			}
		}
	}
}
