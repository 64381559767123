@import "../variables";

.SP-Composition {
	&__inner {
		display: flex;
		align-items: stretch;
	}

	.SP-Field {
		flex-grow: 1;
	}

	.SP-Field + .SP-Field:not(.SP-Field--checkbox):not(.SP-Field--radio) {
		margin-left: 0.75em;
	}

	.SP-Button {
		font-size: 1.6rem;
		line-height: 1.44;
		margin-top: 2.45em;
		margin-bottom: .75em;
		height: calc(1.6em + 3px);
		padding: .5rem 2rem;
		box-sizing: content-box;
	}
}
