@import "../variables";
@import "../breakpoint";
@import "../mixins/text-ellipsis";
@import "../mixins/text-link";
@import "../mixins/arrow-box";
@import "../mixins/text-medium";

.bs-footer-navigation {
	margin: 0;
	padding: 0;
	list-style: none;
	line-height: $fontSizeSmaller * 1.5;
	font-size: $fontSizeSmaller;
	font-family: $fontFamilyDefault;
	font-weight: $fontWeightRegular;

	&__item {
		white-space: nowrap;

		&__label {
			white-space: normal;
		}

		&--main {
			@include bs-text-medium;
			font-size: $fontSizeDefault;
		}

		@include breakpoint($breakpointTabletAndDown) {
			&--secondary {
				margin-left: 35px;
			}
		}
	}

	&__link {
		color: inherit;
		text-decoration: none;
		display: block;
		padding: {
			top: 7px;
			bottom: 7px;
		}

		&:hover,
		&:focus {
			color: $colorPrimary;
		}
	}

	&__item--main &__link {
		@include bs-arrow-box($boxSize: 20px, $arrowSize: 4px);
		position: relative;
		display: block;
		padding-left: 35px;

		@each $themeName, $theme in $themes {
			&--theme-#{$themeName} {
				@include bs-arrow-box-color-bg(map-get($theme, iconColor));

				$fgColor: #fff;
				@if $themeName == "default" {
					$fgColor: $colorTextPrimary;
				}
				@include bs-arrow-box-color-fg($fgColor);
			}
		}
	}

	.bs-icon {
		width: 25px;
		height: 25px;
		margin-right: 10px;
		margin-top: -3px;
		fill: $colorTextPrimary;
	}

	&__link:hover,
	&__link:focus {
		.bs-icon {
			fill: $colorPrimary;
		}
	}

}
