@import "../breakpoint";
@import "../variables";

.bs-site-main {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-color: $colorPageBackground;
	//padding-top: 1px; // contains margin of inner elements

	@include breakpoint($breakpointSidebarFixed) {
		.bs-site:not(.bs-site--full-width) & {
			float: left;
			width: calc(100% - #{$sidebarWidth});
		}
	}

	// dimming the site when sidebar is opened and blocking
	&::before {
		content: "";
		display: none;
		transition: opacity $transitionDurationDefault $transitionEasingDefault;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $sitePageDimmingColor;

		.bs-sidebar-wrapper--is-active & {
			z-index: $zIndexSiteMainDimming;
			opacity: 0;
		}

		.bs-sidebar-wrapper--is-opened &  {
			opacity: $sitePageDimmingOpacity;
		}

		.bs-sidebar-wrapper--is-blocking &  {
			display: block;
		}
	}
}
