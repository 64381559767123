@import "../variables";

.SP-Fieldset {
	margin: 26px 0;

	/**
	 * Checkboxen als Gruppe
	 */
	&--checkbox {
		border: none;
		margin: 26px 0;
		padding: 0;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	&--radioGroup,
	&--radio {
		border: none;
		margin: 26px 0;
		padding: 0;

		ul {
			margin-top: 0;
			padding-left: 0;
		}
	}
}
