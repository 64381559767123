@import "../variables";

.neonaut-tosc-wrapper {
	position: relative;
	margin: 0 -20px;
	background: white;
	z-index: $zIndexTosc5;
	min-height: 400px;
	clear: both;

	html.bs-sidebar-wrapper--is-opened & {
		z-index: 70 !important;
	}
}

#paymentFrameWrapper {
	z-index: $zIndexTosc5 + 100 #{" !important"};
}
