@import "../variables";
@import "../breakpoint";

.bs-text {
	&--centered {
		text-align: center;

		.bs-paragraph {
			dl,
			ol,
			p,
			ul {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}


	&--cols-2 {
		.bs-paragraph {
			@include breakpoint($breakpointDesktopAndUp) {
				column-count: 2;
				column-gap: $gridGap;
			}
		}
	}
}
