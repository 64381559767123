@import "../variables";
@import "../mixins/text-medium";
@import "../mixins/headlines";

.react-sanfona {
	border-top: 1px solid $colorBorderPrimary;
}

.react-sanfona-item-title {
	user-select: none;
	display: block;
	clear: both;
	cursor: default;
	position: relative;
	padding: 1rem;

	@include bs-text-medium;
	font-size: $fontSizeDefault;
	background-color: white;
	border: 0;
	border-bottom: 1px solid $colorBorderPrimary;

	&:hover {
		background-color: $colorOffsetSegment;
	}

	&::before {
		content: " ";
		display: inline-block;
		margin: -.8rem 0px -.8rem -1rem;
		width: 4rem;
		height: 4rem;
		object-fit: contain;
		overflow: hidden;
		background: {
			image: url(../img/accordion-title-arrow.svg);
			size: contain;
			repeat: no-repeat;
		}
		vertical-align: top;
		transform: rotate(90deg);
		transition: transform $transitionDurationFast $transitionEasingDefault;
	}

	&[aria-expanded="true"]::before {
		transform: rotate(-90deg);
	}
}

.react-sanfona-item-body {
	border-bottom: 1px solid white;
	padding: 0 1rem;
	max-height: 0;
}

.react-sanfona-item-expanded {
	> .react-sanfona-item-body {
		border-bottom: 1px solid $colorBorderPrimary;
		max-height: none !important;
	}
}

.react-sanfona-item-disabled {
	.react-sanfona-item-title {
		color: $colorTextSecondary;
		cursor: default !important;
	}
}

.bs-accordion__level2 {
	padding: 0 0 12px;

	.react-sanfona-item-title {
		background-color: #fff;
		border: 0 none;
		padding: 4px 0 0;
		height: auto;
		border-bottom: 1px solid #e2e2e2;
		margin-bottom: 6px !important;
	}
}
