.bs-media-icon {
	position: absolute;
	top: 0;
	right: 0;
	margin: 10px;
	min-width: 35px;
	min-height: 35px;
	padding: 2px;
	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(8px);
	box-shadow: 4px 4px 13px rgb(0 0 0 / 30%);
	border: 0;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include breakpoint($breakpointTabletAndUp) {
		margin: 15px;
		min-width: 40px;
		min-height: 40px;
	}

	.bs-icon {
		width: 25px;
		height: 25px;
	}

	.bs-video:not(.bs-video--placeholder) + & {
		display: none;
	}
}
